import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { Email, Send } from "@material-ui/icons";

import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import htmlToPlainText from "../util/htmlToPlainText";
import trackEvent from "../util/trackEvent";
import isValidEmail from "../util/isValidEmail";

import Loader from "../components/Loader";

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)`
  margin: 6px 0;
`;
const Alert = styled(MuiAlert)`
  margin-bottom: 12px;
`;
const SmallEmail = styled(Email)`
  height: 20px;
  width: 20px;
`;

class SendByEmailDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      processingSend: false,
      errors: {},
      subjectLine: "",
      coveringText: props.coveringText || "",
      toEmails: [],
      inputToEmail: "",
      showCc: false,
      ccEmails: [],
      inputCcEmail: "",
      showBcc: false,
      bccEmails: [],
      inputBccEmail: "",
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      processingSend: false,
      errors: {},
      subjectLine: this.props.releaseSubject,
      toEmails: this.props.toEmail ? this.props.toEmail.split(",") : [],
      inputToEmail: "",
      showCc: false,
      ccEmails: [],
      inputCcEmail: "",
      showBcc: false,
      bccEmails: [],
      inputBccEmail: "",
      coveringText: this.props.coveringText || "",
    });
  };

  handleClose = () => {
    if (this.props.persistCoveringText)
      this.props.persistCoveringText(this.state.coveringText);
    this.setState({
      open: false,
    });
  };

  fromEmail = () => {
    return this.userCanSend()
      ? this.props.profile.email
      : "pressreleases@ping-go.com";
  };

  handleSubmit = () => {
    if (!this.formIsValid()) return;
    this.setState({ processingSend: true });
    const { toEmails, ccEmails, bccEmails, subjectLine } = this.state;
    const coveringText = this.props.contentType.includeCoveringText
      ? this.state.coveringText + "/n/n"
      : "";
    const coveringHtml = this.props.contentType.includeCoveringText
      ? `<p>${this.state.coveringText.replace(/\n/g, "</p><p>")}</p><hr>`
      : "";
    const { releaseHtml, profile, documentId } = this.props;
    const fromEmail = this.fromEmail();
    const message = {
      // PascalCase as being passed as params to PostMark API
      From: fromEmail,
      ReplyTo: profile.email,
      To: toEmails.join(","),
      Tag: "release",
      Subject: subjectLine,
      TextBody: coveringText + htmlToPlainText(releaseHtml),
      HtmlBody: `${coveringHtml}${releaseHtml}`,
      TrackOpens: true,
      Metadata: {
        documentId,
        teamId: this.props.workspace.teamId || "",
      },
    };
    if (ccEmails.length > 0) message.Cc = ccEmails.join(",");
    if (bccEmails.length > 0) message.Bcc = bccEmails.join(",");
    // console.log(message);
    fetch("https://europe-west1-pinggo-v2.cloudfunctions.net/api/mail-send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    })
      .then((response) => {
        // console.log(response);
        trackEvent(this.props.firebase, "Sent an email", { from: fromEmail });
        if (this.props.afterSend) this.props.afterSend();
        this.handleClose();
        return;
      })
      .catch((error) => {
        this.setState({
          errors: { sending: "Sorry, there was a problem sending" },
          processingSend: false,
        });
        console.log(error);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  userCanSend = () => {
    return this.props.profile.hasVerifiedEmail;
    // && PRICING_PLANS[this.props.workspace.plan || 0]
    //   .send_from_verified_owner_email
  };

  formIsValid = () => {
    const errors = {};
    const { toEmails, ccEmails, bccEmails, coveringText, subjectLine } =
      this.state;
    if (!toEmails || (toEmails && !toEmails.every(isValidEmail))) {
      errors.toEmails = "One or more valid email addresses are required";
    }
    if (ccEmails && !ccEmails.every(isValidEmail)) {
      errors.ccEmails = "All Cc email addresses must be valid";
    }
    if (bccEmails && !bccEmails.every(isValidEmail)) {
      errors.bccEmails = "All Bcc email addresses must be valid";
    }
    if (!coveringText && this.props.contentType.includeCoveringText) {
      errors.coveringText = "Enter some covering text";
    }
    if (!subjectLine) {
      errors.subjectLine = "Subject line can't be blank";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  render() {
    const {
      processingSend,
      errors,
      toEmails,
      inputToEmail,
      showCc,
      ccEmails,
      inputCcEmail,
      showBcc,
      bccEmails,
      inputBccEmail,
    } = this.state;
    const { isFollowUp, isFromDistribution, teamContactsMap, contentType } =
      this.props;

    const teamContactsList = teamContactsMap
      ? Object.keys(teamContactsMap)
      : [];

    return (
      <Fragment>
        {isFromDistribution ? (
          <IconButton edge="end" size="small" onClick={this.handleOpen}>
            <SmallEmail />
          </IconButton>
        ) : (
          <Button
            variant={"contained"}
            color="primary"
            size={isFollowUp ? "small" : "medium"}
            onClick={this.handleOpen}
          >
            {isFollowUp ? "Follow up" : "New email"}
          </Button>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {isFollowUp ? "Follow up" : "Send"} {contentType.nameWithArticle} by
            Email
          </DialogTitle>
          <DialogContent>
            {!this.userCanSend() && (
              <Alert severity="info">
                <>
                  For security and deliverability, this email will be sent from{" "}
                  <i>pressreleases@ping-go.com</i>. To enable sending from{" "}
                  <i>
                    {this.props.profile.email} verify your email address from
                    the PingGo account menu
                  </i>
                  {/* <ul>
                    {!PRICING_PLANS[this.props.workspace.plan || 0]
                      .send_from_verified_owner_email && (
                      <li>Please upgrade your workspace plan</li>
                    )}
                    {!this.props.profile.hasVerifiedEmail && (
                      <li>
                        Verify your email address from the PingGo account menu
                      </li>
                    )}
                  </ul> */}
                </>
              </Alert>
            )}
            <TextField
              id="fromEmail"
              name="fromEmail"
              label="From:"
              type="string"
              disabled
              fullWidth
              value={this.fromEmail()}
            />
            <TextField
              id="replyEmail"
              name="replyEmail"
              label="Reply-to:"
              type="string"
              disabled
              fullWidth
              value={this.props.profile.email}
            />
            <Autocomplete
              autoComplete
              multiple
              freeSolo
              autoSelect
              filterSelectedOptions
              id="toEmails"
              options={teamContactsList}
              value={toEmails}
              inputValue={inputToEmail}
              onChange={(event, newEmails) => {
                this.setState({ toEmails: newEmails });
              }}
              onInputChange={(event, newInputEmail) => {
                const options = newInputEmail.split(",");
                if (options.length > 1) {
                  this.setState({
                    toEmails: toEmails
                      .concat(options)
                      .map((x) => x.trim())
                      .filter((x) => x),
                  });
                } else {
                  this.setState({ inputToEmail: newInputEmail });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To:"
                  placeholder="Type a comma or 'enter' to separate multiple email addresses"
                  error={!!errors.toEmails}
                  helperText={errors.toEmails}
                  autoFocus={!isFollowUp}
                />
              )}
            />
            {showCc && (
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                filterSelectedOptions
                id="ccEmails"
                options={teamContactsList}
                value={ccEmails}
                inputValue={inputCcEmail}
                onChange={(event, newEmails) => {
                  this.setState({ ccEmails: newEmails });
                }}
                onInputChange={(event, newInputEmail) => {
                  const options = newInputEmail.split(",");
                  if (options.length > 1) {
                    this.setState({
                      ccEmails: ccEmails
                        .concat(options)
                        .map((x) => x.trim())
                        .filter((x) => x),
                    });
                  } else {
                    this.setState({ inputCcEmail: newInputEmail });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cc:"
                    placeholder="Type a comma or 'enter' to separate multiple email addresses"
                    error={!!errors.ccEmails}
                    helperText={errors.ccEmails}
                  />
                )}
              />
            )}
            {showBcc && (
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                filterSelectedOptions
                id="bccEmails"
                options={teamContactsList}
                value={bccEmails}
                inputValue={inputBccEmail}
                onChange={(event, newEmails) => {
                  this.setState({ bccEmails: newEmails });
                }}
                onInputChange={(event, newInputEmail) => {
                  const options = newInputEmail.split(",");
                  if (options.length > 1) {
                    this.setState({
                      bccEmails: ccEmails
                        .concat(options)
                        .map((x) => x.trim())
                        .filter((x) => x),
                    });
                  } else {
                    this.setState({ inputBccEmail: newInputEmail });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bcc:"
                    placeholder="Type a comma or 'enter' to separate multiple email addresses"
                    error={!!errors.bccEmails}
                    helperText={errors.bccEmails}
                  />
                )}
              />
            )}
            {!showCc && (
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => this.setState({ showCc: true })}
              >
                Add Cc
              </Button>
            )}
            {!showBcc && (
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => this.setState({ showBcc: true })}
              >
                Add Bcc
              </Button>
            )}
            <TextField
              id="subjectLine"
              name="subjectLine"
              label="Subject:"
              type="string"
              error={!!errors.subjectLine}
              helperText={errors.subjectLine}
              required
              fullWidth
              value={this.state.subjectLine}
              onChange={this.handleChange}
            />
            {contentType.includeCoveringText && (
              <TextField
                id="coveringText"
                name="coveringText"
                type="string"
                error={!!errors.coveringText}
                helperText={errors.coveringText}
                multiline
                minRows={5}
                maxRows={99}
                required
                fullWidth
                autoFocus={isFollowUp}
                label="Covering Text"
                value={this.state.coveringText}
                onChange={this.handleChange}
              />
            )}
          </DialogContent>
          <DialogActions>
            {errors.sending && (
              <Typography variant="body2">
                Error sending email: {errors.sending}
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              disabled={processingSend}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              disabled={processingSend}
              color="primary"
              endIcon={processingSend ? <Loader size={20} /> : <Send />}
            >
              {processingSend ? "Sending" : "Send"}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(SendByEmailDialog);
