import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  useFirestoreConnect,
  withFirebase,
  isLoaded
} from "react-redux-firebase";
import Helmet from "react-helmet";
import {
  Container as MuiContainer,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography
} from "@material-ui/core";

import Loader from "../../components/Loader";
import { spacing } from "@material-ui/system";
import DocumentTable from "../../components/DocumentTable";
import BlankHomeImage from "../../assets/blank-home.png";
import trackEvent from "../../util/trackEvent";
import AppSumoFirstVisitDialog from "../../components/AppSumoFirstVisitDialog";
import TermsDialog from "../../components/TermsDialog";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Container = styled(MuiContainer)(spacing);

function MyPingGo({
  myDocuments,
  auth,
  profile,
  sharedDocuments,
  approverDocuments,
  history,
  firebase
}) {
  useFirestoreConnect([
    // we append the uid to the storeAs reference to make each unique, avoiding
    // a brief flash of previous user details if someone else logs in
    {
      collectionGroup: "documents",
      where: ["ownerUserId", "==", auth.uid],
      storeAs: "myDocuments" + auth.uid
    },
    {
      collectionGroup: "documents",
      where: ["sharingUserIds", "array-contains", auth.uid],
      storeAs: "sharedDocuments" + auth.uid
    },
    {
      collectionGroup: "documents",
      where: ["approverUserIds", "array-contains", auth.uid],
      storeAs: "approverDocuments" + auth.uid
    }
  ]);

  useEffect(() => {
    trackEvent(firebase, "Viewed the MyPingGo page");
  }, [firebase]);

  // useEffect(() => {
  //   beforePage();
  // }, []);

  if (!isLoaded(profile)) return <Loader />;

  if (!profile.termsConsentedOn) return <TermsDialog />;

  if (profile.grandfathered && !profile.modal_hidden_appsumo)
    return <AppSumoFirstVisitDialog />;

  if (!isLoaded(myDocuments, sharedDocuments, approverDocuments)) {
    return <Loader />;
  }

  const awaitingApproval = approverDocuments.filter(
    (doc) => !doc.approvedUserIds.includes(auth.uid)
  );
  const allDocumentsLength =
    myDocuments.length + sharedDocuments.length + awaitingApproval.length;

  return (
    <>
      <Helmet title="My PingGo" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h2" gutterBottom display="inline">
            My PingGo
          </Typography>
        </Grid>
      </Grid>
      <Divider my={3} />
      {allDocumentsLength > 0 ? (
        <>
          {awaitingApproval.length > 0 && (
            <>
              <h2>Awaiting my approval</h2>
              <DocumentTable documents={awaitingApproval} history={history} />
            </>
          )}
          {myDocuments.length > 0 && (
            <>
              <h2>Owned by me</h2>
              <DocumentTable documents={myDocuments} history={history} />
            </>
          )}
          {sharedDocuments.length > 0 && (
            <>
              <h2>Shared with me</h2>
              <DocumentTable documents={sharedDocuments} history={history} />
            </>
          )}
        </>
      ) : (
        <Container maxWidth="sm" mt={12}>
          <Typography variant="h2" align="center" gutterBottom>
            Your current documents
          </Typography>
          <Typography variant="body1" align="center">
            The MyPingGo page shows you all the documents you're involved with,
            whether as an Owner, as one that's been shared with you, or an
            Approver or Sign-off, in descending order of release date (most
            urgent first).
          </Typography>
          <Typography variant="body1" align="center">
            They'll appear here when you've created at least one.
          </Typography>
          <img
            src={BlankHomeImage}
            style={{ marginLeft: "100px", marginTop: "48px" }}
            height="200"
            alt="Blank Home"
          />
        </Container>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myDocuments: state.firestore.ordered[`myDocuments${state.firebase.auth.uid}`],
  sharedDocuments:
    state.firestore.ordered[`sharedDocuments${state.firebase.auth.uid}`],
  approverDocuments:
    state.firestore.ordered[`approverDocuments${state.firebase.auth.uid}`]
});

export default compose(withFirebase, connect(mapStateToProps))(MyPingGo);
