import React, { Component, Fragment, useState } from "react";
import "react-chat-elements/dist/main.css";
import styled from "styled-components";
import {
  firestoreConnect,
  isEmpty,
  isLoaded,
  withFirestore,
} from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "recompose";
import stc from "string-to-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format, formatDistanceToNow } from "date-fns";

import {
  Typography as MuiTypography,
  Button as MuiButton,
  Box as MuiBox,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Tooltip,
  Avatar,
  Card as MuiCard,
  Toolbar as MuiToolbar,
  ListItem as MuiListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemAvatar,
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  List as MuiList,
  IconButton,
  Paper as MuiPaper,
  TextField,
  AccordionActions as MuiAccordionActions,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import initials from "../../util/initials";
import htmlToPlainText from "../../util/htmlToPlainText";
import isValidEmail from "../../util/isValidEmail";

import Loader from "../../components/Loader";
import DocumentHeader from "../../components/DocumentHeader";
import SendByEmailDialog from "../../components/SendByEmailDialog";
import canAccessPage from "../../util/canAccessPage";
import CONTENT_TYPES from "../../util/contentTypes";

import {
  CancelScheduleSend as EmailError,
  Drafts as EmailOpened,
  Email as EmailDelivered,
  Send as EmailSending,
  Settings as EmailProcessing,
  Archive,
  ExpandMore,
  Person,
  Clear,
  PanTool,
  PanToolOutlined,
  InsertComment,
} from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import updateEmailVerification from "../../util/updateEmailVerification";
import { Helmet } from "react-helmet";
import trackEvent from "../../util/trackEvent";
import ManageDocumentMenu from "../../components/ManageDocumentMenu";
import DocumentAttributesDialog from "../../components/DocumentAttributesDialog";
import prefaceForDoc from "../../util/prefaceForDoc";
import DistributionDialog from "../../components/DistributionDialog";
import DistributionNoteDialog from "../../components/DistributionNoteDialog";
import AssignItemMenu from "../../components/AssignItemMenu";

const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Box = styled(MuiBox)(spacing);
const List = styled(MuiList)(spacing);
const Typography = styled(MuiTypography)(spacing);
const AccordionActions = styled(MuiAccordionActions)(spacing);

const Toolbar = styled(MuiToolbar)`
  .MuiButton-root {
    margin: 0 5px 0 5px;
  }
  padding-left: 0;
`;
const ListItem = styled(MuiListItem)(spacing);
const AccordionDetails = styled(MuiAccordionDetails)`
  display: inline;
  padding: 0;
`;
const EllipsisWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50vw;
`;
const Preview = styled.div`
  padding-left: 12px;
  // h1 {
  //   font-size: 1.2rem;
  //   font-weight: 600;
  //   line-height: 1.2;
  // }
  // h2 {
  //   font-size: 1rem;
  //   font-weight: 600;
  //   line-height: 1.2;
  // }
  // h3 {
  //   font-size: 0.9rem;
  //   font-weight: 600;
  //   line-height: 1.2;
  // }
`;
const ArchiveIcon = styled(Archive)`
  padding-left: 3px;
`;
const HandUp = styled(PanTool)`
  height: 18px;
  width: 18px;
  margin-right: 2px;
`;
const HandUpOutlined = styled(PanToolOutlined)`
  height: 18px;
  width: 18px;
  margin-right: 2px;
`;
const SmallClear = styled(Clear)`
  height: 20px;
  width: 20px;
`;
const SmallAvatar = styled(Avatar)`
  height: 27px;
  width: 27px;
  font-size: 0.75rem;
  margin-left: 1px;
`;
const EMAIL_STATUS = {
  PROCESSING: "delivering.",
  SUCCESS: "delivered.",
  ERROR: "but an error occurred in delivery.",
};

const statusIcon = (sentEmail) => {
  if (sentEmail.openedAt) {
    return <EmailOpened />;
  } else if (sentEmail.deliveryState) {
    if (sentEmail.deliveryState === "ERROR") return <EmailError />;
    if (sentEmail.deliveryState === "SUCCESS") return <EmailDelivered />;
    return <EmailSending />;
  }
  return <EmailProcessing />;
};

const recipientStatus = (sentEmail, recipient) => {
  const recipientKey = recipient.replace(/\./g, ":");
  if (sentEmail[`openBy-${recipientKey}`]) {
    return ` - opened ${format(
      sentEmail[`openBy-${recipientKey}`],
      "d MMM y h:mm bbb"
    )}`;
  }
  if (sentEmail[`deliveryTo-${recipientKey}`])
    return ` - delivered ${format(
      sentEmail[`deliveryTo-${recipientKey}`],
      "d MMM y h:mm bbb"
    )}`;
  return null;
};

const statusText = (sentEmail) => {
  let status = "sent " + formatDistanceToNow(sentEmail.sentOn) + " ago";
  if (sentEmail.openedAt) {
    status += ", opened " + formatDistanceToNow(sentEmail.openedAt) + " ago.";
  } else if (sentEmail.deliveryState) {
    status += ",  " + EMAIL_STATUS[sentEmail.deliveryState];
  }
  return status;
};

const TooltipIconButton = (props) => {
  //controlled tooltip to work round tips staying open when clicked
  const {
    active,
    onClick,
    activeTitle,
    inactiveTitle,
    activeIcon,
    inactiveIcon,
  } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClick = () => {
    setOpen(false);
    onClick();
  };
  return (
    <IconButton size="small" onClick={handleClick}>
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        title={active ? activeTitle : inactiveTitle}
        arrow
        disableFocusListener
      >
        {active ? activeIcon : inactiveIcon}
      </Tooltip>
    </IconButton>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

class ShareDoc extends Component {
  constructor(props) {
    super();
    this.state = {
      tab: 1,
      copied: false,
      teamContactsMap: null,
      teamId: null,
      newDistributionEmail: "",
      errors: {},
      newEmailCoveringText: "",
    };
  }

  componentDidMount() {
    // we don't have the teamId in the path parameters, so we can't rely on the
    // firebase connect to build the complete question map
    // we use the unwrapped firestore methods instead
    let teamContacts = [];
    let teamId;
    this.props.firestore
      .collection(`workspaces`)
      .doc(this.props.match.params.workspaceId)
      .get()
      .then((result) => {
        teamId = result.data().teamId;
        if (teamId) {
          return this.props.firestore
            .collection(`teams/${teamId}/contacts`)
            .get()
            .then((snapshot) => {
              snapshot.forEach((contact) => {
                teamContacts.push(contact.data());
              });
            });
        }
      })
      .then(() => {
        const teamContactsMap = teamContacts.reduce((acc, cur) => {
          acc[cur.email] = cur;
          return acc;
        }, {});
        this.setState({ teamContactsMap, teamId });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate() {
    updateEmailVerification(this.props);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  handleBack = (backPath) => {
    this.props.history.push(backPath);
  };

  handleArchive = () => {
    const workspaceId = this.props.match.params.workspaceId;
    this.props.firestore
      .update(
        `workspaces/${workspaceId}/documents/${this.props.match.params.documentId}`,
        {
          isArchived: true,
        }
      )
      .then(() => {
        trackEvent(this.props.firebase, "Archived content from the Send stage");
        this.props.history.push(`/workspaces/${workspaceId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addToDistribution = (newContactEmails, assignedUserEmail) => {
    let { distributionList } = this.props.document;
    const assignedDistributions = {
      ...this.props.document.assignedDistributions,
    };
    if (assignedUserEmail) {
      newContactEmails.forEach((email) => {
        assignedDistributions[email] = assignedUserEmail;
      });
    }
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          distributionList: (distributionList || []).concat(newContactEmails),
          assignedDistributions,
        }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Added to distribution list");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeFromDistribution = (email) => {
    let { distributionList, assignedDistributions } = this.props.document;
    let currentAssignedDistributions = { ...assignedDistributions };
    delete currentAssignedDistributions[email];
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          distributionList: (distributionList || []).filter(
            (existingEmail) => existingEmail !== email
          ),
          assignedDistributions: currentAssignedDistributions,
        }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Removed from distribution list");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAddDistributionEmail = () => {
    let { newDistributionEmail, errors } = this.state;
    let { distributionList } = this.props.document;
    if (!isValidEmail(newDistributionEmail)) {
      errors.newDistributionEmail = "Enter a valid email address";
    } else if (
      distributionList &&
      distributionList.includes(newDistributionEmail)
    ) {
      errors.newDistributionEmail = "Already in the list!";
    } else {
      this.addToDistribution([newDistributionEmail]);
      this.setState({ newDistributionEmail: "" });
      errors = {};
    }
    this.setState({ errors });
  };

  handlePrevious = () => {
    const docPath = `/workspaces/${this.props.match.params.workspaceId}/documents/${this.props.match.params.documentId}`;
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          modifiedOn: new Date().getTime(),
          stage: "signoff",
        }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Moved back to sign-off");
        this.props.history.push(docPath + "/signoff");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  coveringTextForEmail = (email) => {
    return (
      "| " +
      htmlToPlainText(
        email.HtmlBody.substring(0, email.HtmlBody.indexOf("<hr>"))
      ).replace(/\n/g, "\n| ")
    );
  };

  persistCoveringText = (newEmailCoveringText) => {
    this.setState({ newEmailCoveringText });
  };

  toggleAssignDistributionItem = (email) => {
    const userEmail = this.props.profile.email;
    const assignedDistributions = {
      ...this.props.document.assignedDistributions,
    };

    if (assignedDistributions[email] === userEmail) {
      delete assignedDistributions[email];
    } else {
      assignedDistributions[email] = userEmail;
    }
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        { assignedDistributions }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Toggle a distribution assignment");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  assignUserToDistribution = (distEmail, userEmail) => {
    const assignedDistributions = {
      ...this.props.document.assignedDistributions,
    };

    if (!userEmail) {
      delete assignedDistributions[distEmail];
    } else {
      assignedDistributions[distEmail] = userEmail;
    }
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        { assignedDistributions }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Changed a distribution assignment");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleDistributionDone = (email) => {
    const existingDistributions = this.props.document.completedDistributions;
    let completedDistributions = existingDistributions
      ? [...existingDistributions]
      : [];
    if (completedDistributions.includes(email)) {
      completedDistributions = completedDistributions.filter(
        (entry) => entry !== email
      );
    } else {
      completedDistributions.push(email);
    }
    // console.log(completedDistributions);
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        { completedDistributions }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Toggle a distribution done flag");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  statusLine = (email) => {
    const recipients = email.To.split(",");
    let line = "";
    recipients.forEach((recipient) => {
      line += this.listPrimaryText(recipient) + ", ";
    });
    return line.substring(0, line.length - 2); // remove final commaSpace
  };

  listPrimaryText = (email) => {
    const { teamContactsMap } = this.state;
    if (!teamContactsMap || !teamContactsMap[email]) return email;
    let text = `${teamContactsMap[email].firstName} ${teamContactsMap[email].lastName}`;
    if (teamContactsMap[email].publication)
      text += ` (${teamContactsMap[email].publication})`;
    return text.trim();
  };

  listAvatar = (email) => {
    const { sharingUsers, ownerUser } = this.props.workspace;
    const users = sharingUsers.concat(ownerUser);
    const user = users.find((user) => user.email === email);
    if (!user) return null;
    return (
      <Tooltip title={email} arrow>
        <ListItemAvatar edge="end">
          <SmallAvatar
            alt={user.name}
            src={user.photo}
            style={{
              backgroundColor: stc(user.name),
            }}
          >
            {initials(user.name)}
          </SmallAvatar>
        </ListItemAvatar>
      </Tooltip>
    );
  };

  listItemIcon = (toEmail) => {
    const assigned = this.props.document.assignedDistributions || {};
    const assignedTo = assigned[toEmail];
    if (!assignedTo)
      return (
        <Tooltip title="Unassigned" arrow>
          <Person />
        </Tooltip>
      );
    return this.listAvatar(assignedTo);
  };

  handleDeleteNote = (toEmail, noteToDelete) => {
    const distributionNotes = { ...this.props.document.distributionNotes };
    distributionNotes[toEmail] = distributionNotes[toEmail].filter(
      (note) => note.sentOn !== noteToDelete.sentOn
    );
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          distributionNotes,
        }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Deleted a distribution note");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  detailForDistributionItem = (toEmail, item, isLast) => {
    if (item.Subject) {
      //must be an email
      return (
        <ListItem key={item.sentOn} alignItems="flex-start" divider={!isLast}>
          <ListItemIcon>{statusIcon(item)}</ListItemIcon>
          <Box>
            <Typography>From: {item.From}</Typography>
            <Typography>
              Sent: {format(item.sentOn, "d MMM y h:mm bbb")}{" "}
            </Typography>
            {item.To.split(",").map((recipient) => (
              <Typography key={recipient}>
                To: {recipient}
                {recipientStatus(item, recipient)}
              </Typography>
            ))}
            {item.Cc &&
              item.Cc.split(",").map((recipient) => (
                <Typography key={recipient}>
                  Cc: {recipient}
                  {recipientStatus(item, recipient)}
                </Typography>
              ))}
            {item.Bcc &&
              item.Bcc.split(",").map((recipient) => (
                <Typography key={recipient}>
                  Bcc: {recipient}
                  {recipientStatus(item, recipient)}
                </Typography>
              ))}
            <Typography>Subject: {item.Subject}</Typography>
            <Typography ml={2}>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.HtmlBody.substring(
                    0,
                    item.HtmlBody.indexOf("<hr>")
                  ),
                }}
              ></span>
            </Typography>
          </Box>
        </ListItem>
      );
    } else {
      // must be a note
      return (
        <ListItem key={item.sentOn} alignItems="flex-start" divider={!isLast}>
          <ListItemIcon>
            <InsertComment />
          </ListItemIcon>
          <Box>
            <Typography>
              Note by {item.authorEmail} on{" "}
              {format(item.sentOn, "d MMM y h:mm bbb")}
            </Typography>
            <Typography ml={2}>
              <i>{item.noteText}</i>
            </Typography>
          </Box>
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              size="small"
              onClick={() => this.handleDeleteNote(toEmail, item)}
            >
              <SmallClear />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    }
  };

  groupedEmailsInProgress = (groupedEmails) =>
    Object.fromEntries(
      Object.entries(groupedEmails).filter(
        ([email, entry]) =>
          !(this.props.document.completedDistributions || []).includes(email)
      )
    );

  groupedEmailsCompleted = (groupedEmails) =>
    Object.fromEntries(
      Object.entries(groupedEmails).filter(([email, entry]) =>
        (this.props.document.completedDistributions || []).includes(email)
      )
    );

  distributionItemsForToEmail = (groupedEmails, toEmail) => {
    let items = groupedEmails[toEmail];
    if (this.props.document.distributionNotes?.[toEmail])
      items = items.concat(this.props.document.distributionNotes[toEmail]);
    return items.sort((a, b) => b.sentOn - a.sentOn);
    // return items;
  };

  accordionForGroupedEmails = (groupedEmails, completed) => {
    const { document } = this.props;
    const { documentId, workspaceId } = this.props.match.params;
    const contentType = CONTENT_TYPES[document.contentType];
    const releaseHtml = prefaceForDoc(document) + document.text;

    return Object.keys(groupedEmails).map((toEmail) => (
      <Accordion key={toEmail}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <ListItem py={0}>
            <ListItemIcon>{statusIcon(groupedEmails[toEmail][0])}</ListItemIcon>
            <ListItemText
              primary={this.statusLine(groupedEmails[toEmail][0])}
              secondary={"last " + statusText(groupedEmails[toEmail][0])}
            />
            {this.listAvatar(groupedEmails[toEmail][0].From)}
          </ListItem>
        </AccordionSummary>
        <AccordionActions py={0}>
          <Button
            onClick={() => this.toggleDistributionDone(toEmail)}
            size="small"
            color="primary"
            variant="outlined"
          >
            Mark as {completed ? "not done" : "done"}
          </Button>
          <DistributionNoteDialog
            document={document}
            documentId={documentId}
            workspaceId={workspaceId}
            toEmail={toEmail}
            mode="Add"
          />
          <SendByEmailDialog
            isFollowUp
            contentType={contentType}
            releaseHtml={releaseHtml}
            releaseSubject={
              (contentType.prefix ? contentType.prefix + ": " : "") +
              document.title
            }
            documentId={documentId}
            workspace={this.props.workspace}
            toEmail={toEmail}
            coveringText={
              `\n\nOn ${format(
                groupedEmails[toEmail][0].sentOn,
                "d MMM y h:mm aaa"
              )} ${groupedEmails[toEmail][0].From} wrote: \n` +
              this.coveringTextForEmail(groupedEmails[toEmail][0])
            }
          />
        </AccordionActions>
        <AccordionDetails>
          <List ml={4} mt={2}>
            {this.distributionItemsForToEmail(groupedEmails, toEmail).map(
              (item, index) =>
                this.detailForDistributionItem(
                  toEmail,
                  item,
                  index ===
                    this.distributionItemsForToEmail(groupedEmails, toEmail)
                      .length -
                      1
                )
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    ));
  };

  render() {
    const { teamContactsMap, teamId, newEmailCoveringText } = this.state;
    const { document, workspace, profile, auth, sentEmails } = this.props;
    const { documentId, workspaceId } = this.props.match.params;

    if (
      !isLoaded(document, workspace, profile, sentEmails) ||
      isEmpty(document)
    )
      return <Loader />;

    if (!canAccessPage(auth, document, workspace)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: {
              from: { pathname: this.props.history.location.pathname },
            },
          }}
        />
      );
    }
    const groupedEmails = this.props.sentEmails.reduce((acc, obj) => {
      let key = obj.To;
      if (!acc[key]) acc[key] = [];
      acc[key].push(obj);
      return acc;
    }, {});

    const contentType = CONTENT_TYPES[document.contentType];
    const releaseHtml = prefaceForDoc(document) + document.text;

    return (
      <>
        <Helmet title={"Share " + contentType.nameWithArticle} />
        <div className="flex-no-shrink">
          <DocumentHeader
            activeStep={4}
            userId={profile.userId}
            workspace={this.props.workspace}
            workspaceId={workspaceId}
            handleBack={this.handleBack}
          />
          <Grid
            container
            justifyContent="space-between"
            spacing={4}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item container alignItems="center" wrap="nowrap">
              <Grid mx={2}>
                <EllipsisWrapper>
                  <Typography variant="h4" gutterBottom display="inline" noWrap>
                    {document.title}
                  </Typography>
                </EllipsisWrapper>
              </Grid>
            </Grid>
            <Grid
              item
              container
              wrap="nowrap"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Tooltip title={`Owner: ${document.ownerUser.name}`} arrow>
                  <Avatar
                    alt={document.ownerUser.name}
                    src={document.ownerUser.photo}
                    style={{
                      backgroundColor: stc(document.ownerUser.name),
                    }}
                  >
                    {initials(document.ownerUser.name)}
                  </Avatar>
                </Tooltip>
              </Grid>
            </Grid>
            {document.isArchived && (
              <Grid item>
                <Tooltip title="Archived" arrow>
                  <ArchiveIcon color="secondary" />
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <DocumentAttributesDialog
                mode="button"
                document={document}
                documentId={documentId}
              />
            </Grid>
            <Grid item>
              <ManageDocumentMenu
                documentId={documentId}
                document={document}
                handlePrevStage={this.handlePrevious}
                prevStageDescription="Sign-off"
              />
            </Grid>
            <Grid item style={{ whiteSpace: "nowrap" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleArchive}
                ml={2}
              >
                Archive and exit
              </Button>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Grid container spacing={6} className="flex-section">
          <Grid item xs={7} py={4} px={4} className="flex-col-scroll-left">
            <Toolbar>
              <CopyToClipboard
                text={releaseHtml}
                onCopy={() => this.setState({ copied: true })}
              >
                <Button variant="outlined" color="primary">
                  Copy HTML
                </Button>
              </CopyToClipboard>{" "}
              <CopyToClipboard
                text={htmlToPlainText(releaseHtml)}
                onCopy={() => this.setState({ copied: true })}
              >
                <Button variant="outlined" color="primary">
                  Copy Plain Text
                </Button>
              </CopyToClipboard>{" "}
              {this.state.copied && (
                <Typography variant="body2" display="inline">
                  Copied
                </Typography>
              )}
            </Toolbar>
            <Card>
              <DocumentAttributesDialog
                mode="preface"
                document={document}
                documentId={documentId}
              />
              <Preview
                dangerouslySetInnerHTML={{
                  __html: document.text,
                }}
              ></Preview>
            </Card>
          </Grid>
          <Grid item xs={5} py={4} px={4} className="flex-col-scroll-right">
            <Toolbar>
              <SendByEmailDialog
                contentType={contentType}
                releaseHtml={releaseHtml}
                releaseSubject={
                  (contentType.prefix ? contentType.prefix + ": " : "") +
                  document.title
                }
                documentId={documentId}
                workspace={this.props.workspace}
                teamContactsMap={teamContactsMap}
                persistCoveringText={this.persistCoveringText}
                coveringText={newEmailCoveringText}
              />
            </Toolbar>
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab
                label={
                  <Badge
                    badgeContent={document.distributionList?.length}
                    color="primary"
                  >
                    To Do
                  </Badge>
                }
                className="tour-todo"
              />
              <Tab
                label={
                  <Badge
                    badgeContent={
                      Object.keys(this.groupedEmailsInProgress(groupedEmails))
                        ?.length
                    }
                    color="primary"
                  >
                    In Progress
                  </Badge>
                }
                className="tour-in-progress"
              />
              <Tab
                label={
                  <Badge
                    badgeContent={
                      Object.keys(this.groupedEmailsCompleted(groupedEmails))
                        ?.length
                    }
                    color="primary"
                  >
                    Done
                  </Badge>
                }
                className="tour-in-progress"
              />
            </Tabs>
            <TabPanel value={this.state.tab} index={0}>
              {teamContactsMap &&
                document.distributionList &&
                document.distributionList.length > 0 && (
                  <Paper>
                    <List>
                      {document.distributionList.map((email, index) => (
                        <ListItem
                          key={email}
                          py={0}
                          divider={
                            index !== document.distributionList.length - 1
                          }
                        >
                          <AssignItemMenu
                            toEmail={email}
                            assignedDistributions={
                              document.assignedDistributions
                            }
                            workspace={workspace}
                            handleAssignUser={this.assignUserToDistribution}
                          />
                          <ListItemText primary={this.listPrimaryText(email)} />
                          <ListItemSecondaryAction>
                            <TooltipIconButton
                              size="small"
                              onClick={() =>
                                this.toggleAssignDistributionItem(email)
                              }
                              active={
                                document.assignedDistributions &&
                                document.assignedDistributions[email] ===
                                  profile.email
                              }
                              activeTitle="Hand back"
                              inactiveTitle="Assign to me"
                              activeIcon={<HandUp />}
                              inactiveIcon={<HandUpOutlined />}
                            ></TooltipIconButton>
                            <SendByEmailDialog
                              isFromDistribution
                              contentType={contentType}
                              releaseHtml={releaseHtml}
                              releaseSubject={
                                (contentType.prefix
                                  ? contentType.prefix + ": "
                                  : "") + document.title
                              }
                              documentId={documentId}
                              workspace={this.props.workspace}
                              toEmail={email}
                              afterSend={() =>
                                this.removeFromDistribution(email)
                              }
                              persistCoveringText={this.persistCoveringText}
                              coveringText={newEmailCoveringText}
                            />
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() => this.removeFromDistribution(email)}
                            >
                              <SmallClear />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                )}
              <Box mb={20} display="flex" alignItems="center" flexWrap="wrap">
                <Box flexGrow={1}>
                  <TextField
                    variant="standard"
                    name="newDistributionEmail"
                    fullWidth
                    value={this.state.newDistributionEmail}
                    onChange={(event) =>
                      this.setState({
                        newDistributionEmail: event.target.value,
                      })
                    }
                    placeholder="Enter an email address"
                    error={!!this.state.errors.newDistributionEmail}
                    helperText={this.state.errors.newDistributionEmail}
                  />
                </Box>
                <Box>
                  <Button
                    ml={3}
                    mt={3}
                    variant="contained"
                    color="primary"
                    disabled={!isValidEmail(this.state.newDistributionEmail)}
                    onClick={this.handleAddDistributionEmail}
                  >
                    Add to List
                  </Button>
                  {teamId &&
                    teamContactsMap &&
                    Object.keys(teamContactsMap).length > 0 && (
                      <DistributionDialog
                        contacts={Object.values(teamContactsMap)}
                        teamId={this.state.teamId}
                        addToDistribution={this.addToDistribution}
                        distributionList={document.distributionList || []}
                        workspace={workspace}
                      />
                    )}
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={this.state.tab} index={1}>
              {this.accordionForGroupedEmails(
                this.groupedEmailsInProgress(groupedEmails),
                false // all not completed
              )}
            </TabPanel>

            <TabPanel value={this.state.tab} index={2}>
              {this.accordionForGroupedEmails(
                this.groupedEmailsCompleted(groupedEmails),
                true // all completed
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workspaceId = ownProps.match.params.workspaceId;
  const documentId = ownProps.match.params.documentId;
  const workspaces = state.firestore.data.workspaces;
  const documents = state.firestore.data[`workspaces/${workspaceId}/documents`];
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    workspace: workspaces ? workspaces[workspaceId] : {},
    documents,
    document: documents ? documents[documentId] : {},
    sentEmails:
      state.firestore.ordered[`sentEmails${ownProps.match.params.documentId}`],
  };
}

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: `workspaces/${props.match.params.workspaceId}/documents`,
      doc: props.match.params.documentId,
    },
    {
      collection: "workspaces",
      doc: props.match.params.workspaceId,
    },
    {
      collection: "email",
      where: [
        ["Tag", "==", "release"],
        ["documentId", "==", props.match.params.documentId],
      ],
      orderBy: ["sentOn", "desc"],
      storeAs: `sentEmails${props.match.params.documentId}`,
    },
  ]),
  connect(mapStateToProps)
)(ShareDoc);
