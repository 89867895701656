import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid as MuiGrid,
  Typography as MuiTypography,
  LinearProgress,
  Tooltip,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import Loader from "../../components/Loader";
import { Archive } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import SaveStatusCloud from "../../components/SaveStatusCloud";
import AccordionQuestionItem from "../../components/AccordionQuestionItem";
import DocumentHeader from "../../components/DocumentHeader";
import Tour from "../../components/Tour";
import ManageDocumentMenu from "../../components/ManageDocumentMenu";
import canAccessPage from "../../util/canAccessPage";
import trackEvent from "../../util/trackEvent";
import CONTENT_TYPES from "../../util/contentTypes";
import DocumentAttributesDialog from "../../components/DocumentAttributesDialog";

const Grid = styled(MuiGrid)(spacing);
const Progress = styled(LinearProgress)`
  margin: 0 //12px 0 12px;
  width: 100%;
  height: 3px;
`;
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;
const ArchiveIcon = styled(Archive)`
  // padding-top: 5px;
  padding-left: 3px;
`;
const SwitchBox = styled(Box)`
  text-align: right;
`;
const Typography = styled(MuiTypography)(spacing);
const EllipsisWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50vw;
`;
const Preview = styled.div`
  padding-left: 12px;
  // h1 {
  //   font-size: 1rem;
  //   font-weight: 800;
  //   line-height: 1.2;
  // }
  // h2 {
  //   font-size: 0.95rem;
  //   font-weight: 700;
  //   line-height: 1.2;
  // }
  // h3 {
  //   font-size: 0.9rem;
  //   font-weight: 700;
  //   line-height: 1.2;
  // }
`;

const STATUS_DIRTY = "Edited";
const STATUS_SAVED = "All changes saved";
const STATUS_SAVING = "Saving changes...";
const STATUS_ERROR = "Error saving changes, retrying...";

class CreateDoc extends Component {
  constructor(props) {
    super();
    this.state = {
      expandedQuestion: "0",
      saveStatus: STATUS_SAVED,
      saveIntervalId: null,
      document: null,
      workspaceKeyAnswers: {},
      teamKeyAnswers: {},
      answers: null,
      questions: [],
      questionMap: {},
      // teamQuestions: {}
      unlockKeyMessages: false,
    };
  }

  componentDidMount() {
    // we use undispatched firestore queries, because we need the results
    // in the state before we can do anything else
    let document = {};
    let workspace = {};
    let answers = {};
    let workspaceKeyAnswers = {};
    let originalWorkspaceKeyAnswers = [];
    let teamKeyAnswers = {};
    let originalTeamKeyAnswers = [];
    let teamQuestions = {};
    const pingGoQuestions = {};
    let questionMap = {};
    let questions = [];
    let text = "";

    this.props.firestore
      .collection(`workspaces/${this.props.match.params.workspaceId}/documents`)
      .doc(this.props.match.params.documentId)
      .get()
      .then((doc) => {
        document = doc.data();
        answers = document.answers || {};
        // from 2.0.14 we persisted the templateText when it was selected so we can return to this stage
        // with the original answers if we want
        text = document.templateText || document.text;
      })
      .then(() => {
        return this.props.firestore
          .collection(`workspaces`)
          .doc(this.props.match.params.workspaceId)
          .get();
      })
      .then((result) => {
        workspace = result.data();
        workspaceKeyAnswers = workspace.keyAnswers || {};
        originalWorkspaceKeyAnswers = { ...workspaceKeyAnswers };
      })
      .then(() => {
        const teamId = workspace.teamId;
        // ignore the PingGo team, it's special
        if (!teamId || teamId === "R7Z1iCDHpvS73plmK1iP") return true;
        return this.props.firestore
          .collection(`teams/${teamId}/questions`)
          .get()
          .then((snapshot) => {
            snapshot.forEach((question) => {
              teamQuestions[question.id] = question.data();
            });
          })
          .then(() => {
            return this.props.firestore.collection(`teams`).doc(teamId).get();
          })
          .then((result) => {
            teamKeyAnswers = result.data().keyAnswers || {};
            originalTeamKeyAnswers = { ...teamKeyAnswers };
          });
      })
      .then(() => {
        return (
          this.props.firestore
            // team PingGo questions
            .collection(`teams/R7Z1iCDHpvS73plmK1iP/questions`)
            .get()
        );
      })
      .then((snapshot) => {
        snapshot.forEach((question) => {
          pingGoQuestions[question.id] = question.data();
        });
      })
      .then(() => {
        const matches = text.match(/#(\w+)\b/gi);
        // remove # at start, then remove duplicates
        let tags = matches // empty for blank template
          ? Array.from(new Set(matches.map((match) => match.substring(1))))
          : [];
        // remove questions where key answers are already stored
        // if (!document.isDefaultTemplate) {
        // tags = tags.filter(
        //   (x) => !workspaceKeyAnswers[x] && !teamKeyAnswers[x]
        // );
        // }
        for (const questionId in pingGoQuestions) {
          const question = pingGoQuestions[questionId];
          questionMap[question.tag] = question;
        }
        // team custom questions with the same tag name override the library ones
        for (const questionId in teamQuestions) {
          const question = teamQuestions[questionId];
          questionMap[question.tag] = question;
        }
        let index = 0;
        for (const questionTag of tags) {
          let question = questionMap[questionTag];
          if (question) {
            questions.push({
              tag: questionTag,
              text: question.text,
              help: question.help,
              isKey: question.isKey,
              placeholder: question.placeholder,
              index: index++,
            });
          } else {
            console.log("missing question " + questionTag);
          }
        }
        return true;
      })
      .then(() => {
        answers = Object.assign(
          {},
          answers,
          workspaceKeyAnswers,
          teamKeyAnswers
        );
        // find the first non-locked question and expand it
        // const questionLength = questions.length;
        // let expandedQuestion = -1;
        // let tag = "";
        // do {
        //   expandedQuestion++;
        //   tag = questions[expandedQuestion.toString()].tag;
        // } while (
        //   // exclude locked questions using the same criteria as in the render method
        //   // but we haven't set the state yet, so we copy them here
        //   (expandedQuestion < questionLength &&
        //     originalWorkspaceKeyAnswers[tag] &&
        //     originalWorkspaceKeyAnswers[tag] !== "") ||
        //   (originalTeamKeyAnswers[tag] && originalTeamKeyAnswers[tag] !== "")
        // );
        // if (expandedQuestion >= questionLength) expandedQuestion = -1;
        this.setState({
          workspace,
          document,
          questions,
          questionMap,
          answers,
          workspaceKeyAnswers,
          originalWorkspaceKeyAnswers,
          teamKeyAnswers,
          originalTeamKeyAnswers,
          // expandedQuestion: expandedQuestion.toString()
        });
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  transformText = (highlighted = true) => {
    // from 2.0.14 we persist the templateText when the doc is created so we can return to this stage
    // with the original answers if we want
    let text = this.state.document.templateText || this.state.document.text;
    const answers = this.state.answers;
    const questionMap = this.state.questionMap;
    const expandedQuestion = this.state.questions[this.state.expandedQuestion];
    const expandedQuestionTag = expandedQuestion && expandedQuestion.tag;

    function replacer(match, p1, p2, p3, offset, string) {
      let thisQuestion = questionMap[p1];
      if (!thisQuestion) {
        console.log("Missing question definition " + p1);
        return "";
      }
      let answer = answers[p1]
        ? answers[p1].replace(/\n/g, "</p><p>")
        : thisQuestion.placeholder
        ? thisQuestion.placeholder.replace(/\S/g, "_")
        : "_____";
      if (!highlighted) return answer;
      const borderStyle =
        thisQuestion.tag === expandedQuestionTag
          ? " border: 2px solid #777;"
          : "";
      const backgroundColor = thisQuestion.isKey ? "#ccf" : "#fc9";
      return `<span style='padding: 1px; background-color:${backgroundColor};${borderStyle}'>${answer}</span>`;
    }
    text = text.replace(/#(\w+)\b/gi, replacer);
    return text;
  };

  transformTitle = () => {
    const answers = this.state.answers;
    const questionMap = this.state.questionMap;

    function replacer(match, p1, p2, p3, offset, string) {
      let thisQuestion = questionMap[p1];
      if (!thisQuestion) {
        console.log("Missing question definition " + p1);
        return "_____";
      }
      return answers[p1]
        ? answers[p1]
        : thisQuestion.placeholder
        ? thisQuestion.placeholder.replace(/\S/g, "_")
        : "_____";
    }

    const el = document.createElement("html");
    el.innerHTML = this.state.document.templateText || this.state.document.text;
    let firstChild = el.children[1].firstChild;
    if (!firstChild) return "Untitled";
    let match = firstChild.innerText;
    match = match.replace(/#(\w+)\b/gi, replacer);
    // empty nodes in CKEditor have whitespace in them, so in case we're using that here...
    if (match.replace(/\s/g, "").length > 0) return match;
    return "Untitled";
  };

  handleChangeExpanded = (question) => (event, expanded) => {
    this.setState({
      expandedQuestion: expanded ? question : false,
    });
  };

  answeredTotal = () => {
    let answered = 0;
    for (const question of this.state.questions) {
      if (this.state.answers[question.tag]) answered++;
    }
    return answered;
  };

  allAnswered = () => {
    return this.answeredTotal() === this.state.questions.length;
  };

  nextQuestion = () => {
    const questionLength = this.state.questions.length;
    let nextQ = Number(this.state.expandedQuestion);
    do {
      nextQ++;
    } while (
      nextQ < questionLength &&
      this.questionIsLocked(this.state.questions[nextQ.toString()].tag)
    );
    if (nextQ >= questionLength) return;
    this.setState({
      expandedQuestion: nextQ.toString(),
    });
  };

  prevQuestion = () => {
    let prevQ = Number(this.state.expandedQuestion);
    do {
      prevQ--;
    } while (
      prevQ > 0 &&
      this.questionIsLocked(this.state.questions[prevQ.toString()].tag)
    );
    if (prevQ < 0) return;
    this.setState({
      expandedQuestion: prevQ.toString(),
    });
  };

  handleChangeText = (event) => {
    const answers = this.state.answers;
    answers[event.target.name] = event.target.value;
    if (this.state.saveIntervalId) {
      clearInterval(this.state.saveIntervalId);
    }
    const newInterval = setInterval(this.saveChanges, 1000);
    this.setState({
      answers,
      saveStatus: STATUS_DIRTY,
      saveIntervalId: newInterval,
    });
  };

  saveChanges = (andExit = false, backPath = "/") => {
    clearInterval(this.state.saveIntervalId);
    if (this.state.saveStatus === STATUS_SAVED && andExit) {
      this.props.history.push(backPath);
    }
    this.setState({
      saveStatus: STATUS_SAVING,
    });
    const workspaceKeyAnswers = Object.assign(
      {},
      this.state.workspaceKeyAnswers
    );
    const docAnswers = {};
    this.state.questions.forEach((question) => {
      const answer = this.state.answers[question.tag];
      if (answer) {
        if (question.isKey && !this.state.teamKeyAnswers[question.tag]) {
          workspaceKeyAnswers[question.tag] = answer;
        } else {
          docAnswers[question.tag] = answer;
        }
      }
    });

    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          answers: docAnswers,
          modifiedOn: new Date().getTime(),
          title: this.transformTitle(this.state.answers),
          stageTarget: this.state.questions.length,
          stageProgress: this.answeredTotal(),
          stage: "create",
        }
      )
      .then(() => {
        this.props.firestore.update(
          {
            collection: `workspaces`,
            doc: this.props.match.params.workspaceId,
          },
          {
            keyAnswers: workspaceKeyAnswers,
            // hasKeyMessages
          }
        );
      })
      .then(() => {
        this.setState({
          saveStatus: STATUS_SAVED,
        });
        if (andExit) this.props.history.push(backPath);
      })
      .catch((err) => {
        const retryInterval = setInterval(this.saveChanges(), 3000);
        this.setState({
          saveStatus: STATUS_ERROR,
          saveIntervalId: retryInterval,
        });
        console.log(err);
      });
  };

  handleStartReview = () => {
    const docPath = `/workspaces/${this.props.match.params.workspaceId}/documents/${this.props.match.params.documentId}`;
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          modifiedOn: new Date().getTime(),
          title: this.transformTitle(),
          text: this.transformText(false), // not highlighted
          stage: "review",
          stageTarget: 0,
          stageProgress: 0,
        }
      )
      .then(() => {
        this.setState({
          saveStatus: STATUS_SAVED,
        });
        // console.log("saved " + this.transformText(false));
        trackEvent(this.props.firebase, `Moved content to review`);
        this.props.history.push(docPath + "/review");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleBack = (backPath) => {
    this.saveChanges(true, backPath);
  };

  toggleUnlockKeyMessages = () => {
    this.setState({ unlockKeyMessages: !this.state.unlockKeyMessages });
  };

  questionIsLocked = (tag) => {
    return (
      (!this.state.unlockKeyMessages &&
        this.state.originalWorkspaceKeyAnswers[tag] &&
        this.state.originalWorkspaceKeyAnswers[tag] !== "") ||
      (this.state.originalTeamKeyAnswers[tag] &&
        this.state.originalTeamKeyAnswers[tag] !== "")
    );
  };

  contentType = () => {
    return CONTENT_TYPES[this.props.listenedDocument.contentType];
  };

  render() {
    const {
      expandedQuestion,
      saveStatus,
      answers,
      document,
      questions,
      unlockKeyMessages,
    } = this.state;

    const { listenedDocument, profile, auth, workspace } = this.props;

    if (!document || !workspace || answers == null) {
      return <Loader />;
    }

    if (!canAccessPage(auth, document, workspace)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: {
              from: { pathname: this.props.history.location.pathname },
            },
          }}
        />
      );
    }

    const { documentId, workspaceId } = this.props.match.params;

    return (
      <Fragment>
        <Helmet title={"Create " + this.contentType().nameWithArticle} />
        <div className="flex-no-shrink">
          <DocumentHeader
            activeStep={1}
            userId={this.props.profile.userId}
            workspace={this.state.workspace}
            workspaceId={workspaceId}
            handleBack={this.handleBack}
          />
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            spacing={4}
            alignItems="center"
          >
            <Grid item container alignItems="center" wrap="nowrap">
              <Grid mx={1}>
                <EllipsisWrapper>
                  <Typography variant="h4" gutterBottom display="inline" noWrap>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.transformTitle(),
                      }}
                    ></span>
                  </Typography>
                </EllipsisWrapper>
              </Grid>
            </Grid>
            <Grid item>
              {listenedDocument && listenedDocument.isArchived && (
                <Tooltip title="Archived" arrow>
                  <ArchiveIcon color="secondary" />
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <DocumentAttributesDialog
                mode="button"
                document={listenedDocument}
                documentId={documentId}
              />
            </Grid>
            <Grid item>
              <SaveStatusCloud saveStatus={saveStatus} />
            </Grid>
            <Grid item>
              <Tour tourId={this.props.match.path} />
            </Grid>
            <Grid item>
              <ManageDocumentMenu
                documentId={documentId}
                document={listenedDocument}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="tour-review"
                color="primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleStartReview}
                disabled={
                  saveStatus === STATUS_DIRTY ||
                  saveStatus === STATUS_ERROR ||
                  !this.allAnswered()
                }
              >
                Next step
              </Button>
            </Grid>
          </Grid>
        </div>
        <Progress
          variant="determinate"
          value={(this.answeredTotal() / questions.length) * 100}
        />
        <Grid container className="flex-section">
          <Grid item xs={5} className="flex-col-scroll-left">
            {(Object.keys(this.state.originalWorkspaceKeyAnswers).length > 0 ||
              Object.keys(this.state.originalTeamKeyAnswers).length > 0) && (
              <Tooltip
                title={
                  profile.userId === workspace.ownerUserId
                    ? "Make existing Key Messages editable"
                    : "Disabled because only the workspace owner can change existing Key Messages"
                }
                arrow
              >
                <SwitchBox>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={profile.userId !== workspace.ownerUserId}
                        checked={unlockKeyMessages}
                        onChange={this.toggleUnlockKeyMessages}
                        name="unlockKeyMessages"
                        color="primary"
                      />
                    }
                    label="Unlock existing Key Messages"
                  />
                </SwitchBox>
              </Tooltip>
            )}
            {this.allAnswered() && (
              <Alert mt={3} mr={2} severity="success">
                All questions are answered, you can click 'Next step'.
              </Alert>
            )}
            <Card my={3} mr={2} elevation={0}>
              {questions.map((question) => (
                <AccordionQuestionItem
                  questions={questions}
                  answers={answers}
                  key={question.index}
                  question={question}
                  answer={answers[question.tag]}
                  expanded={
                    !this.questionIsLocked(question.tag) &&
                    expandedQuestion === question.index.toString()
                  }
                  handleChangeExpanded={
                    this.questionIsLocked(question.tag)
                      ? () => {}
                      : this.handleChangeExpanded
                  }
                  handleChangeText={this.handleChangeText}
                  nextQuestion={this.nextQuestion}
                  prevQuestion={this.prevQuestion}
                  locked={this.questionIsLocked(question.tag)}
                />
              ))}
            </Card>
          </Grid>
          <Grid item xs={7} className="flex-col-scroll-right">
            <Card my={3} ml={2} elevation={4}>
              <CardContent>
                <Grid
                  container
                  spacing={8}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="baseline"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Typography
                      variant="h3"
                      gutterBottom
                      py={2}
                      className="tour-preview"
                    >
                      Preview
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    wrap="nowrap"
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Typography
                        p={1}
                        variant="body2"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: "#ccf",
                        }}
                      >
                        Key Messages
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        p={1}
                        variant="body2"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: "#fc9",
                        }}
                      >
                        Your Answers
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <DocumentAttributesDialog
                  mode="preface"
                  document={listenedDocument}
                  documentId={documentId}
                />
                <Preview
                  dangerouslySetInnerHTML={{
                    __html: this.transformText(),
                  }}
                ></Preview>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workspaces = state.firestore.data.workspaces;
  const workspaceId = ownProps.match.params.workspaceId;
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    listenedDocument: state.firestore.data.listenedDocument,
    workspace: workspaces ? workspaces[workspaceId] : {},
  };
}

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "workspaces",
      doc: props.match.params.workspaceId,
    },
    {
      collection: `workspaces/${props.match.params.workspaceId}/documents`,
      doc: props.match.params.documentId,
      storeAs: "listenedDocument",
    },
  ]),
  connect(mapStateToProps)
)(CreateDoc);
