import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import { Autocomplete } from "@material-ui/lab";
import { compose } from "recompose";

import {
  TextField,
  IconButton as MuiIconButton,
  Typography,
  Box,
  Button as MuiButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { PersonAdd, Send, ExpandMore } from "@material-ui/icons";

import addNotification from "../util/addNotification";
import trackEvent from "../util/trackEvent";
import autoId from "../util/autoId";
import isValidEmail from "../util/isValidEmail";
import Loader from "./Loader";

const USER_ROLES = ["Editor", "Suggester", "Commenter", "Viewer"];

const IconButton = styled(MuiIconButton)(spacing);
const Button = styled(MuiButton)(spacing);

class InviteReviewerDialog extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      error: null,
      addedUser: null,
      addedUserInput: "",
      addedUserRole: USER_ROLES[0],
      invitationState: null,
      coveringText: "",
      addedUserIsApprover: false,
      anchorEl: null,
      processingSend: false
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      addedUser: null,
      addedUserInput: "",
      processingSend: false,
      error: null,
      invitationState: null,
      addedUserIsApprover: false,
      coveringText: ""
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAddedUserMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAddedUserMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAddedUserMenuItemClick = (role) => {
    this.setState({ addedUserRole: role });
    this.handleAddedUserMenuClose();
  };

  handleInputChange = (event, value) => {
    this.setState({ addedUserInput: value });
    // if (isValidEmail(value)) { // we don't check here because we don't know how to clear
    // out the matchingUser state => prop between dialog opens, and this gives an empty result
    // at the right moment
    this.props.firestore.get({
      collection: "users",
      where: ["email", "==", value],
      storeAs: "matchingUser"
    });
    // }
  };

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleAddedUserIsApproverCheckboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  matchingUser = () => {
    const { matchingUser } = this.props;
    return matchingUser && matchingUser.length > 0 && matchingUser[0];
  };

  matchingUserEmail = () => {
    // a bit verbose but hard to simplify just now
    const { matchingUser } = this.props;
    return matchingUser && matchingUser.length > 0 && matchingUser[0].email;
  };

  matchedUserAlreadyIncluded = () => {
    return (this.props.parentUsers || [])
      .map((user) => user.email)
      .includes(this.matchingUserEmail());
  };

  addUserButtonShown = () => {
    return (
      isValidEmail(this.state.addedUserInput) &&
      this.matchingUserEmail() &&
      !this.matchingUser().isGuest &&
      !this.matchedUserAlreadyIncluded()
    );
  };

  inviteUserButtonEnabled = () => {
    return (
      isValidEmail(this.state.addedUserInput) &&
      !this.matchedUserAlreadyIncluded()
    );
  };

  addUserHelperText = () => {
    if (this.state.invitationState) return this.state.invitationState;
    if (this.matchedUserAlreadyIncluded())
      return "This email is already in the list of users";
    if (this.addUserButtonShown()) return "Invite this existing PingGo user";
    if (this.inviteUserButtonEnabled())
      return "Send a guest invitation to this email address";
    return "Enter a valid email address";
  };

  handleAutocompleteOrEnter = (event, value) => {
    event.preventDefault(); // otherwise 'enter' re-opens the dialog afterwards
    if (this.inviteUserButtonEnabled() && !this.addUserButtonShown()) {
      this.handleInviteGuest();
      return;
    }
    if (!this.addUserButtonShown()) {
      // autocomplete has filled the field, so don't add yet
      return;
    }
    const newUser = this.matchingUser();
    if (typeof newUser === "string") return null; //no match, not an email, not a selection
    this.addUser(newUser);
  };

  handleInviteUser = () => {
    this.addUser(this.props.matchingUser[0]);
  };

  addUser = (newUser) => {
    const { name, userId, email, photo } = newUser;
    const newUsers = (this.props.parent.sharingUsers || []).concat({
      name,
      userId,
      email,
      photo
    });
    let newRoles = { ...this.props.parentUserRoles };
    newRoles[userId] = this.state.addedUserRole;
    const newApproverUserIds = [...this.props.parentApproverUserIds];
    if (this.state.addedUserIsApprover) newApproverUserIds.push(userId);
    console.log(newUsers, newRoles, newApproverUserIds);
    this.props.handleUpdate(newUsers, newRoles, newApproverUserIds);
    addNotification(
      this.props.firestore,
      this.props.matchingUser[0],
      this.props.profile,
      `added you to ${this.props.resourceName}${
        this.state.coveringText
          ? ", saying: '" + this.state.coveringText + "'"
          : "."
      }`,
      this.props.redirectUrl
    );
    trackEvent(this.props.firebase, "Added an existing user", {
      resource: this.props.resourceName
    });
    this.handleClose();
  };

  handleInviteGuest = () => {
    // we create an email tagged as 'invitation' which will be used to check the guest
    // user is entitled to access this resource each time the link is clicked, and create
    // a custom access token server-side on that basis
    this.setState({
      invitationState: "Sending invitation...",
      processingSend: true
    });
    // we've already checked if the user already has an account
    // create an email to send
    const { name, userId, email } = this.props.profile;
    const token = autoId();
    const fromEmail = this.props.profile.hasVerifiedEmail
      ? email
      : "invitations@ping-go.com";
    const inviteIntent = this.props.inviteIntent;
    // Postmark only allows 80 characters for metadata fields
    const trimmedInviteIntent =
      inviteIntent.length > 38
        ? inviteIntent.substring(0, 37) + '..."'
        : inviteIntent;
    const message = {
      // PascalCase keys as being passed as params to PostMark API
      Tag: "invitation",
      From: fromEmail,
      To: this.state.addedUserInput,
      TemplateAlias: "guest-reviewer-invitation",
      TemplateModel: {
        name,
        inviteIntent,
        coveringText: this.state.coveringText,
        actionURL: `${window.location.href}?token=${token}`
      },
      TrackOpens: true,
      Metadata: {
        initiatingUserId: userId,
        resourcePath: this.props.resourcePath,
        inviteType: this.props.inviteType,
        inviteIntent: `had invited you to ${trimmedInviteIntent}, and you accepted.`,
        role: this.state.addedUserRole,
        isApprover: this.state.addedUserIsApprover,
        token
      }
    };
    fetch(
      "https://europe-west1-pinggo-v2.cloudfunctions.net/api/mail-send-with-template",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(message)
      }
    )
      .then((response) => {
        if (response.status !== 200) throw new Error("Postmark mailer error");
        trackEvent(this.props.firebase, "Invited a new user", {
          intent: this.props.inviteIntent
        });
        this.handleClose();
      })
      .catch((error) => {
        this.setState({
          error: "Sorry, an invitation error occurred",
          invitationState: null,
          processingSend: false
        });
        console.log(error);
      });
  };

  render() {
    const {
      addedUser,
      addedUserInput,
      addedUserRole,
      anchorEl,
      processingSend
    } = this.state;
    const { myTeam, parentUsers } = this.props;

    const parentUserIds = parentUsers.map((user) => user.userId);
    const autocompleteUsers =
      myTeam && myTeam.sharingUsers
        ? myTeam.sharingUsers.filter(
            (user) => !parentUserIds.includes(user.userId)
          )
        : [];

    return (
      <>
        <Tooltip title="Invite someone else" arrow>
          <IconButton
            onClick={this.handleOpen}
            color="primary"
            ml={1}
            className="tour-manageUser">
            <PersonAdd />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle>Invite someone else</DialogTitle>
          <DialogContent>
            <>
              <div style={{ width: "100%" }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Autocomplete
                      fullwidth="true"
                      name="addedUser"
                      size="small"
                      value={addedUser}
                      onChange={this.handleAutocompleteOrEnter}
                      inputValue={addedUserInput}
                      onInputChange={this.handleInputChange}
                      options={autocompleteUsers}
                      getOptionLabel={(user) =>
                        typeof user === "string" ? user : user.email
                      }
                      getOptionSelected={(option, value) =>
                        option.email === value.email
                      }
                      freeSolo
                      disableClearable
                      forcePopupIcon={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add or invite someone"
                          placeholder="Email"
                          variant="outlined"
                          autoFocus
                          helperText={this.addUserHelperText()}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Button
                      ml={2}
                      mt={1}
                      variant="text"
                      size="small"
                      onClick={this.handleAddedUserMenuClick}
                      endIcon={<ExpandMore />}>
                      {addedUserRole}
                    </Button>
                    <Menu
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={this.handleAddedUserMenuClose}>
                      {USER_ROLES.map((role) => (
                        <MenuItem
                          key={role}
                          selected={addedUserRole === role}
                          onClick={(event) => {
                            this.handleAddedUserMenuItemClick(role);
                          }}>
                          {role}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Box>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(this.state.addedUserIsApprover)}
                    onChange={this.handleAddedUserIsApproverCheckboxChange}
                    name="addedUserIsApprover"
                    color="primary"
                  />
                }
                label={"Require them to " + this.props.inviteType}
              />
              <TextField
                id="coveringText"
                name="coveringText"
                type="string"
                multiline
                minRows={5}
                required
                fullWidth
                label="Add a message"
                value={this.state.coveringText}
                onChange={this.handleTextChange}
              />
            </>
          </DialogContent>

          <DialogActions>
            {this.state.error && (
              <Typography variant="body2" color="error">
                Error: {this.state.error}
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            {this.addUserButtonShown() ? (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleInviteUser}
                endIcon={<PersonAdd />}>
                Invite
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleInviteGuest}
                disabled={!this.inviteUserButtonEnabled() || processingSend}
                endIcon={processingSend ? <Loader size={20} /> : <Send />}>
                Send guest invitation
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  profile: state.firebase.profile,
  matchingUser: state.firestore.ordered.matchingUser,
  myTeam: state.firestore.data.myTeam //[`teams/${props.teamId}`],
});

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "teams",
      doc: props.teamId,
      storeAs: "myTeam"
    }
  ]),
  connect(mapStateToProps)
)(InviteReviewerDialog);
