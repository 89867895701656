import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import autoId from "../util/autoId";

const Button = styled(MuiButton)(spacing);

class CreateTeamDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      open: false,
      error: null
    };
  }

  handleBackToPersona = () => {
    // resets the persona field so 'Decide Persona' is displayed again
    this.props.firebase
      .updateProfile({ persona: "undecided" })
      .then((result) => {
        // this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSubmit = () => {
    const { name, email, photo, userId } = this.props.profile;
    const teamDetails = {
      name: this.state.name,
      ownerUser: {
        name,
        email,
        photo,
        userId
      },
      ownerUserId: userId,
      sharingUserIds: [],
      sharingUsers: [],
      keyAnswers: {}
    };
    const newId = autoId();
    this.props.firestore
      .set(`teams/${newId}`, teamDetails)
      .then(() => {
        // close the parent menu, or do nothing if it's called via the initial dialogs
        // this.handleClose();
        this.props.history.push(`/teams/${newId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>Create your new team</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom align="center">
            Your team will help you manage the members, clients and media
            contacts of your agency...
          </Typography>
          <TextField
            id="name"
            name="name"
            label="Team name"
            type="text"
            value={this.state.name}
            onChange={this.handleChange}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          {this.state.error && <Typography variant="body2">Error</Typography>}
          {this.props.handleClose ? (
            // from menu selection
            <Button
              variant="outlined"
              onClick={this.props.handleClose}
              color="primary">
              Cancel
            </Button>
          ) : (
            // from onboarding flow
            <Button
              variant="outlined"
              onClick={this.handleBackToPersona}
              color="primary">
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="primary"
            disabled={this.state.name === ""}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(CreateTeamDialog);
