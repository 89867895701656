import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase, withFirestore, isLoaded } from "react-redux-firebase";
import Helmet from "react-helmet";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import {
  Button as MuiButton,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@material-ui/core";

import AppIcon from "../../assets/firebase-pinggo-logo.png";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 20px 80px 12px;
  }
`;
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

export class ResetPassword extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      processing: false,
      errors: {}
    };
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleResetPassword = (event) => {
    this.setState({ processing: true });
    this.props.firebase
      .resetPassword(this.state.email)
      .then(() => {
        this.setState({
          processing: false,
          errors: {
            general: "Please check your email for a password reset link."
          }
        });
      })
      .catch((error) => {
        const errors = {};
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/user-not-found"
        )
          errors.email = "Enter a valid PingGo account email address";
        this.setState({ errors, processing: false });
        console.log(error);
      });
  };

  handleChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];

    this.setState({
      [event.target.name]: event.target.value,
      errors
    });
  };

  buttonDisabled = () => {
    const { email, errors, processing } = this.state;
    return !(
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      Object.keys(errors).length === 0 &&
      !processing
    );
  };

  render() {
    const { auth } = this.props;
    const { errors, processing } = this.state;

    if (!isLoaded(auth)) {
      return <Loader />;
    }
    return (
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        direction="column"
        spacing={6}>
        <Grid item>
          <Wrapper elevation={10}>
            <Container maxWidth="xs">
              <Helmet title="Reset my PingGo password" />
              <Grid container justifyContent="center">
                <img src={AppIcon} alt="foxy" width="250px" />
              </Grid>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                gutterBottom
                my={3}>
                Reset my PingGo password
              </Typography>
              <TextField
                mb={2}
                required
                id="email"
                name="email"
                type="email"
                label="Email"
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
              />

              {errors.general && (
                <Typography mb={2} variant="body2" color="primary">
                  {errors.general}
                </Typography>
              )}
              <Grid container justifyContent="center">
                <Button
                  my={4}
                  variant="contained"
                  fullWidth
                  onClick={this.handleResetPassword}
                  color="primary"
                  disabled={this.buttonDisabled()}>
                  {processing ? (
                    <CircularProgress size={30} />
                  ) : (
                    "Send password reset email"
                  )}
                </Button>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Already have an account?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    pb={1}
                    size="small"
                    component={Link}
                    to="/auth/login"
                    color="secondary">
                    Login here
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Need an account?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    pb={0.5}
                    size="small"
                    component={Link}
                    to="/auth/signup"
                    color="secondary">
                    Signup here
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Wrapper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

export default compose(
  withFirebase,
  withFirestore,
  connect(mapStateToProps)
)(ResetPassword);
