import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  useFirestoreConnect,
  withFirebase,
  isLoaded
} from "react-redux-firebase";
import Helmet from "react-helmet";
import {
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  Card,
  Button,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import Loader from "../../components/Loader";
import PRICING_PLANS from "../../util/pricingPlans";
import { spacing } from "@material-ui/system";
import WorkspacePlanDialog from "../../components/WorkspacePlanDialog";
import trackEvent from "../../util/trackEvent";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)`
  white-space: nowrap;
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;
const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

function MySubscription({ myWorkspaces, auth, profile, history, firebase }) {
  useFirestoreConnect([
    // we append the uid to the storeAs reference to make each unique, avoiding
    // a brief flash of previous user details if someone else logs in
    {
      collection: "workspaces",
      where: ["billingUserId", "==", auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "myWorkspaces" + auth.uid
    }
  ]);

  const [modifiedPlan, setModifiedPlan] = useState(null);

  if (!isLoaded(myWorkspaces, profile)) {
    return <Loader />;
  }

  const totalPrice = myWorkspaces.reduce(
    (sum, workspace) => sum + PRICING_PLANS[workspace.plan || 0].price,
    0
  );

  const handlePlanUpdate = (workspaceId, newPlan) => {
    setModifiedPlan({ workspaceId, newPlan });
  };

  const handlePortalClick = () => {
    if (!profile.stripeCustomerId) return;
    trackEvent(firebase, "Navigated to the Stripe billing portal");
    const sessionParams = {
      customer: profile.stripeCustomerId,
      return_url: window.location.href
    };
    fetch(
      "https://europe-west1-pinggo-v2.cloudfunctions.net/api/stripe-create-billing-session",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sessionParams)
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => (window.location.href = data.url))
      .catch((error) => console.log(error.message));
  };

  return (
    <>
      <Helmet title="Billing" />

      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h2" gutterBottom display="inline">
            My subscription
          </Typography>
        </Grid>
        <Grid item>
          {profile.stripeCustomerId && (
            <Tooltip title={"Manage Payments and Invoices"} arrow>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePortalClick}>
                Visit billing portal
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Typography variant="h3" gutterBottom>
        My workspaces and plans
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={2}>
        <Grid item xs={8}>
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Workspace</TableCell>
                    <TableCell align="center">Current plan</TableCell>
                    <TableCell align="center">Monthly price</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myWorkspaces.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography variant="body2">
                          {row.name}
                          {row.teamName ? ` (${row.teamName})` : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {modifiedPlan &&
                        modifiedPlan.workspaceId === row.id &&
                        modifiedPlan.newPlan !== row.plan ? (
                          <CircularProgress size={15} />
                        ) : (
                          <Typography variant="body2">
                            {PRICING_PLANS[row.plan || 0].name}
                            {PRICING_PLANS[ // highlight billing discrepancies
                              (profile.stripeSubscriptionPlans &&
                                profile.stripeSubscriptionPlans[row.id]) ||
                                0
                            ] !== PRICING_PLANS[row.plan || 0] &&
                              `(billed as ${
                                PRICING_PLANS[
                                  (profile.stripeSubscriptionPlans &&
                                    profile.stripeSubscriptionPlans[row.id]) ||
                                    0
                                ].name
                              }`}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          <NumberFormat
                            displayType="text"
                            value={PRICING_PLANS[row.plan || 0].price}
                            prefix="£"
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <WorkspacePlanDialog
                          mode="button"
                          workspace={row}
                          workspaceId={row.id}
                          handleUpdate={handlePlanUpdate}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <Typography variant="h4" gutterBottom>
              Total monthly{" "}
              <NumberFormat displayType="text" value={totalPrice} prefix="£" />
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myWorkspaces:
    state.firestore.ordered[`myWorkspaces${state.firebase.auth.uid}`]
});

export default compose(withFirebase, connect(mapStateToProps))(MySubscription);
