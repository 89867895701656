import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem,
  CardActions,
  Grid,
  Card,
  CardHeader as MuiCardHeader,
  CardContent
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

import PRICING_PLANS from "../util/pricingPlans";
import trackEvent from "../util/trackEvent";

const Button = styled(MuiButton)(spacing);
const CardHeader = styled(MuiCardHeader)(spacing);
const DialogActions = styled(MuiDialogActions)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
`;
const Header = styled.div`
  padding-bottom: 12px;
`;
const CloseIconButton = styled(MuiIconButton)`
  padding: 6px 0 0;
  float: right;
  margin-top: -10px;
`;
const Alert = styled(MuiAlert)(spacing);

class WorkspacePlanDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      plan: props.workspace.plan || 0,
      open: false
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
    trackEvent(this.props.firebase, "Opened the workspace plan dialog");
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.parentPopup) this.props.parentPopup.handleClose();
  };

  handleBetaChange = () => {
    const oldPlan = PRICING_PLANS[this.props.workspace.plan || 0].name;
    this.props.firestore
      .update(`workspaces/${this.props.workspaceId}`, {
        plan: this.state.plan
      })
      .then((result) => {
        // console.log(result);
        trackEvent(
          this.props.firebase,
          "[Beta] Changed workspace plan from the workspace plan dialog",
          {
            from: oldPlan,
            to: PRICING_PLANS[this.state.plan].name
          }
        );
        if (this.props.handleUpdate)
          this.props.handleUpdate(this.props.workspaceId, this.state.plan);
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCheckout = () => {
    // TODO return if no plan change has been made? also return if not the billing owner
    loadStripe("pk_test_WV2HU31OuDmassp530GpJFH0").then((stripe) => {
      const sessionParams = {
        priceId: PRICING_PLANS[this.state.plan].test_price_id,
        success_url: window.location.href,
        cancel_url: window.location.href,
        client_reference_id: this.props.profile.userId,
        customer_email: this.props.profile.email,
        metadata: {
          workspaceId: this.props.workspaceId,
          workspaceName: this.props.workspace.name,
          newPlan: this.state.plan
        }
      };
      fetch(
        "https://europe-west1-pinggo-v2.cloudfunctions.net/api/stripe-create-checkout-session",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(sessionParams)
        }
      )
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          trackEvent(
            this.props.firebase,
            "Initiated checkout from the workspace plan dialog",
            {
              from: PRICING_PLANS[this.props.workspace.plan || 0].name,
              to: PRICING_PLANS[this.state.plan].name
            }
          );
          return stripe.redirectToCheckout({ sessionId: data.sessionId });
        })
        .then((result) => console.log(result.error.message));
    });
  };

  handleAmend = () => {
    // TODO return if not the billing owner
    loadStripe("pk_test_WV2HU31OuDmassp530GpJFH0").then((stripe) => {
      const currentPlans = this.props.profile.stripeSubscriptionPlans;
      currentPlans[this.props.workspaceId] = this.state.plan;
      let newPlanItems = {};
      Object.values(currentPlans).forEach((key) => {
        newPlanItems[key] = newPlanItems[key] ? newPlanItems[key] + 1 : 1;
      });
      let newSubscriptionItems = [];
      Object.entries(PRICING_PLANS).forEach(([key, plan]) => {
        const price = PRICING_PLANS[key].test_price_id;
        newSubscriptionItems.push({
          price,
          quantity: newPlanItems[key] || 0
        });
      });
      const subscriptionParams = {
        subscriptionId: this.props.profile.stripeSubscriptionId,
        subscriptionItems: newSubscriptionItems
      };
      fetch(
        "https://europe-west1-pinggo-v2.cloudfunctions.net/api/stripe-amend-subscription",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(subscriptionParams)
        }
      )
        .then((data) => {
          return data.json();
        })
        .then(() => {
          // console.log(result);
          trackEvent(this.props.firebase, "Amended a workspace plan", {
            from: PRICING_PLANS[this.props.workspace.plan || 0].name,
            to: PRICING_PLANS[this.state.plan].name
          });
          return this.props.firestore.update(
            `users/${this.props.profile.userId}`,
            { stripeSubscriptionPlans: currentPlans }
          );
        })
        .then(() => {
          return this.props.firestore.update(
            `workspaces/${this.props.workspaceId}`,
            { plan: this.state.plan }
          );
        })
        .catch((error) => console.log(error));
    });
    if (this.props.handleUpdate)
      this.props.handleUpdate(this.props.workspaceId, this.state.plan);
    this.handleClose();
  };

  handleSelect = (key) => {
    this.setState({
      plan: Number(key)
    });
  };

  render() {
    // const existingSubscription = this.props.profile.stripeSubscriptionId;
    return (
      <>
        {this.props.mode === "menu" ? (
          <MenuItem variant="text" onClick={this.handleOpen}>
            Change Plan
          </MenuItem>
        ) : (
          <Button size="small" color="inherit" onClick={this.handleOpen}>
            Change
          </Button>
        )}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          // fullScreen
          maxWidth="lg">
          <DialogTitle>
            {this.props.workspace.name} - Workspace plans
            <CloseIconButton className="close" onClick={this.handleClose}>
              <CloseIcon />
            </CloseIconButton>
          </DialogTitle>
          <DialogContent>
            <Header>
              <Typography variant="h4" gutterBottom align="center">
                Choose a workspace plan
              </Typography>
              <Alert severity="info" mx={10} my={5} p={3}>
                {!this.props.profile.grandfathered ? (
                  <>
                    <Typography variant="body1">
                      Hey PingGo Beta User! When our extended Public Beta period
                      ends, any upgrades you've made here will form part of your
                      14-day free trial, and then be chargeable.
                    </Typography>
                    <Typography variant="body1">
                      We'll be sure and let you know in plenty of time what
                      future plan pricing will be.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" gutterBottom>
                      Hey PingGo Beta User and Sumoling! When you subscribed to
                      the previous PingGo deal on AppSumo we promised you'd
                      benefit from future enhancements to PingGo.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      So when our beta test period ends, you'll receive a
                      permanent credit for a 'Pro' workspace at no additional
                      charge. Feel free to upgrade your first workspace whenever
                      you're ready.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Any other workspaces you upgrade will form part of a
                      14-day free trial, and then be chargeable.
                    </Typography>
                    <Typography variant="body1">
                      We'll be sure and let you know in plenty of time what
                      future plan pricing will be.
                    </Typography>
                  </>
                )}
              </Alert>
            </Header>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={10}>
                <Grid container spacing={5} alignItems="flex-end">
                  {Object.entries(PRICING_PLANS).map(([key, plan]) => (
                    <Grid item xs={12} md={3} key={key}>
                      <Card p={5} elevation={8}>
                        <CardHeader
                          title={plan.name}
                          subheader={
                            Number(key) === (this.props.workspace.plan || 0)
                              ? "Current Plan"
                              : ""
                          }
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          pt={2}
                          pb={1}
                        />
                        <CardContent>
                          <Price>
                            <Typography
                              variant="h4"
                              color="textPrimary"
                              align="center"
                              display="inline">
                              £tbc {/*plan.price*/}
                            </Typography>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              align="center"
                              display="inline">
                              /mo
                            </Typography>
                          </Price>
                          <Typography align="center">
                            Unlimited Press Releases
                          </Typography>
                          {plan.max_additional_members > 0 && (
                            <Typography align="center">
                              {plan.max_additional_members === 99
                                ? "Unlimited"
                                : `${plan.max_additional_members} x`}{" "}
                              additional user
                              {plan.max_additional_members > 1 ? "s" : ""}
                            </Typography>
                          )}
                          {plan.max_approvers > 0 && (
                            <Typography align="center">
                              {plan.max_approvers === 99
                                ? "Unlimited"
                                : `${plan.max_approvers} x`}{" "}
                              approver{plan.max_approvers > 1 ? "s" : ""}
                            </Typography>
                          )}
                          {plan.can_use_custom_templates && (
                            <Typography align="center">
                              Use custom templates
                            </Typography>
                          )}
                          {plan.can_manage_due_dates && (
                            <Typography align="center">
                              Track due dates
                            </Typography>
                          )}
                          {plan.can_track_emails && (
                            <Typography align="center">
                              Track email delivery and opening
                            </Typography>
                          )}
                          {plan.send_from_verified_owner_email && (
                            <Typography align="center">
                              Send emails from your own verified address
                            </Typography>
                          )}
                          {/* {plan.send_from_verified_domain && (
                            <Typography align="center">
                              Send emails from your own verified domain
                            </Typography>
                          )} */}
                          {plan.collaborative_comments && (
                            <Typography align="center">
                              Collaborate with real-time comments
                            </Typography>
                          )}
                          {plan.collaborative_editing && (
                            <Typography align="center">
                              Real-time collaborative editing
                            </Typography>
                          )}
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              Number(key) === this.state.plan || 0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            onClick={() => this.handleSelect(key)}>
                            Select
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            {/* <Button
              variant="contained"
              disabled={this.state.plan === (this.props.workspace.plan || 0)}
              onClick={
                existingSubscription ? this.handleAmend : this.handleCheckout
              }
              color="primary">
              {existingSubscription
                ? "Amend subscription"
                : "Create subscription"}
            </Button> */}
            <Button
              variant="contained"
              disabled={this.state.plan === (this.props.workspace.plan || 0)}
              onClick={this.handleBetaChange}
              color="primary">
              Change Plan
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(WorkspacePlanDialog);
