import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { compose } from "recompose";
import {
  MenuItem,
  Menu,
  IconButton as MuiIconButton,
  Tooltip
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import QuestionDialog from "./QuestionDialog";

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

class ManageQuestionMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
    this.questionDialog = React.createRef();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.props.firestore
      .delete(`teams/${this.props.teamId}/questions/${this.props.questionId}`)
      .then(() => {
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDuplicate = () => {
    this.child.handleOpen(this.props.question);
    this.handleClose();
  };

  handleQuestionDialogOpen = () => {
    this.child.handleOpen();
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-question-popup" : undefined;
    const { question, questionId, teamId } = this.props;

    return (
      <>
        <QuestionDialog
          childRef={(ref) => (this.child = ref)}
          question={question}
          teamId={teamId}
          questionId={questionId}
          mode="Edit"
        />
        <div>
          <Tooltip title="Question actions" arrow>
            <IconButton onClick={this.handleClick}>
              <MoreVert />
            </IconButton>
          </Tooltip>
          <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}>
            <MenuItem variant="text" onClick={this.handleQuestionDialogOpen}>
              Edit
            </MenuItem>
            <MenuItem divider variant="text" onClick={this.handleDuplicate}>
              Duplicate
            </MenuItem>
            <MenuItem
              variant="text"
              onClick={this.handleDelete}
              style={{ color: "red" }}>
              Delete
            </MenuItem>
          </Menu>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  connect(mapStateToProps)
)(ManageQuestionMenu);
