import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import {
  withFirestore,
  firestoreConnect,
  isLoaded
} from "react-redux-firebase";
import { formatDistanceToNow } from "date-fns";
import WorkspaceDialog from "../../components/WorkspaceDialog";
import WorkspaceCard from "../../components/WorkspaceCard";
import CreateTemplateDialog from "../../components/CreateTemplateDialog";
import canAccessPage from "../../util/canAccessPage";
import trackEvent from "../../util/trackEvent";
import BlankWorkspacesImage from "../../assets/blank-workspaces.png";
import BlankHomeImage from "../../assets/blank-home.png";

import {
  Container as MuiContainer,
  FormControlLabel as MuiFormControlLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  Divider as MuiDivider,
  Paper,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tabs,
  Tab,
  Box,
  Switch
} from "@material-ui/core";

import { VpnKey } from "@material-ui/icons";
import Loader from "../../components/Loader";
import { AvatarGroup as MuiAvatarGroup } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import addNotification from "../../util/addNotification";
import QuestionDialog from "../../components/QuestionDialog";
import KeyMessageTable from "../../components/KeyMessageTable";
import KeyMessageDialog from "../../components/KeyMessageDialog";
import ManageQuestionMenu from "../../components/ManageQuestionMenu";
import ManageTemplateMenu from "../../components/ManageTemplateMenu";
import ManageMemberMenu from "../../components/ManageMemberMenu";
import ManageInvitationMenu from "../../components/ManageInvitationMenu";
import InviteMemberDialog from "../../components/InviteMemberDialog";

import CONTENT_TYPES from "../../util/contentTypes";
import ContactDialog from "../../components/ContactDialog";
import UploadContactsDialog from "../../components/UploadContactsDialog";
import ContactTable from "../../components/ContactTable";

const Grid = styled(MuiGrid)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;
const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;
const KeyIcon = styled(VpnKey)`
  padding-top: 5px;
`;
const Container = styled(MuiContainer)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;
const FormControlLabel = styled(MuiFormControlLabel)`
  float: right;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabLoading: false,
      keyQuestionMap: null,
      contactSortKey: "publication",
      contactSortOrder: "ascending",
      showArchived: false
    };
  }

  componentDidMount() {
    // we need to build this keyQuestionMap in this order
    let keyQuestionMap = {};
    this.props.firestore
      .collection(`teams/R7Z1iCDHpvS73plmK1iP/questions`)
      .where("isKey", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((question) => {
          keyQuestionMap[question.data().tag] = question.data();
        });
      })
      .then(() => {
        return this.props.firestore
          .collection(`teams/${this.props.match.params.teamId}/questions`)
          .where("isKey", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.forEach((question) => {
              keyQuestionMap[question.data().tag] = question.data();
            });
          })
          .then(() => {
            this.setState({ keyQuestionMap });
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  updateSharingUsers = (newUsers) => {
    const teamId = this.props.match.params.teamId;
    this.props.firestore
      .update(
        {
          collection: "teams",
          doc: teamId
        },
        {
          sharingUsers: newUsers,
          sharingUserIds: newUsers.map((user) => user.userId)
        }
      )
      .then(() => {})
      .catch((error) => console.log(error));
  };

  handleRemoveUser = (removeUser) => {
    const newUsers = this.props.team.sharingUsers.filter(
      (user) => user.userId !== removeUser.userId
    );
    this.updateSharingUsers(newUsers);
    addNotification(
      this.props.firestore,
      removeUser,
      this.props.profile,
      `removed you as a member of ${this.props.team.name}.`,
      null
    );
    trackEvent(this.props.firebase, "Removed a user", {
      resource: this.props.team.name
    });
  };

  allUsers = () => {
    const team = this.props.team;
    // we store members in the sharingUsers field
    return [team.ownerUser, ...team.sharingUsers];
  };

  handleTabChange = (event, value) => {
    // this.setState({ tabLoading: true });
    this.props.history.push(
      `/teams/${this.props.match.params.teamId}/${value}`
    );
    // this.setState({ tab: value });
  };

  // handleSectorMigrate = () => {
  //   // temp to migrate workspaces to add sector
  //   // keep this to reuse for sector name migration in future
  //   this.props.firestore
  //     .collection(`workspaces`)
  //     .get()
  //     .then((snapshot) => {
  //       snapshot.forEach((workspace) => {
  //         if (!workspace.data().sector) {
  //           console.log("Updating " + workspace.id);
  //           this.props.firestore.update(`workspaces/${workspace.id}`, {
  //             sector: "Technology (B2B)"
  //           });
  //         }
  //       });
  //     });
  // };

  // handleWorkspaceBillingMigrate = () => {
  //   const workspaces = [];
  //   const teams = {};
  //   this.props.firestore
  //     .collection(`workspaces`)
  //     .get()
  //     .then((snapshot) => {
  //       snapshot.forEach((doc) => {
  //         const ws = doc.data();
  //         ws.id = doc.id;
  //         workspaces.push(ws);
  //       });
  //       this.props.firestore
  //         .collection(`teams`)
  //         .get()
  //         .then((snapshot) => {
  //           snapshot.forEach((doc) => {
  //             teams[doc.id] = doc.data();
  //           });
  //           let billingUserId;
  //           workspaces.forEach((workspace) => {
  //             if (teams[workspace.teamId]) {
  //               billingUserId = teams[workspace.teamId].ownerUserId;
  //               console.log(
  //                 "ws " +
  //                   workspace.name +
  //                   " team " +
  //                   teams[workspace.teamId].name +
  //                   " buid " +
  //                   teams[workspace.teamId].ownerUserId
  //               );
  //             } else {
  //               billingUserId = workspace.ownerUserId;
  //               console.log(
  //                 "ws " + workspace.name + " no team, buid=" + billingUserId
  //               );
  //             }
  //             this.props.firestore.update(`workspaces/${workspace.id}`, {
  //               billingUserId
  //             });
  //           });
  //         });
  //     });
  // };

  // handleDocPopulate = () => {
  //   this.props.firestore
  //     .collectionGroup("documents")
  //     .get()
  //     .then((snapshot) => {
  //       console.log(snapshot.docs.length);
  //       snapshot.forEach((doc) => {
  //         const document = doc.data();
  //         if (!document.contentType) {
  //           console.log("found one");
  //           console.log(document.title);
  //           doc.ref
  //             .update({ contentType: Object.keys(CONTENT_TYPES)[0] })
  //             .then((result) => {
  //               console.log(result);
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // handleTemplateMigrateToQuickAccessTitle = () => {
  //   this.props.firestore
  //     .collectionGroup("templates")
  //     .get()
  //     .then((snapshot) => {
  //       console.log(snapshot.docs.length);
  //       snapshot.forEach((doc) => {
  //         const template = doc.data();
  //         if (template.isDefaultTemplate) {
  //           console.log("found one");
  //           console.log(template.title);
  //           doc.ref
  //             .update({
  //               quickAccessTitle: "Key Messages",
  //               isDefaultTemplate: false
  //             })
  //             .then((result) => {
  //               console.log(result);
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  updateKeyAnswers = (newAnswer) => {
    const teamId = this.props.match.params.teamId;
    const newAnswers = Object.assign({}, this.props.team.keyAnswers, newAnswer);
    this.props.firestore
      .update(
        {
          collection: "teams",
          doc: teamId
        },
        {
          keyAnswers: newAnswers
        }
      )
      .then(() => {
        trackEvent(this.props.firebase, "Updated a Key Answer");
        return;
      })
      .catch((error) => console.log(error));
  };

  sortContactsBy = (sortKey) => {
    if (sortKey === this.state.contactSortKey) {
      this.setState({
        contactSortOrder:
          this.state.contactSortOrder === "ascending"
            ? "descending"
            : "ascending"
      });
    } else {
      this.setState({ contactSortKey: sortKey });
    }
  };

  render() {
    const {
      team,
      contacts,
      templates,
      questions,
      profile,
      auth,
      sentInvitations,
      myTeamWorkspaces,
      sharedTeamWorkspaces
    } = this.props;
    const { keyQuestionMap, showArchived } = this.state;

    if (
      !isLoaded(
        team,
        templates,
        questions,
        contacts,
        myTeamWorkspaces,
        sharedTeamWorkspaces
      ) ||
      !team ||
      !keyQuestionMap
    ) {
      return <Loader />;
    }

    if (!canAccessPage(auth, team)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: {
              from: { pathname: this.props.history.location.pathname }
            }
          }}
        />
      );
    }

    const isTeamPingGo =
      this.props.match.params.teamId === "R7Z1iCDHpvS73plmK1iP";
    const defaultTab = isTeamPingGo ? 2 : 0;
    const tab = parseInt(this.props.match.params.tab) || defaultTab;
    const teamWorkspaces = { ...myTeamWorkspaces, ...sharedTeamWorkspaces };
    const teamWorkspacesKeys =
      // teamWorkspaces
      // ?
      Object.keys(teamWorkspaces).sort((workspaceIdA, workspaceIdB) =>
        teamWorkspaces[workspaceIdA].name.localeCompare(
          teamWorkspaces[workspaceIdB].name
        )
      );
    // : [];
    const unarchivedTeamWorkspacesKeys = teamWorkspacesKeys.filter(
      (workspaceId) => !teamWorkspaces[workspaceId].isArchived
    );
    const showArchivedSwitch =
      unarchivedTeamWorkspacesKeys.length !== teamWorkspacesKeys.length;
    const visibleTeamWorkspaces = showArchived
      ? teamWorkspacesKeys
      : unarchivedTeamWorkspacesKeys;

    const keyAnswers = team.keyAnswers || {};
    const teamId = this.props.match.params.teamId;
    let sortedTemplates = [...templates].sort((a, b) => {
      const aSector = a.sector && a.sector !== "none" ? a.sector : "Zzz"; // No/undefined sector sorts at end of list
      const bSector = b.sector && b.sector !== "none" ? b.sector : "Zzz";
      return aSector === bSector
        ? a.title.localeCompare(b.title)
        : aSector.localeCompare(bSector);
    });
    // let sortedContacts = [...contacts];
    // const sortKey = this.state.contactSortKey;
    // sortedContacts.sort((a, b) =>
    //   this.state.contactSortOrder === "ascending"
    //     ? (a[sortKey] || "").localeCompare(b[sortKey] || "")
    //     : b[sortKey] || "".localeCompare(a[sortKey] || "")
    // );

    const allTagsInUse = contacts.reduce((acc, contact) => {
      if (contact.tags) {
        const tags = contact.tags.split(",");
        tags.forEach((tag) => {
          if (acc.indexOf(tag) === -1) acc.push(tag);
        });
      }
      return acc;
    }, []);

    const pendingInvitations = (sentInvitations || []).filter(
      (invitation) => !invitation.invitationHandled
    );

    return (
      <Fragment>
        <Helmet title={team.name} />
        <Grid
          container
          justifyContent="space-between"
          spacing={6}
          wrap="nowrap"
          style={{ whiteSpace: "nowrap" }}
          alignItems="center">
          <Grid item>
            <Typography variant="h2" display="inline">
              {team.name}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid item>
              <AvatarGroup max={12} spacing={10} className="tour-members">
                {this.allUsers().map((user) => (
                  <ManageMemberMenu
                    key={user.userId}
                    user={user}
                    profile={profile}
                    // isAnApprover={this.userIsAnApprover(user.userId)}
                    // hasApproved={this.userHasApproved(user.userId)}
                    // role={this.userRoles()[user.userId]}
                    canEdit={team.ownerUserId === profile.userId}
                    isOwner={team.ownerUserId === user.userId}
                    // handleToggleApprover={() =>
                    //   this.handleToggleApprover(user.userId)
                    // }
                    handleRemoveUser={() => this.handleRemoveUser(user)}
                    // handleMenuRoleUpdate={(role) =>
                    //   this.handleMenuRoleUpdate(user.userId, role)
                  />
                ))}
                {pendingInvitations.map((invitation) => (
                  <ManageInvitationMenu
                    key={invitation.id}
                    invitation={invitation}
                    canEdit={team.ownerUserId === profile.userId}
                  />
                ))}
              </AvatarGroup>
            </Grid>
            {team.ownerUserId === this.props.profile.userId && (
              <Grid item>
                <InviteMemberDialog
                  class="tour-manageUser"
                  resourceName={"the " + team.name + " team"}
                  parent={team}
                  // workspaceId={workspaceId}
                  // workspace={workspace} // assuming for plan changes?
                  // teamId={teamId}
                  parentUsers={this.allUsers()}
                  // parentAddedUserIds={team.sharingUserIds}
                  // parentUserRoles={this.userRoles()}
                  // parentApproverUserIds={
                  //   this.props.listenedDocument.approverUserIds
                  // }
                  resourcePath={`teams/${teamId}`}
                  redirectUrl={`/teams/${teamId}`}
                  inviteType={"team"}
                  inviteIntent={`join ${team.name}`}
                  handleUpdate={this.updateSharingUsers}
                  // maxSharingUsers={99}
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            {/* {profile.userId === "7pqCxl9YGTV2uWLXDIEushpDCuq1" && (
              <Button onClick={this.handleTemplateMigrateToQuickAccessTitle}>
                TQuat
              </Button>
            )} */}
            {tab === 0 && Object.keys(visibleTeamWorkspaces).length > 0 && (
              <WorkspaceDialog mode="Add" teamId={teamId} />
            )}
            {tab === 1 && contacts.length > 0 && (
              <>
                <UploadContactsDialog
                  existingContacts={contacts}
                  teamId={teamId}
                />
                <ContactDialog
                  teamId={teamId}
                  allTagsInUse={allTagsInUse}
                  mode="Add"
                />
              </>
            )}
            {tab === 2 && (
              <CreateTemplateDialog
                teamId={teamId}
                history={this.props.history}
              />
            )}
            {tab === 3 && <QuestionDialog teamId={teamId} mode="Add" />}
            {tab === 4 && (
              <KeyMessageDialog
                keyQuestionMap={keyQuestionMap}
                mode="Add"
                updateKeyAnswers={this.updateKeyAnswers}
              />
            )}
          </Grid>
        </Grid>
        <Divider my={4} />
        <Tabs
          value={tab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered>
          {!isTeamPingGo && (
            <Tab
              label="Workspaces"
              className="tour-team-workspaces"
              value={0}
            />
          )}
          {!isTeamPingGo && (
            <Tab
              label="Media Contacts"
              className="tour-media-contacts"
              value={1}
            />
          )}
          {isTeamPingGo && (
            <Tab
              label="Custom Templates"
              className="tour-custom-templates"
              value={2}
            />
          )}
          {isTeamPingGo && (
            <Tab
              label="Custom Questions"
              className="tour-custom-questions"
              value={3}
            />
          )}
          {false && (
            <Tab
              label="Master Key Messages"
              className="tour-master-key-messages"
              value={4}
            />
          )}
        </Tabs>
        <TabPanel value={tab} index={0}>
          <>
            {showArchivedSwitch && (
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showArchived}
                    onChange={this.handleChange}
                    name="showArchived"
                    color="primary"
                  />
                }
                label="Show archived"
              />
            )}
            {Object.keys(visibleTeamWorkspaces).length > 0 ? (
              <Grid container mt={4} spacing={4}>
                {visibleTeamWorkspaces.map((workspaceId) => (
                  <WorkspaceCard
                    key={workspaceId}
                    workspaceId={workspaceId}
                    workspace={teamWorkspaces[workspaceId]}
                  />
                ))}
              </Grid>
            ) : (
              <Container maxWidth="sm" mt={12}>
                <Typography variant="h2" align="center" gutterBottom>
                  Set up your first team workspace
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                  Create a workspace for every client, where you can write new
                  content, manage distributions and collaborate with your team.
                </Typography>
                <Container align="center" my={5}>
                  <WorkspaceDialog mode="Add" teamId={teamId} />
                </Container>
                <img
                  src={BlankWorkspacesImage}
                  style={{ marginLeft: "100px", marginTop: "40px" }}
                  height="160"
                  alt="Blank Workspaces"
                />
              </Container>
            )}
          </>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {contacts.length > 0 ? (
            <ContactTable
              contacts={contacts}
              teamId={teamId}
              allTagsInUse={allTagsInUse}
              selectMode={false}
            />
          ) : (
            <Container maxWidth="sm" mt={12}>
              <Typography variant="h2" align="center" gutterBottom>
                Add your media contacts
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Manage all your media contacts and personalise media
                distributions.
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Upload from csv. file or add media outlets and target
                journalists individually.{" "}
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Keep contacts up to date and record call notes from your
                distributions.{" "}
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Build tailored media lists and collaborate on distributions.
                Shared access for the whole team means you work as a team on a
                media distribution. This allows close collaboration on media
                distributions.
              </Typography>
              <Container align="center" my={5}>
                <>
                  <UploadContactsDialog
                    existingContacts={contacts}
                    teamId={teamId}
                  />
                  <ContactDialog
                    teamId={teamId}
                    allTagsInUse={allTagsInUse}
                    mode="Add"
                  />
                </>
              </Container>
              <img
                src={BlankHomeImage}
                style={{ marginLeft: "100px", marginTop: "48px" }}
                height="200"
                alt="Blank Home"
              />
            </Container>
          )}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="15%">Sector</TableCell>
                    <TableCell width="10%">Type</TableCell>
                    <TableCell width="20%">Title</TableCell>
                    <TableCell width="40%">Description</TableCell>
                    <TableCell width="10%">Last Updated</TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedTemplates.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography variant="body2">
                          {row.sector || "none"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {CONTENT_TYPES[row.contentType].name}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{row.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {row.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {formatDistanceToNow(row.modifiedOn)} ago
                      </TableCell>
                      <TableCell align="right">
                        <ManageTemplateMenu
                          template={row}
                          templateId={row.id}
                          teamId={teamId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Paper>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Paper>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tag</TableCell>
                    <TableCell>Question</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">
                          #{row.tag}
                          {row.isKey && (
                            <KeyIcon color="secondary" fontSize="small" />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{row.text}</Typography>
                      </TableCell>
                      <TableCell>
                        {formatDistanceToNow(row.modifiedOn)} ago
                      </TableCell>
                      <TableCell align="right">
                        <ManageQuestionMenu
                          question={row}
                          teamId={teamId}
                          questionId={row.id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Paper>
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <KeyMessageTable
            keyAnswers={keyAnswers}
            keyQuestionMap={keyQuestionMap}
            updateKeyAnswers={this.updateKeyAnswers}
          />
        </TabPanel>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const teamId = ownProps.match.params.teamId;
  const teams = state.firestore.data.teams;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    teams: state.firestore.data.teams,
    team: teams ? teams[teamId] : {},
    contacts: state.firestore.ordered[`teams/${teamId}/contacts`],
    templates: state.firestore.ordered[`teams/${teamId}/templates`],
    questions: state.firestore.ordered[`teams/${teamId}/questions`],
    sentInvitations: state.firestore.ordered["sentInvitations"],
    myTeamWorkspaces: state.firestore.data[`myTeamWorkspaces${teamId}`],
    sharedTeamWorkspaces: state.firestore.data[`sharedTeamWorkspaces${teamId}`]
  };
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "teams",
      doc: props.match.params.teamId
    },
    {
      collection: "workspaces",
      where: [
        ["teamId", "==", props.match.params.teamId],
        ["ownerUserId", "==", props.auth.uid]
      ],
      storeAs: "myTeamWorkspaces" + props.match.params.teamId
    },
    {
      collection: "workspaces",
      where: [
        ["teamId", "==", props.match.params.teamId],
        ["sharingUserIds", "array-contains", props.auth.uid]
      ],
      storeAs: "sharedTeamWorkspaces" + props.match.params.teamId
    },
    {
      collection: `teams/${props.match.params.teamId}/templates`,
      orderBy: ["title", "asc"]
    },

    {
      collection: `teams/${props.match.params.teamId}/questions`,
      orderBy: ["tag", "asc"]
    },
    {
      collection: `teams/${props.match.params.teamId}/contacts`,
      orderBy: ["lastName", "asc"]
    },
    {
      collection: "email",
      where: [
        ["Tag", "==", "invitation"],
        ["resourcePath", "==", `teams/${props.match.params.teamId}`]
      ],
      orderBy: ["sentOn", "desc"],
      storeAs: "sentInvitations"
    }
  ])
)(Team);
