import React, { Component } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
  Box,
  Button as MuiButton,
  Step,
  StepLabel,
  Stepper as MuiStepper,
  Typography
} from "@material-ui/core";
import { KeyboardBackspace as BackIcon } from "@material-ui/icons";
import PingoLogo from "../assets/logo-pinggo165x54.png";

const STEPS = [
  "Choose a template",
  "Answer questions",
  "Edit & review",
  "Get sign-off",
  "Send"
];

const Stepper = styled(MuiStepper)`
  padding: 12px 36px 18px;
`;
const Button = styled(MuiButton)(spacing);
const LogoWrapper = styled.div`
  padding: 0;
`;

export class DocumentHeader extends Component {
  render() {
    const {
      userId,
      workspace,
      workspaceId,
      handleBack,
      activeStep,
      isGuest
    } = this.props;
    if (isGuest)
      return (
        <LogoWrapper>
          <img src={PingoLogo} alt="PingoLogo" width="100" height="33" />
        </LogoWrapper>
      );

    let backPath = "/mypinggo";
    let text = "Back to My PingGo";
    if (
      workspace.ownerUserId === userId ||
      workspace.sharingUserIds.includes(userId)
    ) {
      backPath = `/workspaces/${workspaceId}`;
      text = `Back to ${workspace.name}`;
    }

    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box>
          <Button
            mb={2}
            onClick={() => handleBack(backPath)}
            startIcon={<BackIcon />}>
            {text}
          </Button>
        </Box>
        <Box flexGrow={1}>
          <Stepper activeStep={activeStep}>
            {STEPS.map((label, index) => (
              <Step key={index} className={`step-${index}`}>
                <StepLabel>
                  <Typography variant="body1">{label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    );
  }
}

export default DocumentHeader;
