import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Grid,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Typography as MuiTypography
} from "@material-ui/core";
import { Person, Business } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import PingGoLogo from "../assets/firebase-pinggo-logo.png";

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const DialogContent = styled(MuiDialogContent)`
  padding: 0 50px 0;
`;
const DialogActions = styled(MuiDialogActions)`
  padding: 24px;
  justify-content: center;
`;
const List = styled(MuiList)`
  display: flex;
  flex-direction: row;
`;
const ListItem = styled(MuiListItem)`
  margin: 4px;
  cursor: pointer;
  border: 1px solid LightGray;
  &:hover {
    background-color: #ebebeb;
  }
`;

const personaOptions = {
  business: {
    title: "For my business",
    description: "I'm using PingGo to help with PR for my own business",
    icon: <Person size="large" color="primary" />
  },
  agency: {
    title: "For my agency",
    description: "I'm using PingGo to help manage my PR agency.",
    icon: <Business size="large" color="primary" />
  }
};

class DecidePersonaDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true,
      persona: ""
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    if (this.state.persona === "") return;
    this.props.firebase
      .updateProfile({ persona: this.state.persona })
      .then((result) => {
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        // onClose={() => { }} prevent clicking away to close
        fullWidth={true}
        disableEscapeKeyDown
        maxWidth="md">
        <DialogContent>
          <Grid container justifyContent="center">
            <img src={PingGoLogo} alt="logo" width="250px" />
          </Grid>
          <Typography variant="h3" my={5} align="center">
            Welcome to PingGo!
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            First, let us know what describes you best...
          </Typography>
          <List>
            {Object.entries(personaOptions).map(([key, option]) => (
              <ListItem
                key={key}
                selected={this.state.persona === key}
                onClick={() => this.setState({ persona: key })}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText
                  primary={<Typography variant="h6">{option.title}</Typography>}
                  secondary={option.description}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="primary"
            disabled={this.state.persona === ""}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(DecidePersonaDialog);
