import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Grid,
  Typography as MuiTypography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import AppSumoIcon from "../assets/appsumo-logo.jpg";

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const DialogContent = styled(MuiDialogContent)`
  padding: 0 50px 0;
`;
const DialogActions = styled(MuiDialogActions)`
  padding: 24px;
  justify-content: center;
`;

class AppSumoFirstVisitDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOk = () => {
    this.props.firebase
      .updateProfile({ modal_hidden_appsumo: true })
      .then((result) => {
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        fullWidth={true}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm">
        <DialogContent>
          <Grid container justifyContent="center">
            <img src={AppSumoIcon} alt="sumo" width="300px" height="150px" />
          </Grid>
          <Typography variant="h3" my={5}>
            Welcome Sumoling!
          </Typography>
          <Typography variant="body1" gutterBottom>
            Glad you could make it!
          </Typography>
          <Typography variant="body1" gutterBottom>
            So, here's the deal. When you subscribed to the previous PingGo deal
            on AppSumo we promised you'd benefit from future enhancements to
            PingGo.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We're really pleased to be able to offer you our new, improved app.
            You can create unlimited PingGo workspaces in the app for free.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Do let us know what you think - give feedback or ask questions
            whenever you want via the chat widget down on the bottom right...
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.handleOk} color="primary">
            OK, got it!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(AppSumoFirstVisitDialog);
