import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { withFirebase, withFirestore, isLoaded } from "react-redux-firebase";
import Helmet from "react-helmet";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import GoogleButton from "react-google-button";
import addNotification from "../../util/addNotification";
import trackEvent from "../../util/trackEvent";
import AppIcon from "../../assets/firebase-pinggo-logo.png";
import Loader from "../../components/Loader";
import GRANDFATHERED_USERS from "../../util/grandfatheredUsers";
import {
  Button as MuiButton,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 20px 80px 12px;
  }
`;
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);

export class Signup extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      displayName: "",
      password: "",
      showPassword: false,
      termsConsent: false,
      marketingConsent: false,
      processing: false,
      errors: {},
      invitation: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.token) {
      // is an invitation
      this.props.firestore
        .collection("email")
        // .where("Tag", "==", "invitation")
        .where("token", "==", this.props.match.params.token)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.setState({
              errors: { general: "Oops! Your invitation is not valid" }
            });
          } else {
            const invitation = snapshot.docs[0].data();
            invitation.id = snapshot.docs[0].id;
            this.setState({
              invitation,
              email: invitation.To
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleSignup = () => {
    this.setState({ processing: true });
    const { email, password, displayName, marketingConsent, invitation } =
      this.state;
    if (!displayName) {
      this.setState({ errors: { displayName: "Please enter your name" } });
      return;
    }
    let addedUser = {};
    this.props.firebase
      .createUser(
        {
          email,
          password
        },
        { displayName }
      )
      .then((result) => {
        addedUser = result;
        return this.props.firestore.update(`users/${result.userId}`, {
          marketingConsent,
          termsConsentedOn: new Date().getTime(),
          grandfathered: GRANDFATHERED_USERS.includes(email),
          persona: "undecided" // first use will ask for 'business' or 'agency'
        });
      })
      .then((result) => {
        trackEvent(
          this.props.firebase,
          "Signed up",
          {
            method: "email"
          },
          "sign_up"
        );
        if (invitation) this.handleInvitation(invitation, addedUser);
      })
      .catch((error) => {
        const errors = {};
        switch (error.code) {
          case "auth/invalid-email":
            errors.email = "Enter a valid email address";
            break;
          case "auth/weak-password":
            errors.password = "Password should be at least 6 characters";
            break;
          case "auth/email-already-in-use":
            errors.email = "Email is already in use by another account";
            break;
          default:
            errors.general = `Sorry, an error occurred (${error.code})`;
        }
        this.setState({ errors, processing: false });
      });
  };

  loginWithGoogle = () => {
    // can also login from here
    const { invitation } = this.state;
    let isNewUser = true;
    let addedUser = {};
    this.props.firebase
      .login({ provider: "google", type: "popup" })
      .then((result) => {
        addedUser = result.profile;
        isNewUser = result.additionalUserInfo.isNewUser;
        return this.props.firestore.update(`users/${result.profile.userId}`, {
          persona: isNewUser ? "undecided" : null, // first use will ask for 'business' or 'agency'
          grandfathered: GRANDFATHERED_USERS.includes(result.profile.email)
        });
      })
      .then(() => {
        trackEvent(
          this.props.firebase,
          isNewUser ? "Signed up" : "Logged in",
          {
            method: "google"
          },
          isNewUser ? "sign_up" : "login"
        );
        if (invitation) this.handleInvitation(invitation, addedUser);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInvitation = (invitation, addedUser) => {
    console.log(invitation, addedUser);
    const docRef = this.props.firestore.doc(invitation.resourcePath);
    docRef
      .get()
      .then((doc) => {
        const resource = doc.data();
        let { sharingUsers, sharingUserIds, userRoles, approverUserIds } =
          resource;
        // theoretically should be a guest document user, since not signed up
        // but there's an edge case where there's a workspace invitation as well
        // for now, we'll add to the sharingUser list anyway
        sharingUsers = sharingUsers.concat(addedUser);
        sharingUserIds = sharingUserIds.concat(addedUser.userId);
        if (approverUserIds) {
          const newUserRoles = { ...userRoles };
          newUserRoles[addedUser.userId] = invitation.role;
          const newApproverUserIds = [...approverUserIds];
          if (invitation.isApprover) newApproverUserIds.push(addedUser.userId);
          return docRef.update({
            sharingUsers,
            sharingUserIds,
            userRoles: newUserRoles,
            approverUserIds: newApproverUserIds
          });
        } else if (userRoles && invitation.role) {
          // TODO must migrate all existing doc userRoles to make this work consistently
          let newUserRoles = JSON.parse(JSON.stringify(userRoles)); // deep clone
          newUserRoles[addedUser.userId] = {
            role: invitation.role,
            isApprover: !!invitation.isApprover
          };
          return docRef.update({
            sharingUsers,
            sharingUserIds,
            userRoles: newUserRoles
          });
        } else {
          return docRef.update({ sharingUsers, sharingUserIds });
        }
      })
      .then(() => {
        return this.props.firestore.doc(`email/${invitation.id}`).delete();
      })
      .then(() => {
        return this.props.firestore
          .doc(`users/${invitation.initiatingUserId}`)
          .get();
      })
      .then((doc) => {
        const initiatingUser = doc.data();
        addNotification(
          this.props.firestore,
          addedUser,
          initiatingUser,
          invitation.inviteIntent,
          invitation.resourcePath
        );
        trackEvent(
          this.props.firebase,
          `Accepted ${invitation.inviteType} invitiation`
        );
        this.props.history.push(invitation.redirectUrl);
        return;
      });
  };

  handleChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];

    this.setState({
      [event.target.name]: event.target.value,
      errors
    });
  };

  buttonDisabled = () => {
    const { email, password, displayName, termsConsent, errors, processing } =
      this.state;
    return !(
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      password &&
      displayName &&
      termsConsent &&
      Object.keys(errors).length === 0 &&
      !processing
    );
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleCheckboxChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  render() {
    const { auth } = this.props;
    const { errors, processing, invitation } = this.state;

    if (!isLoaded(auth)) {
      return <Loader />;
    }
    return (
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        direction="column"
        spacing={6}>
        <Grid item>
          <Wrapper elevation={10}>
            <Container maxWidth="xs">
              <Helmet title="Signup to PingGo" />
              <Grid container justifyContent="center">
                <img src={AppIcon} alt="foxy" width="250px" />
              </Grid>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                gutterBottom
                my={3}>
                Signup to PingGo
              </Typography>
              {invitation && (
                <Typography variant="body1" align="center" gutterBottom my={3}>
                  to accept your invitation
                </Typography>
              )}
              <TextField
                mb={2}
                required
                autoFocus
                id="displayName"
                name="displayName"
                type="text"
                label="Full name"
                value={this.state.displayName}
                onChange={this.handleChange}
                helperText={errors.displayName}
                error={errors.displayName ? true : false}
                fullWidth
              />
              <TextField
                mb={2}
                required
                id="email"
                name="email"
                type="email"
                label="Email"
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                mb={2}
                required
                id="password"
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                label="Choose a password"
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}>
                        {this.state.showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormControlLabel
                mt={3}
                // position checkbox at top
                style={{ display: "table" }}
                control={
                  <div style={{ display: "table-cell" }}>
                    <Checkbox
                      checked={Boolean(this.state.termsConsent)}
                      onChange={this.handleCheckboxChange}
                      name="termsConsent"
                      color="primary"
                    />
                  </div>
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    I agree to PingGo's{" "}
                    <a href="https://ping-go.com/tos">Terms of Service</a> and{" "}
                    <a href="https://ping-go.com/privacy">Privacy Policy</a>,
                    and I consent to PingGo transferring, hosting and processing
                    my data outside of the UK.*
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                mb={3}
                // position checkbox at top
                style={{ display: "table" }}
                control={
                  <div style={{ display: "table-cell" }}>
                    <Checkbox
                      checked={Boolean(this.state.marketingConsent)}
                      onChange={this.handleCheckboxChange}
                      name="marketingConsent"
                      color="primary"
                    />
                  </div>
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    I agree to receive marketing emails from PingGo.
                  </Typography>
                }
              />
              {errors.general && (
                <Typography mb={2} variant="body2" color="primary">
                  {errors.general}
                </Typography>
              )}
              {/* <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary">
                  * Required
                </Typography>
              </Grid> */}
              <Grid container justifyContent="center">
                <Button
                  my={4}
                  variant="contained"
                  fullWidth
                  onClick={this.handleSignup}
                  color="primary"
                  disabled={this.buttonDisabled()}>
                  {processing ? (
                    <CircularProgress size={30} />
                  ) : (
                    "Let's get started"
                  )}
                </Button>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Already have an account?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    pb={1}
                    size="small"
                    component={Link}
                    to="/auth/login"
                    color="secondary">
                    Login here
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Wrapper>
        </Grid>
        <Grid item container justifyContent="space-evenly" alignItems="center">
          <Grid item xs={5}>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              style={{ color: "#afafaf", textAlign: "center" }}>
              or
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <GoogleButton
              onClick={this.loginWithGoogle}
              type="light"
              label="Signup with Google"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

export default compose(
  withFirebase,
  withFirestore,
  connect(mapStateToProps)
)(Signup);
