import Page403 from "../pages/auth/Page403";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ResetPassword from "../pages/auth/ResetPassword";

import MyPingGo from "../pages/pages/MyPingGo";
import Team from "../pages/pages/Team";
import Workspaces from "../pages/pages/Workspaces";
import Workspace from "../pages/pages/Workspace";
import StartDoc from "../pages/pages/StartDoc";
import CreateDoc from "../pages/pages/CreateDoc";
import ReviewDoc from "../pages/pages/ReviewDoc";
// import SignoffDoc from "../pages/pages/SignoffDoc";
import ShareDoc from "../pages/pages/ShareDoc";
import Template from "../pages/pages/Template";
import MySubscription from "../pages/pages/MySubscription";

const homeRoute = {
  path: "/",
  component: Workspaces
};

const mySubscriptionRoute = {
  path: "/mySubscription",
  component: MySubscription
};

const workspacesRoute = {
  path: "/workspaces",
  component: Workspaces
};

const myPingGoRoute = {
  path: "/mypinggo",
  component: MyPingGo
};

const workspaceRoute = {
  path: "/workspaces/:workspaceId",
  component: Workspace
};

const teamRoute = {
  path: "/teams/:teamId/:tab?",
  component: Team
};

const startDocRoute = {
  path: "/workspaces/:workspaceId/documents/start/:category",
  component: StartDoc
};

const createDocRoute = {
  path: "/workspaces/:workspaceId/documents/:documentId/create",
  component: CreateDoc
};

const reviewDocRoute = {
  path: "/workspaces/:workspaceId/documents/:documentId/review",
  component: ReviewDoc
};

const signoffDocRoute = {
  path: "/workspaces/:workspaceId/documents/:documentId/signoff",
  component: ReviewDoc
};

const shareDocRoute = {
  path: "/workspaces/:workspaceId/documents/:documentId/share",
  component: ShareDoc
};

const startTemplateRoute = {
  path: "/teams/:teamId/templates/start/:templateId",
  component: StartDoc
};

const templateRoute = {
  path: "/teams/:teamId/templates/:templateId",
  component: Template
};

const loginRoute = {
  path: "/auth/login",
  component: Login
};

const guestRoute = {
  path: "/auth/guest/:token",
  component: Login
};

const signupRoute = {
  path: "/auth/signup",
  component: Signup
};

const inviteRoute = {
  path: "/auth/invite/:token",
  component: Signup
};

const resetPasswordRoute = {
  path: "/auth/reset-password",
  component: ResetPassword
};

const error403Route = {
  path: "/error/403",
  component: Page403
};

const error404Route = {
  path: "/error/404",
  component: Page404
};

const error500Route = {
  path: "/error/500",
  component: Page500
};

export const dashboardLayoutRoutes = [
  homeRoute,
  myPingGoRoute,
  mySubscriptionRoute,
  workspaceRoute,
  workspacesRoute,
  teamRoute
];

export const multiColumnLayoutRoutes = [
  startDocRoute,
  createDocRoute,
  reviewDocRoute,
  signoffDocRoute,
  shareDocRoute,
  startTemplateRoute,
  templateRoute
];
export const authLayoutRoutes = [
  loginRoute,
  guestRoute,
  signupRoute,
  inviteRoute,
  resetPasswordRoute
];
export const errorRoutes = [error403Route, error404Route, error500Route];
