const updateEmailVerification = ({ profile, firestore }) => {
  if (profile) {
    // console.log("in UEV with profile");
    const { senderSignatureId, hasVerifiedEmail } = profile;
    if (senderSignatureId && !hasVerifiedEmail) {
      // console.log("yep needs fixed");
      fetch(
        "https://europe-west1-pinggo-v2.cloudfunctions.net/api/sender-signatures/" +
          senderSignatureId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.Confirmed) {
            // console.log("yep fixing");
            return firestore.update(`users/${profile.userId}`, {
              hasVerifiedEmail: true
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
export default updateEmailVerification;
