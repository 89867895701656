import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";

import {
  Button as MuiButton,
  Card as MuiCard,
  Grid,
  CardContent as MuiCardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Radio
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { compose } from "recompose";
import autoId from "../util/autoId";
import trackEvent from "../util/trackEvent";

import SECTORS from "../util/sectors";
// import CONTENT_TYPES from "../util/contentTypes";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)`
  margin: 10px 10px;
`;
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

class CreateTemplateDialog extends Component {
  state = {
    selectedTemplate: null, //this.availableTemplates()[0],
    open: false,
    error: null
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const teamId = this.props.teamId;
    const template = this.availableTemplates()[this.state.selectedTemplate];
    const newId = autoId();
    const templateDetails = {
      teamId,
      title: `Copy of ${template.title}`,
      description: template.description,
      text: template.text,
      modifiedOn: new Date().getTime(),
      isDefaultTemplate: template.isDefaultTemplate,
      contentType: template.contentType,
      sector: template.sector
    };
    this.props.firestore
      .set(`teams/${teamId}/templates/${newId}`, templateDetails)
      .then((ref) => {
        trackEvent(this.props.firebase, "Created a template", {
          from: template.title
        });
        this.props.history.push(`/teams/${teamId}/templates/${newId}`);
      })
      .catch((error) => {
        console.log(error);
      });
    this.handleClose();
  };

  handleChange = (event) => {
    this.setState({
      selectedTemplate: event.target.value
    });
  };

  availableTemplates() {
    // add a blank template first
    let templates = Object.assign({}, this.props.pingGoTemplates);
    templates["Blank"] = {
      title: "Blank",
      contentType: "pressRelease",
      text: "<h1>[CompanyName] Press Release</h1>\n",
      description: "A completely blank Press Release",
      isDefaultTemplate: false,
      sector: SECTORS[0]
    };
    return templates;
  }

  render() {
    if (!this.props.pingGoTemplates) return null;
    return (
      <>
        <Button variant="contained" color="primary" onClick={this.handleOpen}>
          New template
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="md">
          <DialogTitle>Choose a template</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Choose a template as a starting point, or just a blank Press
              Release. Then you can edit the template and publish it for your
              team.
            </DialogContentText>
            {Object.entries(this.availableTemplates()).map(
              ([key, template]) => (
                <Card key={key}>
                  <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={3}>
                        <Radio
                          checked={this.state.selectedTemplate === key}
                          onChange={this.handleChange}
                          value={key}
                          name="selectedTemplate"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        container
                        direction="column"
                        spacing={2}>
                        <Grid item>
                          <Typography variant="h4">{template.title}</Typography>
                        </Grid>
                        {/* <Grid item>
                        <Typography>{template.variation}</Typography>
                      </Grid> */}
                        <Grid item>
                          <Typography variant="body2">
                            {template.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )
            )}
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!this.state.selectedTemplate}
              onClick={this.handleSubmit}
              color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  pingGoTemplates: state.firestore.data["teams/R7Z1iCDHpvS73plmK1iP/templates"]
});

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "teams/R7Z1iCDHpvS73plmK1iP/templates"
    }
  ]),
  connect(mapStateToProps)
)(CreateTemplateDialog);
