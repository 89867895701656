import React from "react";
import Helmet from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { compose } from "recompose";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { isLoaded, firestoreConnect } from "react-redux-firebase";

import Routes from "./routes/Routes";
import theme from "./theme";
import packageJson from "../package.json";

function App(props) {
  const { profile } = props;
  if (isLoaded(profile)) {
    window.intercomSettings = {
      app_id: "f0w49in5",
      name: profile.name,
      email: profile.email,
      user_id: profile.userId,
      created_at: profile.createdAt / 1000,
      grandfathered: profile.grandfathered ? true : null,
      last_created_workspace_sector: profile.lastCreatedWorkspaceSector || null,
      marketing_consent: profile.marketingConsent ? true : null,
      pinggo_version: packageJson.version
    };
  }
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/f0w49in5";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <>
      <Helmet titleTemplate="%s | PingGo" defaultTitle="PingGo" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  );
}

const mapStateToProps = (state) => {
  const versions = state.firestore.data.versions;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    version: versions
      ? state.firestore.data.versions[process.env.NODE_ENV]
      : null
  };
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "versions",
      doc: process.env.NODE_ENV
    }
  ]),
  connect(mapStateToProps)
)(App);
