import { createTheme } from "@material-ui/core/styles";
import { green, grey, amber } from "@material-ui/core/colors";

const theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1280,
      xl: 1440
    }
  },
  overrides: {
    MuiCardHeader: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px"
      }
    },
    MuiPickersDay: {
      day: {
        fontWeight: "300"
      }
    },
    MuiPickersYear: {
      root: {
        height: "64px"
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: "6px"
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent"
        }
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px"
      }
    },
    MuiPickersClock: {
      container: {
        margin: `32px 0 4px`
      }
    },
    MuiPickersClockNumber: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px"
      }
    },
    MuiPickerDTHeader: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400
        }
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400
        }
      }
    },
    MuiPickersTimePicker: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400
        }
      }
    },
    MuiAccordionSummary: {
      content: {
        margin: "5px 0px"
      }
    },
    MuiAvatar: {
      root: {
        fontSize: "0.8rem",
        height: "32px",
        width: "32px"
      }
    },
    MuiDialogActions: {
      root: {
        margin: "12px"
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: 1.2
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiCardHeader: {
      titleTypographyProps: { variant: "h4" }
    },
    MuiDialogTitle: {
      disableTypography: true
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h6: {
      fontSize: "0.95rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 15
    },
    caption: {
      fontSize: 11
    },
    button: {
      textTransform: "none"
    }
  },
  // shadows: shadows,
  palette: {
    primary: {
      main: amber[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: grey[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[300]
    },
    indicator: {
      background: amber[700]
    }
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#000",
      background: "#FFF",
      brand: {
        color: "#ccc"
      }
    },
    footer: {
      color: grey[800],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 600
    },
    badge: {
      color: "#FFF",
      background: green[600]
    }
  },
  body: {
    background: "#F8F8FC"
  }
});

export default theme;
