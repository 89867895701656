import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import trackEvent from "../util/trackEvent";

const Button = styled(MuiButton)(spacing);

class VerifyEmailDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      open: false,
      error: null
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleSubmit = () => {
    const { name, email, userId, senderSignatureId } = this.props.profile;
    this.handleClose();
    if (!senderSignatureId) {
      const verificationDetails = {
        name,
        email,
        userId
      };
      fetch(
        "https://europe-west1-pinggo-v2.cloudfunctions.net/api/verify-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(verificationDetails)
        }
      )
        .then((response) => {
          trackEvent(this.props.firebase, "Initiated email verification", {
            email
          });
          console.log(response);
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // already have a senderSignatureId, so ask for resend
      fetch(
        "https://europe-west1-pinggo-v2.cloudfunctions.net/api/resend-sender-signature-confirmation/" +
          senderSignatureId
      )
        .then((response) => {
          trackEvent(
            this.props.firebase,
            "Requested email verification re-send",
            {
              email
            }
          );
          console.log(response);
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>Verify my email address</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            You need to verify your email address with our email partner
            Postmark before you can send content by email from that address.
            Without verification, your content will be sent from
            <i>pressreleases@ping-go.com</i>.
          </Typography>
          <Typography variant="body2">
            Click on the 'Send Verification' button below, and PostMark will
            send an email to {this.props.profile.email}. In that email, click on
            'Verify Address' or copy and paste the link in your browser, and
            Postmark will let us know your address has been verified.
          </Typography>
          <Typography variant="body2">
            Please note that it's not possible to deliver reliably from Gmail,
            Yahoo etc free addresses, so we don't allow these to be verified.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="primary">
            {this.props.profile.senderSignatureId
              ? "Re-send verification"
              : "Send verification"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(VerifyEmailDialog);
