import React, { useState } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

import ContactTable from "./ContactTable";

const Button = styled(MuiButton)(spacing);

function DistributionDialog({
  contacts,
  teamId,
  addToDistribution,
  distributionList,
  workspace
}) {
  const [open, setOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [assignedUserEmail, setAssignedUserEmail] = useState("");
  const { sharingUsers, ownerUser } = workspace;
  const users = sharingUsers.concat(ownerUser);

  const allTagsInUse = contacts.reduce((acc, contact) => {
    if (contact.tags) {
      const tags = contact.tags.split(",");
      tags.forEach((tag) => {
        if (acc.indexOf(tag) === -1) acc.push(tag);
      });
    }
    return acc;
  }, []);

  const handleOpen = () => {
    setSelectedContacts([]);
    setAssignedUserEmail("");
    setOpen(true);
  };

  const handleSelectionChange = ({ selectedRows }) => {
    setSelectedContacts(selectedRows);
  };

  const handleAssignedUserEmailChange = (event) => {
    setAssignedUserEmail(event.target.value);
  };

  const submit = () => {
    addToDistribution(
      selectedContacts.map(({ email }) => email),
      assignedUserEmail
    );
    setOpen(false);
  };

  const filteredContacts = contacts.filter(
    (contact) => distributionList && !distributionList.includes(contact.email)
  );

  return (
    <>
      <Button
        ml={3}
        mt={3}
        variant="contained"
        color="primary"
        style={{ whiteSpace: "nowrap" }}
        onClick={handleOpen}>
        Add from Contacts
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullwidth="true"
        maxWidth="lg">
        <DialogTitle>Add contacts to distribution</DialogTitle>
        <DialogContent>
          <ContactTable
            contacts={filteredContacts}
            teamId={teamId}
            allTagsInUse={allTagsInUse}
            selectMode
            handleSelectionChange={handleSelectionChange}
          />
        </DialogContent>
        <DialogActions>
          <FormControl
            style={{
              minWidth: 200,
              marginRight: "40px",
              marginBottom: "5px"
            }}>
            <InputLabel>Assign to a user</InputLabel>
            <Select
              autoWidth
              value={assignedUserEmail}
              onChange={handleAssignedUserEmailChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.email} value={user.email}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={submit}
            color="primary"
            disabled={selectedContacts.length === 0}>
            {selectedContacts.length > 0
              ? `Add ${selectedContacts.length} contact${
                  selectedContacts.length === 1 ? "" : "s"
                } to distribution`
              : "No contacts selected"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DistributionDialog;
