const SECTORS = [
  // "Consumer Products",
  "B2B Company",
  "Consumer Tech (B2C)",
  "Energy (B2B)",
  "Fintech (B2B)",
  "Food and Drink (B2B)",
  "Industrial (B2B)",
  // "Interiors (B2C)",
  // "Interiors (B2B)",
  "Life Sciences (B2B)",
  // "Property (B2C)",
  "Professional Services (Law)",
  "Property (B2B)",
  "Publishing (B2B)",
  "Restaurant",
  "Retail (B2C)",
  // "Retail (B2B)",
  "Technology (B2B)",
  "Third Sector",
  "Trade Organisation",
  "Travel (B2B)",
  "Toy Company (Demo)"
];

export default SECTORS;
