import React, { useState } from "react";
import styled from "styled-components";
import stc from "string-to-color";

import initials from "../util/initials";

import {
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  ListItemAvatar,
  IconButton,
  ListItemIcon
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

const SmallAvatar = styled(Avatar)`
  height: 27px;
  width: 27px;
  font-size: 0.75rem;
  margin-left: 1px;
`;

function AssignItemMenu({
  toEmail,
  assignedDistributions,
  workspace,
  handleAssignUser
}) {
  const [anchorEl, setAnchorEl] = useState();

  const assigned = assignedDistributions || {};
  const assignedToEmail = assigned[toEmail];
  const { sharingUsers, ownerUser } = workspace;
  const users = sharingUsers.concat(ownerUser);
  const assignedUser = users.find((user) => user.email === assignedToEmail);

  const open = Boolean(anchorEl);
  const id = open ? "manage-assignedUser" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (userEmail) => {
    handleAssignUser(toEmail, userEmail);
    handleClose();
  };

  return (
    <ListItemIcon>
      {assignedUser ? (
        <Tooltip title={"Assigned to " + assignedUser.name} arrow>
          <ListItemAvatar>
            <IconButton onClick={handleClick}>
              <SmallAvatar
                alt={assignedUser.name}
                src={assignedUser.photo}
                style={{
                  backgroundColor: stc(assignedUser.name)
                }}>
                {initials(assignedUser.name)}
              </SmallAvatar>
            </IconButton>
          </ListItemAvatar>
        </Tooltip>
      ) : (
        <Tooltip title="Click to assign" arrow>
          <IconButton onClick={handleClick}>
            <Person />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}>
        <MenuItem
          variant="text"
          selected={!assignedUser}
          onClick={() => handleSelect(null)}>
          No-one assigned
        </MenuItem>
        {users.map((user) => (
          <MenuItem
            key={user.email}
            selected={user.email === assignedUser?.email}
            variant="text"
            onClick={() => handleSelect(user.email)}>
            {user.name}
          </MenuItem>
        ))}
      </Menu>
    </ListItemIcon>
  );
}

export default AssignItemMenu;
