import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  ListItemAvatar,
  Avatar as MuiAvatar,
  ListItemText,
  MenuItem as MuiMenuItem,
  Popover,
  Tooltip,
  Badge as MuiBadge,
  ListItem,
  MenuList as MuiMenuList,
  ClickAwayListener
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import stc from "string-to-color";
import initials from "../util/initials";
// import trackEvent from "../util/trackEvent";

const MenuList = styled(MuiMenuList)`
  &.MuiList-dense {
    padding-top: 0;
    padding-bottom: 3px;
  }
`;
const Avatar = styled(MuiAvatar)`
  && {
    font-size: 1rem;
  }
`;
const Badge = styled(MuiBadge)`
  border-radius: 50%;
  .MuiBadge-badge {
    height: 16px;
    min-width: 16px;
    // border-radius: 6px;
    padding: 0;
  }
  .MuiBadge-anchorOriginTopRightCircle {
    top: 2%;
  }
  .MuiBadge-colorPrimary {
    color: #3bc300;
    background-color: #fff;
  }
  .MuiBadge-colorSecondary {
    color: #dedede;
    background-color: #fff;
  }
`;
const CheckIcon = styled(CheckCircle)`
  width: 16px;
  height: 16px;
`;
const MenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

// const USER_ROLES = ["Editor", "Suggester", "Commenter", "Viewer"];

class ManageMemberMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemoveUser = () => {
    this.props.handleRemoveUser();
    this.handleClose();
  };

  // handleMenuRoleUpdate = (thisRole) => {
  //   this.props.handleMenuRoleUpdate(thisRole);
  //   this.handleClose();
  // };

  // handleToggleApprover = () => {
  //   this.props.handleToggleApprover();
  //   this.handleClose();
  // };

  handleClickAway = (event) => {
    const thisMenu = document.getElementById(this.props.user.userId);
    // sometimes the element is not found, so we ignore
    if (thisMenu && !thisMenu.contains(event.target)) this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const {
      user,
      profile,
      // isAnApprover,
      // hasApproved,
      // role,
      canEdit,
      isLiveApprover,
      isLiveOwner,
      isOwner
    } = this.props;
    const id = open ? user.userId : undefined;

    const cantRemove = isLiveApprover || isLiveOwner;
    const cantRemoveReason = () => {
      let reason = "Can't be removed because they ";
      if (isLiveApprover) reason += "are a current approver";
      if (isLiveApprover && isLiveOwner) reason += " and ";
      if (isLiveOwner) reason += "own one or more documents";
      return reason;
    };

    return (
      <div id={id}>
        <Tooltip
          title={
            // isAnApprover
            //   ? hasApproved
            //     ? `${stageText.actioned} by ${
            //         user.userId === profile.userId
            //           ? "you"
            //           : user.name
            //       }`
            //     : `Awaiting ${stageText.action_noun} from ${
            //         user.userId === profile.userId
            //           ? "you"
            //           : user.name
            //       }`
            //   :
            user.userId === profile.userId ? "You" : user.name
          }
          arrow>
          <Badge
            badgeContent={<CheckIcon />}
            // we keep this here in case we want to have 'admin' badges etc
            // also the border styling works fine and we don't want to lose it!
            // color={
            //   isAnApprover ? (hasApproved ? "primary" : "secondary") : "primary"
            // }
            invisible={true} //!isAnApprover}
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{ marginLeft: "-10px", border: "2px solid #fafafa" }}>
            <Avatar
              alt={user.name}
              src={user.photo}
              style={{
                backgroundColor: stc(user.name)
                // we create 2 shadows as the white border is removed
                // boxShadow: userSession
                //   ? "0 0 0 2px #fafafa, 0 0 0 4px " +
                //     USER_COLORS[userSession.color.getMarkerClass()]
                //   : "none"
              }}
              onClick={this.handleClick}>
              {initials(user.name)}
            </Avatar>
          </Badge>
        </Tooltip>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Popover
            // id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            // elevation={8}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            disableEnforceFocus
            style={{ pointerEvents: "none" }}
            PaperProps={{ style: { pointerEvents: "auto" } }}>
            <MenuList dense>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={user.name}
                    src={user.photo}
                    style={{
                      backgroundColor: stc(user.name)
                    }}>
                    {initials(user.name || user.email)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.name + (isOwner ? " (owner)" : "")}
                  secondary={user.email}
                />
              </ListItem>

              {/* <ListItem>
              <ListItemText
                primary={
                  isAnApprover
                    ? hasApproved
                      ? stageText.actioned
                      : `Awaiting ${stageText.action_noun}`
                    : null
                }
              />
            </ListItem> */}
              {/* {canEdit && (
              <MenuItem
                variant="text"
                color="primary"
                onClick={this.handleToggleApprover}
                divider>
                <ListItemIcon>
                  {isAnApprover && <Check fontSize="small" />}
                </ListItemIcon>
                {`Require their ${stageText.action_noun}`}
              </MenuItem>
            )} */}
              {/* {canEdit &&
              USER_ROLES.map((thisRole, index) => (
                <MenuItem
                  key={thisRole}
                  // selected={role === thisRole}
                  onClick={(event) => this.handleMenuRoleUpdate(thisRole)}
                  divider={index === USER_ROLES.length - 1 && canBeRemoved}>
                  <ListItemIcon>
                    {role === thisRole && <Check fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText primary={thisRole} />
                </MenuItem>
              ))} */}
              {canEdit && !isOwner && (
                <Tooltip title={cantRemove ? cantRemoveReason() : ""} arrow>
                  <MenuItem
                    style={{
                      color: cantRemove ? "pink" : "red",
                      align: "center"
                    }}
                    onClick={cantRemove ? () => {} : this.handleRemoveUser}>
                    Remove
                  </MenuItem>
                </Tooltip>
              )}
            </MenuList>
          </Popover>
        </ClickAwayListener>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  withRouter,
  connect(mapStateToProps)
)(ManageMemberMenu);
