import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import {
  MenuItem,
  Menu,
  IconButton as MuiIconButton,
  Tooltip
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import autoId from "../util/autoId";
import trackEvent from "../util/trackEvent";

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

class ManageTemplateMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.props.firestore
      .delete(`teams/${this.props.teamId}/templates/${this.props.templateId}`)
      .then(() => {
        trackEvent(this.props.firebase, "Deleted a template");
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDuplicate = () => {
    const { teamId, template } = this.props;
    const newId = autoId();
    const templateDetails = {
      teamId,
      title: `Copy of ${template.title}`,
      description: template.description,
      sector: template.sector,
      text: template.text,
      modifiedOn: new Date().getTime(),
      isDefaultTemplate: template.isDefaultTemplate,
      contentType: template.contentType
    };
    this.props.firestore
      .set(`teams/${teamId}/templates/${newId}`, templateDetails)
      .then(() => {
        trackEvent(this.props.firebase, "Duplicated a template");
        this.props.history.push(`/teams/${teamId}/templates/${newId}`);
      })
      .catch((error) => {
        console.log(error);
      });
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-template-popup" : undefined;
    const { templateId, teamId } = this.props;

    return (
      <>
        <Tooltip title="Template actions" arrow>
          <IconButton onClick={this.handleClick}>
            <MoreVert />
          </IconButton>
        </Tooltip>
        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}>
          <MenuItem
            variant="text"
            onClick={() =>
              this.props.history.push(
                `/teams/${teamId}/templates/${templateId}`
              )
            }>
            Edit
          </MenuItem>
          <MenuItem variant="text" onClick={this.handleDuplicate} divider>
            Duplicate
          </MenuItem>
          <MenuItem
            variant="text"
            onClick={this.handleDelete}
            style={{ color: "red" }}>
            Delete
          </MenuItem>
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  withRouter,
  connect(mapStateToProps)
)(ManageTemplateMenu);
