import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { compose } from "recompose";
import { MenuItem, Menu, IconButton, Tooltip } from "@material-ui/core";
import { MoreVert, MoreHoriz } from "@material-ui/icons";
import WorkspaceDialog from "./WorkspaceDialog";
import trackEvent from "../util/trackEvent";

class ManageWorkspaceMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
    this.workspaceDialog = React.createRef();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToggleArchived = () => {
    const currentArchivedState = this.props.workspace.isArchived || false;
    this.props.firestore
      .update(`workspaces/${this.props.workspaceId}`, {
        isArchived: !currentArchivedState
      })
      .then(() => {
        trackEvent(
          this.props.firebase,
          !currentArchivedState
            ? "Archived a workspace"
            : "Un-archived a workspace"
        );
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleWorkspaceDialogOpen = () => {
    this.child.handleOpen();
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-workspace-popup" : undefined;
    const { workspace, workspaceId, verticalIcon } = this.props;
    return (
      <>
        <WorkspaceDialog
          childRef={(ref) => (this.child = ref)}
          workspace={workspace}
          workspaceId={workspaceId}
          mode="Edit"
        />
        <div>
          <Tooltip title="More actions" arrow>
            <IconButton onClick={this.handleClick}>
              {verticalIcon ? <MoreVert /> : <MoreHoriz />}
            </IconButton>
          </Tooltip>
          <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}>
            <MenuItem variant="text" onClick={this.handleWorkspaceDialogOpen}>
              Edit
            </MenuItem>
            {/* {(profile.userId === workspace.billingUserId ||
              profile.userId === workspace.ownerUserId) && (
              <WorkspacePlanDialog
                mode="menu"
                workspace={workspace}
                workspaceId={workspaceId}
                parentPopup={this}
                handleUpdate={handleUpdate}
              />
            )} */}
            <MenuItem variant="text" onClick={this.handleToggleArchived}>
              {workspace.isArchived ? "Un-archive" : "Archive"}
            </MenuItem>
          </Menu>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  connect(mapStateToProps)
)(ManageWorkspaceMenu);
