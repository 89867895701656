import React, { Fragment } from "react";
import { createGlobalStyle } from "styled-components";
import { CssBaseline, withWidth } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
html,
body {
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flex-section {
  flex-grow: 1;
  display: flex;
  // flex-direction: column;
  flex-wrap: nowrap;
  min-height: 0;
}
.flex-col-scroll-left {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
  margin-left: 18px;
  margin-right: 6px;
  white-space: normal;
}
.message-list {
  flex: 1;
  min-width: 140px;
  overflow: auto;
}
.flex-col-scroll-right {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
  margin-left: 6px;
  margin-right: 18px;
  white-space: normal;
}
.flex-no-shrink {
  flex-shrink: 0;
  padding: 6px 18px 12px;
  background-color: #fff;
}
`;

const TwoCol = ({ children, routes, width }) => {
  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Fragment>
  );
};

export default withWidth()(TwoCol);
