const startDocSteps = [
  {
    target: "body",
    placement: "center",
    title: "Take a quick tour?",
    content: `First time creating content in PingGo? Let's show you the key steps - 
     click Next to find out more, or 'Skip the tour' to get started yourself`
  },
  {
    target: ".step-0",
    placement: "bottom",
    title: "Step 1",
    content: `First you'll select which template to use as the basis for your content.
    You're already on the screen that let's you do that, and when you select one you'll see
    a preview of what it looks like with some placeholder text inserted (or your Key 
      Messages if you already have some).`
  },
  {
    target: ".step-1",
    placement: "bottom",
    title: "Step 2",
    content: `Then you'll answer some questions to fill in the blanks. You'll be guided through
    each question with some explanation about how to frame your answer for best effect.`
  },
  {
    target: ".step-2",
    placement: "bottom",
    title: "Step 3",
    content: `Your content now has all the component parts in place, so now's the time
    to tweak to your taste, ask others to provide feedback, and set up approvers so you've 
    got the right sign-off before distribution.`
  },
  {
    target: ".step-3",
    placement: "bottom",
    title: "Step 4",
    content: `Your content is ready to get out into the big wide world - you can copy the
    text to use in your own workflow, or send by email to your media contacts.`
  },
  {
    target: "body",
    placement: "center",
    title: "Get Started",
    content: `That's all there is to it. Let's get going!`
  }
];

export default startDocSteps;
