const trackEvent = (
  firebase,
  eventName,
  metadata,
  analyticsEventName = eventName
) => {
  firebase.analytics().logEvent(analyticsEventName, metadata);
  window.Intercom("trackEvent", eventName, metadata);
};
export default trackEvent;
