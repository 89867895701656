const addNotification = (
  firestore,
  user,
  initiatingUser,
  message,
  redirectUrl
) => {
  const fromName = initiatingUser.name || initiatingUser.email; // catering for guest users
  const newNotification = {
    initiatingUserName: fromName,
    initiatingUserPhoto: initiatingUser.photo || "",
    message,
    redirectUrl,
    createdAt: new Date().getTime()
  };
  // console.log("in add Notification");
  // console.log(user);
  // console.log(newNotification);
  firestore
    .add(`users/${user.userId}/notifications`, newNotification)
    .then(() => {
      // the user that's passed in has only a subset of the user fields, let's get the rest
      return firestore.collection("users").doc(user.userId).get();
    })
    .then((doc) => {
      return doc.data();
    })
    .then((returnedUser) => {
      if (!returnedUser.suppressEmailNotifications) {
        const newMessage = {
          // PascalCase keys as being passed as params to PostMark API
          Tag: "notification",
          From: `${fromName} via PingGo <notifications@ping-go.com>`,
          To: user.email,
          TemplateAlias: "notification",
          TemplateModel: {
            fromName,
            message,
            actionURL: redirectUrl ? `${window.location.href}` : null
          },
          TrackOpens: false
        };
        return fetch(
          "https://europe-west1-pinggo-v2.cloudfunctions.net/api/mail-send-with-template",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newMessage)
          }
        );
      } else {
        return;
      }
    })
    .then((response) => {
      if (response && response.status !== 200)
        throw new Error("Postmark mailer error");
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};
export default addNotification;
