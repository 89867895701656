const PRICING_PLANS = {
  0: {
    name: "Free",
    price: 0.0,
    test_price_id: "price_1IB0qL2S7oGCYNjTVN6xoSjC",
    live_price_id: "price_1IB0sj2S7oGCYNjTiRMxwNf1",
    max_additional_members: 0,
    max_approvers: 0,
    max_sign_offs: 0,
    can_track_emails: false,
    send_from_verified_owner_email: false,
    send_from_verified_domain: false,
    can_use_custom_templates: false,
    collaborative_comments: false,
    collaborative_editing: false
  },
  1: {
    name: "Essentials",
    price: 10.0,
    test_price_id: "price_1I9TJe2S7oGCYNjTHy1iEAr1",
    live_price_id: "price_1I6ahb2S7oGCYNjTIbs4EkvP",
    max_additional_members: 1,
    max_approvers: 1,
    max_sign_offs: 0,
    can_track_emails: false,
    send_from_verified_owner_email: false,
    send_from_verified_domain: false,
    can_use_custom_templates: false,
    collaborative_comments: false,
    collaborative_editing: false
  },
  2: {
    name: "Pro",
    price: 25.0,
    test_price_id: "price_1I9TJU2S7oGCYNjTEWx0IUhl",
    live_price_id: "price_1I6aiC2S7oGCYNjT6b7MmKQ0",
    max_additional_members: 5,
    max_approvers: 99,
    max_sign_offs: 0,
    can_track_emails: true,
    send_from_verified_owner_email: true,
    send_from_verified_domain: false,
    can_use_custom_templates: true,
    collaborative_comments: true,
    collaborative_editing: true
  },
  3: {
    name: "Advanced",
    price: 50.0,
    test_price_id: "price_1I9TJH2S7oGCYNjTRDab0rr6",
    live_price_id: "price_1I6aiW2S7oGCYNjTkj1V5Xhi",
    max_additional_members: 99,
    can_track_emails: true,
    max_approvers: 99,
    max_sign_offs: 99,
    send_from_verified_owner_email: true,
    send_from_verified_domain: true,
    can_use_custom_templates: true,
    collaborative_comments: true,
    collaborative_editing: true
  }
};

export default PRICING_PLANS;
