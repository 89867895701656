const canAccessPage = (auth, item, parent) => {
  // either the owner, billing user, sharing user, parent owner or parent sharing user
  const userId = auth.uid;
  if (item && userId === item.ownerUserId) return true;
  if (item && item.billingUserId && userId === item.billingUserId) return true;
  if (
    item &&
    item.sharingUserIds &&
    item.sharingUserIds.some((sharingUserId) => sharingUserId === userId)
  )
    return true;
  if (parent && userId === parent.ownerUserId) return true;
  if (
    parent &&
    parent.sharingUserIds &&
    parent.sharingUserIds.some((sharingUserId) => sharingUserId === userId)
  )
    return true;
  console.log("permission to access failed for auth, item, parent");
  console.log(JSON.stringify(auth));
  console.log(JSON.stringify(item));
  console.log(JSON.stringify(parent));
  return false;
};
export default canAccessPage;
