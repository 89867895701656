import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { compose } from "recompose";
import styled from "styled-components";
import {
  MenuItem,
  Menu,
  IconButton as MuiIconButton,
  Tooltip
} from "@material-ui/core";
import { MoreVert, MoreHoriz } from "@material-ui/icons";
import DocumentAttributesDialog from "./DocumentAttributesDialog";
import trackEvent from "../util/trackEvent";

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

class ManageDocumentMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null,
      dueDate: props.document.dueDate || null
    };
    this.documentDialog = React.createRef();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToggleArchived = () => {
    const currentArchivedState = this.props.document.isArchived || false;
    this.props.firestore
      .update(
        `workspaces/${this.props.document.workspaceId}/documents/${this.props.documentId}`,
        {
          isArchived: !currentArchivedState
        }
      )
      .then(() => {
        trackEvent(
          this.props.firebase,
          !currentArchivedState
            ? "Archived a press release"
            : "Un-archived a press release"
        );
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDocumentAttributesDialogOpen = () => {
    this.child.handleOpen();
    this.handleClose();
  };

  handleCleanVersion = () => {
    this.handleClose();
    this.props.handleCleanVersion();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-document-popup" : undefined;
    const { document, documentId, verticalIcon } = this.props;

    return (
      <>
        <DocumentAttributesDialog
          childRef={(ref) => (this.child = ref)}
          document={document}
          documentId={documentId}
        />
        <div>
          <Tooltip title="More actions" arrow>
            {
              verticalIcon ? (
                <IconButton onClick={this.handleClick}>
                  <MoreVert />
                </IconButton>
              ) : (
                // <>
                //   <Hidden mdUp>
                <IconButton onClick={this.handleClick}>
                  <MoreHoriz />
                </IconButton>
              ) /* </Hidden>
                <Hidden smDown>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={this.handleClick}
                    endIcon={<MoreHoriz />}>
                    More
                  </Button>
                </Hidden>
              </> */
            }
          </Tooltip>
          <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}>
            <MenuItem
              variant="text"
              onClick={this.handleDocumentAttributesDialogOpen}>
              Release and Embargo dates...
            </MenuItem>
            <MenuItem variant="text" onClick={this.handleToggleArchived}>
              {document.isArchived
                ? "Mark as not archived"
                : "Mark as archived"}
            </MenuItem>
            {this.props.handleCleanVersion && (
              <MenuItem variant="text" onClick={this.handleCleanVersion}>
                Clean up (reject changes and comments)
              </MenuItem>
            )}
            {this.props.handlePrevStage && (
              <MenuItem variant="text" onClick={this.props.handlePrevStage}>
                {`Back to ${this.props.prevStageDescription}`}
              </MenuItem>
            )}
          </Menu>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  connect(mapStateToProps)
)(ManageDocumentMenu);
