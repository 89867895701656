import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";

import store from "./redux/store/index";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import Honeybadger from "@honeybadger-io/js";
import ErrorBoundary from "@honeybadger-io/react";

const firebaseConfig = {
  apiKey: "AIzaSyBEPYAY70i-CRBAbl7BXg7S1xwIdO3CRWk",
  authDomain: "go.ping-go.com",
  databaseURL: "https://pinggo-v2.firebaseio.com",
  projectId: "pinggo-v2",
  storageBucket: "pinggo-v2.appspot.com",
  messagingSenderId: "643822749514",
  appId: "1:643822749514:web:7084947136e3e42a4be3b3",
  measurementId: "G-SNT43WQ216",
};

const rrfConfig = {
  userProfile: "users",
  profileFactory: (userData, profileData, firebase) => {
    const user = userData.user ? userData.user : userData;
    // console.log(user.metadata);
    return {
      // user.displayName is populated for Google signups, we populate profile.displayName for
      // email signups
      name: user.displayName || profileData.displayName,
      // again data presented differently depending on signup type...
      photo: user.avatarUrl
        ? user.avatarUrl
        : user.photoURL
        ? user.photoURL
        : "",
      email: user.email,
      userId: user.uid ? user.uid : user.userId,
      createdAt: Number(user.metadata.a || user.metadata.createdAt),
    };
  },
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
// console.log(firebase.app().functions("europe-west1"));
firebase.firestore();
firebase.analytics();
firebase.functions();

// this should be the default but we've seen some unexpected logouts
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const rrfProps = {
  firebase,
  config: rrfConfig,
  firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

Honeybadger.configure({
  apiKey: "68898119",
  environment: process.env.NODE_ENV,
  breadcrumbsEnabled: false,
});

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ErrorBoundary honeybadger={Honeybadger}>
        <App />
      </ErrorBoundary>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
