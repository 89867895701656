const workspaceSteps = [
  {
    target: "body",
    placement: "center",
    title: "A Workspace",
    content: `Workspaces are where you group together content which all has a set of Key Messages
    in common - for example they might all belong to a single client, department or project.`
  },
  {
    target: ".tour-members",
    placement: "bottom",
    title: "Members",
    content: `Workspace members are all the PingGo users who have access to this workspace.`
  },
  {
    target: ".tour-press-releases",
    placement: "bottom",
    title: "Content",
    content: `All the content in a workspace is listed here. If there are archived items, you'll see a switch to hid or show it.`
  },
  {
    target: ".tour-key-messages",
    placement: "bottom",
    title: "Key Messages",
    content: `The workspace's current Key Messages are updated here when they change, so that
    those changes can be reflected consistently in later content. You can also
    add Key Messages here, but they're more likely to be collected first when you write your Press Pack
    or other content.`
  }
];

export default workspaceSteps;
