import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton as MuiIconButton
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import NewContentCards from "./NewContentCards";

const Button = styled(MuiButton)(spacing);

const CloseIconButton = styled(MuiIconButton)`
  padding: 6px 0 0;
  float: right;
  margin-top: -10px;
`;

class NewContentDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSelect = (category) => {
    this.handleClose();
    this.props.history.push(
      `/workspaces/${this.props.workspaceId}/documents/start/${category}`
    );
  };

  render() {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          style={{ whiteSpace: "nowrap" }}
          onClick={this.handleOpen}>
          New content
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="lg">
          <DialogTitle>
            Choose what content to create
            <CloseIconButton className="close" onClick={this.handleClose}>
              <CloseIcon />
            </CloseIconButton>
          </DialogTitle>
          <DialogContent>
            <NewContentCards handleSelect={this.handleSelect} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(NewContentDialog);
