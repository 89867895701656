import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  IconButton as MuiIconButton,
  Typography,
  Popover as MuiPopover,
  ListItemAvatar,
  Avatar,
  ListItem as MuiListItem,
  ListItemText,
  List as MuiList,
  Divider as MuiDivider,
  Badge,
  Tooltip
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
  Notifications as NotificationsIcon,
  Close as CloseIcon,
  Delete
} from "@material-ui/icons";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import stc from "string-to-color";
import initials from "../util/initials";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

const IconButton = styled(MuiIconButton)`
  // padding-top: 15px;
`;
const DeleteIcon = styled(Delete)`
  height: 20px;
  width: 20px;
  margin-top: 3px;
  margin-right: 5px;
`;
const TitleIconButton = styled(MuiIconButton)`
  padding: 6px 0 0;
  float: right;
  margin-top: -10px;
`;
const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    margin: 6px;
    // height: 400px;
    width: 450px;
    padding: 12px;
  }
`;
const List = styled(MuiList)`
  padding-bottom: 0;
`;
const ListItem = styled(MuiListItem)`
  padding: 0;
`;
const Divider = styled(MuiDivider)(spacing);

class NotificationsPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null }); // do this first so it closes quickly
    this.props.firebase
      .updateProfile({ lastNotificationReadAt: new Date().getTime() })
      .then(() => {
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleIgnoreBeforeNow = () => {
    // in lieu of potentially large number of deletes, can tidy up with cloud function later?
    this.handleClose();
    this.props.firebase
      .updateProfile({ ignoreNotificationsBefore: new Date().getTime() })
      .then(() => {
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleLink = (url) => {
    if (url) {
      this.handleClose();
      this.props.history.push(url);
    }
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-notification-popup" : undefined;
    // remove users from the autocomplete list who are already assigned
    const myNotifications = this.props.myNotifications || [];
    const unreadNotificationCount = myNotifications.filter(
      (notification) =>
        notification.createdAt >
        (this.props.profile.lastNotificationReadAt || 0)
    ).length;
    return (
      <div>
        <IconButton onClick={this.handleClick} className="tour-notifications">
          <Badge
            badgeContent={unreadNotificationCount}
            max={99}
            color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          elevation={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}>
          <Tooltip title="Close" arrow>
            <TitleIconButton className="close" onClick={this.handleClose}>
              <CloseIcon />
            </TitleIconButton>
          </Tooltip>
          <Tooltip title="Clear all" arrow>
            <TitleIconButton
              className="close"
              onClick={this.handleIgnoreBeforeNow}>
              <DeleteIcon />
            </TitleIconButton>
          </Tooltip>
          <Typography variant="h6" align="center" gutterBottom>
            Notifications
          </Typography>
          <Divider my={2} />
          {myNotifications.length === 0 && (
            <Typography align="center" variant="body1">
              No notifications
            </Typography>
          )}
          <List dense>
            {myNotifications.map((notification) => (
              <ListItem key={notification.id} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={notification.initiatingUserName}
                    src={notification.initiatingUserPhoto}
                    style={{
                      backgroundColor: stc(notification.initiatingUserName)
                    }}>
                    {initials(notification.initiatingUserName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // should remove the resourcePath reference eventurally, only required for buggy notifications pre 15/2/21
                  style={
                    notification.redirectUrl || notification.resourcePath
                      ? { cursor: "pointer" }
                      : {}
                  }
                  onClick={() =>
                    this.handleLink(
                      notification.redirectUrl ||
                        "/" + notification.resourcePath
                    )
                  }
                  primary={`${
                    notification.initiatingUserName
                  } (${formatDistanceToNow(notification.createdAt)} ago)`}
                  primaryTypographyProps={{
                    variation: "body2",
                    style: {
                      fontWeight:
                        notification.createdAt >
                        this.props.profile.lastNotificationReadAt
                          ? 700
                          : "inherit"
                    }
                  }}
                  secondary={notification.message}
                  secondaryTypographyProps={{
                    variation: "caption",
                    style: {
                      fontSize: "10pt", // hmmm, really hardcoded?
                      fontWeight:
                        notification.createdAt >
                        this.props.profile.lastNotificationReadAt
                          ? 550
                          : "inherit"
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  profile: state.firebase.profile,
  myNotifications: state.firestore.ordered.myNotifications
});

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: `users/${props.userId}/notifications`,
      where: ["createdAt", ">", props.ignoreNotificationsBefore],
      orderBy: ["createdAt", "desc"],
      storeAs: "myNotifications"
    }
  ]),
  connect(mapStateToProps)
)(NotificationsPopup);
