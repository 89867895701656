import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  MenuItem,
  Select as MuiSelect,
  FormControl as MuiFormControl,
  InputLabel
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import autoId from "../util/autoId";
import SECTORS from "../util/sectors";
import trackEvent from "../util/trackEvent";
// import PRICING_PLANS from "../util/pricingPlans";

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

class WorkspaceDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      name: props.workspace?.name || "",
      teamId: props.teamId || props.workspace?.teamId || "none", //can't be an empty string or the select won't select "No team"
      sector: props.workspace?.sector // can be undefined for pre-sector workspaces
        ? props.workspace.sector
        : SECTORS[0],
      // plan: props.workspace?.plan || 0,
      errors: {},
      open: false,
      error: null
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  selectableTeams = () => {
    const { myTeams, sharedTeams } = this.props;
    return Object.assign({}, myTeams, sharedTeams);
  };

  handleOpen = () => {
    this.setState({
      open: true,
      teamId: this.props.teamId || this.props.workspace?.teamId || "none"
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    if (!this.formIsValid()) return;
    let { name, sector, teamId } = this.state;
    const { userId } = this.props.profile;
    let teamName = "";
    let billingUserId;
    if (teamId === "none") {
      teamId = "";
      billingUserId =
        this.props.mode === "Add" ? userId : this.props.workspace.ownerUserId;
    } else {
      const selectedTeam = this.selectableTeams()[teamId];
      teamName = selectedTeam.name;
      billingUserId = selectedTeam.ownerUserId;
    }

    let workspaceDetails = {
      name,
      sector,
      // plan,
      teamId,
      teamName,
      billingUserId
    };
    if (this.props.mode === "Add") {
      const {
        name,
        email,
        photo,
        // grandfathered,
        // hasCreatedGrandfatheredWorkspace,
        isAdmin
      } = this.props.profile;
      workspaceDetails = {
        ...workspaceDetails,
        ownerUser: {
          name,
          email,
          photo,
          userId
        },
        ownerUserId: userId,
        keyAnswers: {},
        sharingUserIds: [],
        sharingUsers: []
      };
      const profileUpdateDetails = {
        lastCreatedWorkspaceSector: this.state.sector
      };
      // if (grandfathered && !hasCreatedGrandfatheredWorkspace) {
      //   workspaceDetails.plan = 2; // Pro Plan
      //   profileUpdateDetails.hasCreatedGrandfatheredWorkspace = true;
      // }
      if (isAdmin) {
        workspaceDetails.plan = 2; // Pro Plan
      }
      this.props.firebase.updateProfile(profileUpdateDetails);
      const newId = autoId();
      this.props.firestore
        .set(`workspaces/${newId}`, workspaceDetails)
        .then(() => {
          trackEvent(this.props.firebase, "Created a workspace");
          this.handleClose();
          this.props.history.push(`/workspaces/${newId}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // called in Edit mode
      this.props.firestore
        .update(`workspaces/${this.props.workspaceId}`, workspaceDetails)
        .then(() => {
          trackEvent(this.props.firebase, "Edited a workspace");
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleBlur = () => {
    // TODO handle individual blur on fields to validate them
    // this.formIsValid();
  };

  formIsValid = () => {
    const errors = {};
    const { name } = this.state;
    if (!name) {
      errors.name = "Enter a name for the workspace";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  render() {
    const teamOptions = this.selectableTeams();

    return (
      <>
        {this.props.mode === "Add" && (
          <Button variant="contained" color="primary" onClick={this.handleOpen}>
            New workspace
          </Button>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="xs">
          <DialogTitle>{this.props.mode} a workspace</DialogTitle>
          <DialogContent>
            <TextField
              my={4}
              id="name"
              name="name"
              label="Workspace name"
              type="text"
              error={!!this.state.errors.name}
              helperText={this.state.errors.name}
              onBlur={this.handleBlur}
              value={this.state.name}
              onChange={this.handleChange}
              fullWidth
              autoFocus
              inputProps={{
                autoComplete: "off"
              }}
            />
            <FormControl fullWidth my={2}>
              <InputLabel id="sectorSelectLabel">Sector</InputLabel>
              <Select
                id="sector"
                name="sector"
                labelId="sectorSelectLabel"
                fullWidth
                value={this.state.sector}
                onChange={this.handleChange}>
                {SECTORS.map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(this.state.teamId !== "none" ||
              (teamOptions && Object.keys(teamOptions).length > 0)) && (
              <FormControl fullWidth my={2}>
                <InputLabel id="teamSelectLabel">Team</InputLabel>
                <Select
                  id="teamId"
                  name="teamId"
                  labelId="teamSelectLabel"
                  autoWidth
                  value={this.state.teamId}
                  onChange={this.handleChange}>
                  <MenuItem value="none">No team</MenuItem>
                  {teamOptions &&
                    Object.keys(teamOptions).map((teamId) => (
                      <MenuItem key={teamId} value={teamId}>
                        {teamOptions[teamId].name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myTeams: state.firestore.data.myTeams,
  sharedTeams: state.firestore.data.sharedTeams
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "teams",
      where: ["ownerUserId", "==", props.auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "myTeams"
    },
    {
      collection: "teams",
      where: ["sharingUserIds", "array-contains", props.auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "sharedTeams"
    }
  ])
)(WorkspaceDialog);
