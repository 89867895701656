import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { withFirebase, withFirestore, isLoaded } from "react-redux-firebase";
import Helmet from "react-helmet";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import GoogleButton from "react-google-button";

import {
  Button as MuiButton,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import AppIcon from "../../assets/firebase-pinggo-logo.png";
import Loader from "../../components/Loader";
import trackEvent from "../../util/trackEvent";
import GRANDFATHERED_USERS from "../../util/grandfatheredUsers";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 20px 80px 12px;
  }
`;
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);

export class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      processing: false,
      handlingToken: false,
      errors: {}
    };
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    const locationState = this.props.location.state;
    // console.log(locationState);
    if (!locationState) return;
    // a token param in the email invitation provides guest access
    // console.log("got token " + token);
    const token = new URLSearchParams(locationState.from.search).get("token");
    if (!token) return;
    let customToken = null;
    let invitationEmailId = null;
    let uid = null;
    let invitationDocRef = null;
    let resourceDocRef = null;
    let invitation = null;
    let addedUser = null;
    this.setState({ handlingToken: true });
    fetch(
      "https://europe-west1-pinggo-v2.cloudfunctions.net/api/custom-token/" +
        token
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("got data from token service");
        // console.log(data);
        if (!data.customToken) {
          throw new Error(data.error);
        }
        ({ customToken, invitationEmailId, uid } = data);
        invitationDocRef = this.props.firestore.doc(
          `email/${invitationEmailId}`
        );
        return invitationDocRef.get();
      })
      .then((doc) => {
        invitation = doc.data();
        if (!invitation.invitationHandled) {
          // console.log("not handled");
          resourceDocRef = this.props.firestore.doc(invitation.resourcePath);
          resourceDocRef
            .get()
            .then((doc) => {
              let { sharingUsers, sharingUserIds, userRoles, approverUserIds } =
                doc.data();
              addedUser = {
                email: invitation.To,
                userId: uid,
                createdAt: new Date().getTime(),
                isGuest: true
              };
              // theoretically should always be an added document user, since not signed up
              // but there's an edge case where there's a workspace invitation as well out there
              // For now, we'll add to the sharingUser list anyway
              sharingUsers = sharingUsers.concat(addedUser);
              sharingUserIds = sharingUserIds.concat(addedUser.userId);
              // if (approverUserIds && userRoles) {
              const newUserRoles = { ...userRoles };
              newUserRoles[addedUser.userId] = invitation.role;
              const newApproverUserIds = [...approverUserIds];
              if (invitation.isApprover)
                newApproverUserIds.push(addedUser.userId);
              return resourceDocRef.update({
                sharingUsers,
                sharingUserIds,
                userRoles: newUserRoles,
                approverUserIds: newApproverUserIds
              });
              // } else if (userRoles && invitation.role) {
              // TODO must migrate all existing doc userRoles to make this work consistently
              //   let newUserRoles = JSON.parse(JSON.stringify(userRoles)); // deep clone
              //   newUserRoles[addedUser.userId] = {
              //     role: invitation.role,
              //     isApprover: !!invitation.isApprover
              //   };
              //   return resourceDocRef.update({
              //     sharingUsers,
              //     sharingUserIds,
              //     userRoles: newUserRoles
              //   });
              // } else {
              //   return resourceDocRef.update({
              //     sharingUsers,
              //     sharingUserIds
              //   });
              // }
            })
            .then((result) => {
              // console.log(result);
              // console.log("now adding (updating?) user");
              return this.props.firestore
                .doc(`users/${uid}`)
                .set(addedUser, { merge: true });
            })
            .then((result) => {
              // console.log(result);
              // console.log("now updating invitation to show handled");
              return invitationDocRef.update({ invitationHandled: true });
            })
            .catch((error) => {
              // console.log("catch 1");
              // console.log(error);
              this.setState({
                handlingToken: false,
                errors: { guest: error }
              });
            });
        } else {
          // console.log("invitation already handled");
          return; // invitation already handled
        }
      })
      .then((result) => {
        // console.log("signing in with custom token");
        // console.log(result);
        return this.props.firebase
          .auth()
          .setPersistence(this.props.firebase.auth.Auth.Persistence.SESSION);
      })
      .then(() => {
        // console.log("set persistence to session");
        return this.props.firebase.auth().signInWithCustomToken(customToken);
      })
      .then((userCredential) => {
        // Signed in?
        // console.log("got credential");
        // console.log(userCredential);
        this.setState({
          handlingToken: false,
          guestSigninSuccess: true
        });
        // console.log("state set");
        return;
      })
      .catch((error) => {
        // console.log("catch 2");
        // console.log(error);
        this.setState({ handlingToken: false, errors: { guest: error } });
      });
  }

  handleLogin = (event) => {
    this.setState({ processing: true });
    this.props.firebase
      .login({
        email: this.state.email,
        password: this.state.password
      })
      .then((result) => {
        // console.log(result);
        const method = result.user.additionalUserInfo.providerId;
        trackEvent(
          this.props.firebase,
          "Logged in",
          {
            method
          },
          "login"
        );
      })
      .catch((error) => {
        const errors = {};
        switch (error.code) {
          case "auth/invalid-email":
          case "auth/user-not-found":
            errors.email = "Enter a valid PingGo account email address";
            break;
          case "auth/wrong-password":
            errors.password = "Sorry, wrong password";
            break;
          case "auth/account-exists-with-different-credential":
            errors.email =
              "This email is linked to a different credential provider";
            break;
          default:
            errors.general = `Sorry, an error occurred (${error.code})`;
        }
        this.setState({ errors, processing: false });
      });
  };

  handleChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];

    this.setState({
      [event.target.name]: event.target.value,
      errors
    });
  };

  buttonDisabled = () => {
    const { email, password, errors, processing } = this.state;
    return !(
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      password &&
      Object.keys(errors).length === 0 &&
      !processing
    );
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  loginWithGoogle = () => {
    let isNewUser = true; // can also auto-signup from here
    this.props.firebase
      .login({ provider: "google", type: "popup" })
      .then((result) => {
        isNewUser = result.additionalUserInfo.isNewUser;
        return this.props.firestore.update(`users/${result.profile.userId}`, {
          persona: isNewUser ? "undecided" : null, // first use will ask for 'business' or 'agency'
          grandfathered: GRANDFATHERED_USERS.includes(result.profile.email)
        });
      })
      .then(() => {
        trackEvent(
          this.props.firebase,
          isNewUser ? "Signed up" : "Logged in",
          {
            method: "google"
          },
          isNewUser ? "sign_up" : "login"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { auth } = this.props;
    const { errors, processing, handlingToken } = this.state;

    if (!isLoaded(auth) || handlingToken) {
      return <Loader />;
    }

    return (
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        direction="column"
        spacing={6}>
        <Grid item>
          <Wrapper elevation={10}>
            <Container maxWidth="xs">
              <Helmet title="Login to PingGo" />
              {errors.guest && (
                <Alert mb={3} severity="error">
                  <Typography variant="h6">
                    There was a problem with your guest invitation
                  </Typography>
                  <Typography variant="body1">
                    Check that you've used the correct link as emailed to you.
                  </Typography>
                </Alert>
              )}
              <Alert mb={3} severity="info">
                <Typography variant="h6">Welcome to the new PingGo!</Typography>
                <Typography variant="body1">
                  To login to the previous version click{" "}
                  <a href="https://app.ping-go.com/login">here</a>
                </Typography>
              </Alert>
              <Grid container justifyContent="center">
                <img src={AppIcon} alt="foxy" width="250px" />
              </Grid>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                gutterBottom
                my={3}>
                Login to PingGo
              </Typography>
              <TextField
                mb={2}
                required
                autoFocus
                id="email"
                name="email"
                type="email"
                label="Email"
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                mb={2}
                required
                id="password"
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                label="Password"
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}>
                        {this.state.showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.general && (
                <Typography mb={2} variant="body2">
                  {errors.general}
                </Typography>
              )}
              <Grid container justifyContent="center">
                <Button
                  my={4}
                  variant="contained"
                  fullWidth
                  onClick={this.handleLogin}
                  color="primary"
                  disabled={this.buttonDisabled()}>
                  {processing ? <CircularProgress size={30} /> : "Log in"}
                </Button>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    size="small"
                    href="/auth/reset-password"
                    color="secondary">
                    Forgot Password?
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Need an account?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    pb={0.5}
                    size="small"
                    component={Link}
                    to="/auth/signup"
                    color="secondary">
                    Signup here
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Wrapper>
        </Grid>
        <Grid item container justifyContent="space-evenly" alignItems="center">
          <Grid item xs={5}>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              style={{ color: "#afafaf", textAlign: "center" }}>
              or
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <GoogleButton
              onClick={this.loginWithGoogle}
              type="light"
              label="Login with Google"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

export default compose(
  withFirebase,
  withFirestore,
  connect(mapStateToProps)
)(Login);
