import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Grid
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import autoId from "../util/autoId";
import isValidEmail from "../util/isValidEmail";

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

class ContactDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      errors: {},
      firstName: "",
      lastName: "",
      publication: "",
      jobTitle: "",
      email: "",
      phone: "",
      tags: [],
      tagsInput: ""
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOpen = (duplicatedContact = null) => {
    if (this.props.contact) {
      const { firstName, lastName, publication, jobTitle, email, phone, tags } =
        this.props.contact;
      this.setState({
        firstName,
        lastName,
        publication,
        jobTitle,
        email,
        phone,
        tags: tags ? tags.split(",") : [],
        tagsInput: "",
        error: null,
        open: true
      });
    } else {
      this.setState({
        error: null,
        firstName: "",
        lastName: "",
        publication: "",
        jobTitle: "",
        email: "",
        phone: "",
        tags: [],
        tagsInput: "",
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  formIsValid = () => {
    const errors = {};
    const { firstName, lastName, email } = this.state;
    if (!email || (email && !isValidEmail(email))) {
      errors.email = "A valid email address is required";
    }
    if (!firstName) {
      errors.firstName = "Enter a first name";
    }
    if (!lastName) {
      errors.lastName = "Enter a last name";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
    if (!this.formIsValid()) return;
    const { firstName, lastName, publication, jobTitle, email, phone, tags } =
      this.state;
    const contactDetails = {
      modifiedOn: new Date().getTime(),
      firstName,
      lastName,
      publication,
      jobTitle,
      email,
      phone,
      tags: tags.sort().join(",")
    };
    if (this.props.mode === "Add" || this.state.duplicated) {
      const newId = autoId();
      this.props.firestore
        .set(`teams/${this.props.teamId}/contacts/${newId}`, contactDetails)
        .then(() => {
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.firestore
        .update(
          `teams/${this.props.teamId}/contacts/${this.props.contactId}`,
          contactDetails
        )
        .then(() => {
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];
    this.setState({
      [event.target.name]: event.target.value,
      errors
    });
  };

  render() {
    const {
      errors,
      firstName,
      lastName,
      email,
      publication,
      jobTitle,
      phone,
      tags,
      tagsInput
    } = this.state;

    return (
      <Fragment>
        {this.props.mode === "Add" && (
          <Button
            variant="contained"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            onClick={this.handleOpen}>
            New contact
          </Button>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          // fullwidth="true"
          maxWidth="md">
          <DialogTitle>{this.props.mode} a contact</DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First name"
                  type="string"
                  required
                  fullWidth
                  margin="normal"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  value={firstName}
                  onChange={this.handleChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  type="string"
                  required
                  fullWidth
                  margin="normal"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  value={lastName}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <TextField
              id="email"
              name="email"
              type="string"
              required
              fullWidth
              margin="normal"
              label="Email address"
              error={!!errors.email}
              helperText={errors.email}
              value={email}
              onChange={this.handleChange}
            />
            <TextField
              id="publication"
              name="publication"
              type="string"
              fullWidth
              label="Publication"
              value={publication}
              onChange={this.handleChange}
            />
            <TextField
              id="jobTitle"
              name="jobTitle"
              type="string"
              fullWidth
              margin="normal"
              label="Job title"
              value={jobTitle}
              onChange={this.handleChange}
            />
            <TextField
              id="phone"
              name="phone"
              type="string"
              fullWidth
              margin="normal"
              label="Phone number"
              value={phone}
              onChange={this.handleChange}
            />
            <Autocomplete
              autoComplete
              multiple
              freeSolo
              autoSelect
              filterSelectedOptions
              id="toEmails"
              options={this.props.allTagsInUse}
              value={tags}
              inputValue={tagsInput}
              onChange={(event, newTags) => {
                this.setState({ tags: newTags });
              }}
              onInputChange={(event, newTagsInput) => {
                const options = newTagsInput.split(",");
                if (options.length > 1) {
                  this.setState({
                    tags: tags
                      .concat(options)
                      .map((x) => x.trim())
                      .filter((x) => x)
                  });
                } else {
                  this.setState({ tagsInput: newTagsInput });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Type a comma or 'enter' to separate multiple tags"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              color="primary">
              {this.props.mode === "Add" ? "Create" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(ContactDialog);
