import React, { Fragment, Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { spacing } from "@material-ui/system";
import { compose } from "recompose";
import {
  withFirestore,
  firestoreConnect,
  isLoaded
} from "react-redux-firebase";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../components/Loader";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Divider as MuiDivider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { KeyboardBackspace as BackIcon, DoubleArrow } from "@material-ui/icons";
import SaveStatusCloud from "../../components/SaveStatusCloud";
import QuestionDialog from "../../components/QuestionDialog";
import SECTORS from "../../util/sectors";
import CONTENT_TYPES from "../../util/contentTypes";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;
const DefaultIcon = styled(DoubleArrow)`
  padding-top: 5px;
`;
const Typography = styled(MuiTypography)(spacing);
const EditorWrapper = styled.div`
  padding-left: 6px;
  padding-right: 6px;
`;
const Preview = styled.div`
  h1 {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.2;
  }
  h2 {
    font-size: 0.95rem;
    font-weight: 700;
    line-height: 1.2;
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.2;
  }
`;

const STATUS_FRESH = "";
const STATUS_DIRTY = "Edited";
const STATUS_SAVED = "All changes saved";
const STATUS_SAVING = "Saving changes...";
const STATUS_ERROR = "Error saving changes, retrying...";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // expandedQuestion: "0",
      saveStatus: STATUS_FRESH,
      saveIntervalId: null,
      questions: [],
      sector: "none",
      title: "",
      text: "",
      description: "",
      isDefaultTemplate: false,
      contentType: Object.keys(CONTENT_TYPES)[0]
    };
  }

  componentDidMount() {
    this.props.firestore
      .get({
        collection: `teams/${this.props.match.params.teamId}/templates`,
        doc: this.props.match.params.templateId
      })
      .then(() => {
        const {
          title,
          text,
          isDefaultTemplate,
          sector,
          description,
          contentType
        } = this.props.template;
        this.setState({
          title,
          text,
          isDefaultTemplate: isDefaultTemplate || false,
          sector: sector || "none",
          description,
          contentType
        });
      })
      .catch((error) =>
        this.setState({
          error
        })
      );
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  allQuestions = () => {
    const pingGoQuestions = this.props.pingGoQuestions;
    const teamQuestions = this.props.teamQuestions;
    let allQuestions = {};
    for (const question of pingGoQuestions) {
      allQuestions[question.tag] = question;
    }
    // override library questions with custom ones
    for (const question of teamQuestions) {
      allQuestions[question.tag] = question;
    }
    return allQuestions;
  };

  transformText = () => {
    let rawText = this.state.text;
    if (!rawText) return "";
    const allQuestions = this.allQuestions();
    const teamMasterKeyMessages = this.props.team.keyAnswers || {};
    function replacer(match, p1, p2, p3, offset, string) {
      let thisQuestion = allQuestions[p1];
      if (!thisQuestion) {
        return "<span style='background-color:#fcc;'>#" + p1 + "</span>";
      }
      let masterKeyMessage = teamMasterKeyMessages[p1];
      let answer = masterKeyMessage
        ? masterKeyMessage
        : thisQuestion.placeholder;
      if (answer === "") answer = "???";
      if (thisQuestion.isKey) {
        return "<span style='background-color:#ccf;'>" + answer + "</span>";
      } else {
        return "<span style='background-color:#fc9;'>" + answer + "</span>";
      }
    }
    let newText = rawText.replace(/#(\w+)\b/gi, replacer);
    return newText;
  };

  firstInvalidTag = () => {
    const rawText = this.state.text;
    if (!rawText) return "";
    const allQuestions = this.allQuestions();
    const matches = rawText.matchAll(/#(\w+)\b/gi);
    for (let match of matches) {
      if (!allQuestions[match[1]]) {
        return match[1];
      }
    }
    return null;
  };

  handleChange = (event) => {
    if (this.state.saveIntervalId) {
      clearInterval(this.state.saveIntervalId);
    }
    const newInterval = setInterval(this.saveChanges, 1000);
    this.setState({
      [event.target.name]: event.target.value,
      saveStatus: STATUS_DIRTY,
      saveIntervalId: newInterval
    });
  };

  handleCheckboxChange = (event) => {
    if (this.state.saveIntervalId) {
      clearInterval(this.state.saveIntervalId);
    }
    const newInterval = setInterval(this.saveChanges, 1000);
    this.setState({
      [event.target.name]: event.target.checked,
      saveStatus: STATUS_DIRTY,
      saveIntervalId: newInterval
    });
  };

  fetchAutocomplete = (pattern) => {
    const matchedQuestions = Object.entries(this.allQuestions()).filter(
      ([tag, question]) => {
        return (
          question.tag.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
          question.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1
        );
      }
    );
    return new Promise((resolve) => {
      const results = matchedQuestions.map(([tag, question]) => {
        return {
          value: tag,
          text: "#" + tag + " " + question.text,
          icon: ""
        };
      });
      resolve(results);
    });
  };

  handleEditorInit = (value, editor) => {
    editor.ui.registry.addAutocompleter("questions", {
      ch: "#",
      minChars: 1,
      columns: 1,
      fetch: this.fetchAutocomplete,
      onAction: function (autocompleteApi, rng, value) {
        editor.selection.setRng(rng);
        editor.insertContent("#" + value);
        autocompleteApi.hide();
      }
    });
  };

  handleEditorChange = (content, editor) => {
    if (this.state.saveIntervalId) {
      clearInterval(this.state.saveIntervalId);
    }
    const newInterval = setInterval(this.saveChanges, 1000);
    this.setState({
      text: content,
      saveStatus: STATUS_DIRTY,
      saveIntervalId: newInterval
    });
  };

  saveChanges = (andExit = false) => {
    clearInterval(this.state.saveIntervalId);
    if (this.state.saveStatus === STATUS_SAVED && andExit) {
      this.props.history.push(`/teams/${this.props.match.params.teamId}`);
    }
    this.setState({
      saveStatus: STATUS_SAVING
    });
    const { title, text, isDefaultTemplate, description, sector, contentType } =
      this.state;
    this.props.firestore
      .update(
        {
          collection: `teams/${this.props.match.params.teamId}/templates`,
          doc: this.props.match.params.templateId
        },
        {
          title,
          text,
          isDefaultTemplate,
          description,
          sector,
          contentType,
          modifiedOn: new Date().getTime(),
          stage: "create"
        }
      )
      .then(() => {
        this.setState({
          saveStatus: STATUS_SAVED
        });
        if (andExit)
          this.props.history.push(`/teams/${this.props.match.params.teamId}/2`);
      })
      .catch((err) => {
        const retryInterval = setInterval(this.saveChanges(), 3000);
        this.setState({
          saveStatus: STATUS_ERROR,
          saveIntervalId: retryInterval
        });
        console.log(err);
      });
  };

  handleBack = () => {
    this.saveChanges(true);
  };

  render() {
    const { saveStatus, title, isDefaultTemplate } = this.state;
    const { template, templates, teamQuestions, pingGoQuestions, team } =
      this.props;
    if (!isLoaded(templates, teamQuestions, pingGoQuestions, team)) {
      return <Loader />;
    }
    return (
      <Fragment>
        <Helmet title="Press release template" />
        <div className="flex-no-shrink">
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            spacing={6}
            alignItems="center">
            <Grid item container alignItems="center" wrap="nowrap">
              <Grid mr={2}>
                <BackIcon onClick={this.handleBack} />{" "}
              </Grid>
              <Grid>
                <Typography variant="h4" gutterBottom display="inline">
                  {title}
                </Typography>
                {template && isDefaultTemplate && (
                  <DefaultIcon color="secondary" fontSize="small" />
                )}{" "}
              </Grid>
              <Grid ml={2}>
                <SaveStatusCloud saveStatus={saveStatus} />
              </Grid>
            </Grid>
            <Grid item>
              <QuestionDialog
                teamId={this.props.match.params.teamId}
                mode="Add"
                tag={this.firstInvalidTag()}
              />
            </Grid>
          </Grid>
        </div>
        <Divider mb={3} />
        <Grid container className="flex-section">
          <Grid item xs={6} className="flex-col-scroll-left">
            <Card m={3}>
              <EditorWrapper>
                <TextField
                  id="title"
                  name="title"
                  type="string"
                  required
                  fullWidth
                  margin="normal"
                  label="Template Title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />

                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="contentTypeSelectLabel">Type</InputLabel>
                      <Select
                        my={4}
                        id="contentType"
                        name="contentType"
                        labelId="contentTypeSelectLabel"
                        autoWidth
                        inputProps={{ fullwidth: "true" }}
                        value={this.state.contentType}
                        onChange={this.handleChange}>
                        {Object.entries(CONTENT_TYPES).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="sectorSelectLabel">Sector</InputLabel>
                      <Select
                        my={4}
                        id="sector"
                        name="sector"
                        labelId="sectorSelectLabel"
                        autoWidth
                        inputProps={{ fullwidth: "true" }}
                        value={this.state.sector}
                        onChange={this.handleChange}>
                        <MenuItem value="none">No sector</MenuItem>
                        {SECTORS.map((sector) => (
                          <MenuItem key={sector} value={sector}>
                            {sector}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(this.state.isDefaultTemplate)}
                          onChange={this.handleCheckboxChange}
                          name="isDefaultTemplate"
                          color="primary"
                        />
                      }
                      label="Quick Access template"
                    />
                  </Grid> */}
                </Grid>
                <TextField
                  id="description"
                  name="description"
                  type="string"
                  multiline
                  required
                  fullWidth
                  margin="normal"
                  // minRows={3}
                  label="Template Description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
                <Editor
                  apiKey="2k7e29hsgl6xshcvy56o3b0i4icfr7p2yqhjeka6xgix08ai"
                  init={{
                    menubar: false,
                    statusbar: false,
                    content_css: "/static/css/editor.css",
                    block_formats:
                      "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                    plugins: [
                      "autolink lists link autoresize",
                      "searchreplace",
                      "paste help wordcount"
                    ],
                    toolbar:
                      // eslint-disable-next-line no-multi-str
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help"
                  }}
                  value={this.state.text}
                  onEditorChange={this.handleEditorChange}
                  onInit={this.handleEditorInit}
                />
              </EditorWrapper>
            </Card>
          </Grid>
          <Grid item xs={6} className="flex-col-scroll-right">
            <Card m={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Preview
                </Typography>
                <Preview
                  dangerouslySetInnerHTML={{
                    __html: this.transformText()
                  }}></Preview>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Template.propTypes = {
  match: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const teamId = ownProps.match.params.teamId;
  const templateId = ownProps.match.params.templateId;
  const templates = state.firestore.data[`teams/${teamId}/templates`];
  const teams = state.firestore.data.teams;
  return {
    profile: state.firebase.profile,
    templates,
    template: templates ? templates[templateId] : {},
    pingGoQuestions:
      state.firestore.ordered["teams/R7Z1iCDHpvS73plmK1iP/questions"],
    teamQuestions: state.firestore.ordered[`teams/${teamId}/questions`],
    team: teams ? teams[teamId] : {}
  };
}

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "teams/R7Z1iCDHpvS73plmK1iP/questions"
    },
    {
      collection: `teams/${props.match.params.teamId}/questions`
    },
    {
      collection: "teams",
      doc: props.match.params.teamId
    }
  ]),
  connect(mapStateToProps)
)(Template);
