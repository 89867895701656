import React, { useState } from "react";
import { withFirebase } from "react-redux-firebase";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import initials from "../util/initials";
import stc from "string-to-color";
import { formatDistanceToNow } from "date-fns";
import {
  Avatar,
  Chip as MuiChip,
  Container,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  LinearProgress,
  FormControlLabel as MuiFormControlLabel,
  Switch
} from "@material-ui/core";
import ManageDocumentMenu from "./ManageDocumentMenu";
import DueDate from "./DueDate";
// import { CheckCircle } from "@material-ui/icons";

const TableWrapper = styled(Container)`
  padding: 0;
  overflow-y: auto;
  // max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;
const FormControlLabel = styled(MuiFormControlLabel)`
  float: right;
`;
const EllipsisWrapper = styled.div`
  // overflow: hidden;
  // text-overflow: ellipsis;
  width: 40vw;
`;
const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;
const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.stage === "create" && "#3377F6"};
  background: ${(props) => props.stage === "review" && "#F19436"};
  background: ${(props) => props.stage === "signoff" && "#DC695F"};
  background: ${(props) => props.stage === "share" && "#64AD5F"};
  color: ${(props) => props.stage && props.theme.palette.common.white};
`;
const Progress = styled(LinearProgress)`
  width: 50px;
  height: 10px;
`;
const SmallAvatar = styled(Avatar)`
  height: 25px;
  width: 25px;
  font-size: 0.75rem;
`;
// const CheckIcon = styled(CheckCircle)`
//   width: 20px;
//   height: 20px;
//   color: ${(props) => (props.approved === "true" ? "#3bc300" : "#dedede")};
// `;
const MAX_DATE = 8640000000000000;

const STAGE_LABEL_FOR_STAGE = {
  create: "create",
  review: "edit",
  signoff: "sign-off",
  share: "send"
};

function DocumentTable({ documents, history, profile }) {
  const [showArchived, setShowArchived] = useState(false);

  const unarchivedDocuments = documents.filter(
    (document) => !document.isArchived
  );
  const showArchivedSwitch = unarchivedDocuments.length !== documents.length;
  const visibleDocuments = showArchived ? [...documents] : unarchivedDocuments;

  const sortedDocuments = visibleDocuments.sort((a, b) => {
    return a.dueDate === b.dueDate
      ? a.title.localeCompare(b.title)
      : (a.dueDate ? a.dueDate : MAX_DATE) - (b.dueDate ? b.dueDate : MAX_DATE);
  });

  return (
    <TableWrapper>
      {showArchivedSwitch && (
        <FormControlLabel
          control={
            <Switch
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              name="showArchived"
              color="primary"
            />
          }
          label="Show archived"
        />
      )}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">Owner</TableCell>
              <TableCell align="center">Stage</TableCell>
              <TableCell align="center">Progress</TableCell>
              <TableCell align="center">Release date</TableCell>
              <Hidden mdDown>
                <TableCell align="center">Last updated</TableCell>
              </Hidden>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDocuments.map((row) => (
              <TableRow
                key={row.id}
                style={{
                  backgroundColor: row.isArchived ? "#eee" : "#fff"
                }}>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() =>
                    history.push(
                      `/workspaces/${row.workspaceId}/documents/${row.id}/${row.stage}`
                    )
                  }
                  style={{ cursor: "pointer" }}>
                  <EllipsisWrapper>
                    <Typography noWrap={true} variant="body1">
                      {row.title}
                      {/* {row.approverUserIds.includes(profile.userId) && (
                        <CheckIcon
                          approved={
                            row.approvedUserIds
                              .includes(profile.userId)
                              .toString() // can't pass boolean?
                          }
                        />
                      )} */}
                    </Typography>
                  </EllipsisWrapper>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={`Owner: ${row.ownerUser.name}`} arrow>
                    <SmallAvatar
                      alt={row.ownerUser.name}
                      src={row.ownerUser.photo}
                      style={{
                        backgroundColor: stc(row.ownerUser.name)
                      }}>
                      {initials(row.ownerUser.name)}
                    </SmallAvatar>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Chip
                    size="small"
                    pb={0.5}
                    mr={1}
                    mb={1}
                    label={STAGE_LABEL_FOR_STAGE[row.stage]}
                    stage={row.stage}
                  />
                </TableCell>
                <TableCell align="center">
                  <Progress
                    variant="determinate"
                    value={
                      row.stageTarget === 0 // for example a completed press pack
                        ? 100
                        : isNaN(row.stageProgress / row.stageTarget)
                        ? 0 // can this happen? if either are undefined/null?
                        : (row.stageProgress / row.stageTarget) * 100
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <Typography noWrap={true} variant="body2">
                    <DueDate dueDate={row.dueDate} />
                  </Typography>
                </TableCell>
                <Hidden mdDown>
                  <TableCell align="center">
                    <Typography noWrap={true} variant="body2">
                      {formatDistanceToNow(row.modifiedOn)} ago
                    </Typography>
                  </TableCell>
                </Hidden>
                <TableCell align="center">
                  {profile.userId === row.ownerUserId && (
                    <ManageDocumentMenu
                      documentId={row.id}
                      document={row}
                      verticalIcon
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </TableWrapper>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    profile: state.firebase.profile
  };
}
export default compose(withFirebase, connect(mapStateToProps))(DocumentTable);
