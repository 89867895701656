const reviewDocSteps = [
  {
    target: "body",
    placement: "center",
    title: "Edit & review",
    content: `Here's where the content is crafted and tweaked, using the template and your answers as
    a starting point.`
  },
  {
    target: ".tour-document",
    placement: "auto",
    title: "The content",
    content: `If you're the author of the content, you can make any changes here to refine
    your text or add additional material. Others from your workspace can collaborate with you
    on it in real time if they're viewing it as well.`
  },
  {
    target: ".tour-manageUser",
    placement: "auto",
    title: "Reviewers",
    content: `You can invite others to review and approve your content. These should be internal
    reviewers, the idea being that by the time you move on to the sign-off stage, it's in good
    enough shape for clients to see for the first time.`
  },
  {
    target: ".tour-next",
    placement: "left",
    title: "Moving on",
    content: `When you're happy with your content, and everyone on the Reviewers list has
    provided their approval, you can move it to the Sign-off stage where you can
    invite others (like the client) in to finally sign it off. You can still make further changes at that stage 
    in response to their feedback before it finally gets distributed.`
  },
  {
    target: ".tour-approve",
    placement: "left",
    title: "Approval",
    content: `As an reviewer, you need to click here once you're happy with the content. The author
    will be notified and, when all the reviewers in the list have approved, the content can be moved
    on to the Sign-off stage. You can also retract your approval, and approve later, if you want.`
  },
  {
    target: ".tour-messages",
    placement: "left",
    title: "Chat Messages",
    content: `You can chat about the content with other members of your workspace. If you're
    the author, you'll get a notification if others add a message. There's also chat in the next step, 
    the Sign-off step, but none of these messages are displayed there.`
  }
];

export default reviewDocSteps;
