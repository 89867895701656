import React from "react";
import styled from "styled-components";
import {
  CloudOutlined,
  CloudUploadOutlined,
  CloudDoneOutlined,
  CloudOffOutlined
} from "@material-ui/icons";
import { Grid, Hidden, Tooltip, Typography } from "@material-ui/core";

const STATUS_FRESH = "No changes to save";
const STATUS_DIRTY = "Edited";
const STATUS_SAVED = "All changes saved";
const STATUS_SAVING = "Saving changes...";
const STATUS_ERROR = "Error saving changes, retrying...";

const IconWrapper = styled.div`
  padding-top: 5px;
`;

function renderCloudIcon(saveStatus) {
  switch (saveStatus) {
    case STATUS_DIRTY:
      return <CloudOutlined color="secondary" />;
    case STATUS_FRESH:
    case STATUS_SAVED:
      return <CloudDoneOutlined color="secondary" />;
    case STATUS_SAVING:
      return <CloudUploadOutlined color="secondary" />;
    case STATUS_ERROR:
      return <CloudOffOutlined color="secondary" />;
    default:
      return <CloudOutlined color="secondary" />;
  }
}

function SaveStatusCloud({ saveStatus }) {
  return (
    <Tooltip title={saveStatus} aria-label={saveStatus}>
      <Grid container alignItems="center" wrap="nowrap" spacing={2}>
        <Grid item>
          <IconWrapper>{renderCloudIcon(saveStatus)}</IconWrapper>
        </Grid>
        <Hidden mdDown>
          <Grid item>
            <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
              {saveStatus}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
    </Tooltip>
  );
}

export default SaveStatusCloud;
