import React, { Component } from "react";
import styled from "styled-components";
import {
  Container as MuiContainer,
  Typography as MuiTypography,
  Paper,
  Table,
  TableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import KeyMessageDialog from "./KeyMessageDialog";

const Typography = styled(MuiTypography)(spacing);
const Container = styled(MuiContainer)(spacing);
const TableWrapper = styled(Container)`
  padding: 0;
  overflow-y: auto;
  // max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;
const TableRow = styled(MuiTableRow)`
  // cursor: pointer;
`;
const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;
export class KeyMessageTable extends Component {
  render() {
    const {
      keyAnswers,
      keyQuestionMap,
      updateKeyAnswers,
      overrideAnswers,
      canEdit = true
    } = this.props;
    // console.log(overrideAnswers);
    return (
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tag</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Key message</TableCell>
                {canEdit && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(keyAnswers).map(([tag, answer]) => (
                <TableRow
                  key={tag}
                  style={
                    overrideAnswers && overrideAnswers[tag]
                      ? { textDecoration: "line-through" }
                      : {}
                  }>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">#{tag}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      {keyQuestionMap[tag]
                        ? keyQuestionMap[tag].text
                        : "Invalid question: " + tag}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{answer}</Typography>
                  </TableCell>
                  {canEdit && (
                    <TableCell align="right">
                      <KeyMessageDialog
                        tag={tag}
                        answer={answer}
                        keyQuestionMap={keyQuestionMap}
                        updateKeyAnswers={updateKeyAnswers}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    );
  }
}

export default KeyMessageTable;
