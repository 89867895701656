import React, { Component, Fragment } from "react";
import {
  firestoreConnect,
  withFirestore,
  isLoaded,
} from "react-redux-firebase";
import styled from "styled-components";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";

import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  Typography,
  Divider as MuiDivider,
  Chip,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Description as GenericIcon } from "@material-ui/icons";
import Loader from "../../components/Loader";
import { spacing } from "@material-ui/system";

import autoId from "../../util/autoId";
import canAccessPage from "../../util/canAccessPage";
import DocumentHeader from "../../components/DocumentHeader";
import Tour from "../../components/Tour";
// import PRICING_PLANS from "../../util/pricingPlans";
import trackEvent from "../../util/trackEvent";
import CONTENT_TYPES from "../../util/contentTypes";
import prefaceForDoc from "../../util/prefaceForDoc";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Card = styled(MuiCard)`
  margin: 10px 10px;
`;
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;
const Divider = styled(MuiDivider)(spacing);

const ListItem = styled(MuiListItem)`
  border: 1px solid LightGray;
  margin-bottom: 16px;
  cursor: pointer;
  background: white;
  &:hover {
    background-color: #ebebeb;
  }
`;
const PrefaceWrapper = styled.div`
  margin: 12px 0 0 0;
`;
const Preview = styled.div`
  h1 {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.2;
  }
  h2 {
    font-size: 0.95rem;
    font-weight: 700;
    line-height: 1.2;
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.2;
  }
`;

class StartDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyAnswers: [],
      selectedTemplate: null, //this.availableTemplates()[0],
      teamTemplates: {},
      teamQuestions: {},
      teamKeyAnswers: {},
      error: null,
    };
  }

  componentDidMount() {
    // directly query for team templates and questions
    // (this can't come from redux as we don't know the teamId from the props)
    this.props.firestore
      .collection(`workspaces`)
      .doc(this.props.match.params.workspaceId)
      .get()
      .then((doc) => {
        const { teamId, teamName } = doc.data();
        // not the PingGo team, and don't add templates if not in plan
        if (
          teamId &&
          teamId !== "R7Z1iCDHpvS73plmK1iP"
          // && PRICING_PLANS[plan || 0].can_use_custom_templates
        ) {
          this.props.firestore
            .collection(`teams/${teamId}/templates`)
            .get()
            .then((snapshot) => {
              const teamTemplates = {};
              snapshot.forEach((template) => {
                teamTemplates[template.id] = template.data();
                teamTemplates[template.id].teamName = teamName;
              });
              this.setState({
                teamTemplates,
              });
            });
          this.props.firestore
            .collection(`teams/${teamId}/questions`)
            .get()
            .then((snapshot) => {
              const teamQuestions = {};
              snapshot.forEach((question) => {
                teamQuestions[question.id] = question.data();
              });
              this.setState({
                teamQuestions,
              });
            })
            .then(() => {
              let teamKeyAnswers = {};
              this.props.firestore
                .collection(`teams`)
                .doc(teamId)
                .get()
                .then((result) => {
                  teamKeyAnswers = result.data().keyAnswers;
                })
                .then(() => {
                  this.props.firestore
                    .collection(`workspaces`)
                    .doc(this.props.match.params.workspaceId)
                    .get()
                    .then((result) => {
                      const workspaceKeyAnswers = result.data().keyAnswers;
                      this.setState({
                        // team answers override workspace ones
                        keyAnswers: Object.assign(
                          {},
                          workspaceKeyAnswers,
                          teamKeyAnswers
                        ),
                      });
                    })
                    .catch((error) =>
                      this.setState({
                        error,
                      })
                    );
                });
            });
        }
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleStartCreate = () => {
    const workspaceId = this.props.match.params.workspaceId;
    const template =
      this.props.pingGoTemplates[this.state.selectedTemplate] ||
      this.state.teamTemplates[this.state.selectedTemplate];
    const { name, email, photo, userId } = this.props.profile;
    const hasNoTags = !template.text.includes("#");
    const nextStage = hasNoTags ? "review" : "create";
    const documentDetails = {
      workspaceId,
      title: "Untitled", // in case our redirect is interrupted
      stage: nextStage,
      text: template.text, // not really necessary - we use templateText in Create from v2.0.14
      templateText: template.text,
      contentType: template.contentType,
      modifiedOn: new Date().getTime(),
      ownerUser: {
        name,
        email,
        photo,
        userId,
      },
      ownerUserId: userId,
      stageProgress: 0,
      stageTarget: 0,
      sharingUserIds: [],
      sharingUsers: [],
      approverUserIds: [],
      approvedUserIds: [],
      userRoles: {},
    };
    const newId = autoId();
    this.props.firestore
      .set(`workspaces/${workspaceId}/documents/${newId}`, documentDetails)
      .then(() => {
        trackEvent(this.props.firebase, "Created content from a template", {
          title: template.title,
        });
        this.props.history.push(
          `/workspaces/${workspaceId}/documents/${newId}/${nextStage}`
        );
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleBack = (backPath) => {
    this.props.history.push(backPath);
  };

  currentTemplate = () =>
    this.props.pingGoTemplates[this.state.selectedTemplate] ||
    this.state.teamTemplates[this.state.selectedTemplate];

  transformText = () => {
    const template = this.currentTemplate();
    if (!template) return "";
    let rawText = template.text;
    // if (!rawText) return "";
    let allQuestions = {};
    const pingGoQuestions = this.props.pingGoQuestions;
    for (const questionId in pingGoQuestions) {
      const question = pingGoQuestions[questionId];
      allQuestions[question.tag] = question;
    } // override library questions with custom ones
    const teamQuestions = this.state.teamQuestions;
    for (const questionId in teamQuestions) {
      const question = teamQuestions[questionId];
      allQuestions[question.tag] = question;
    }
    const answers = this.state.keyAnswers;
    function replacer(match, p1, p2, p3, offset, string) {
      let thisQuestion = allQuestions[p1];

      if (!thisQuestion) {
        return "<span style='background-color:#fcc;'>#" + p1 + "</span>";
      }
      // use an existing Key Answer where available, otherwise a placeholder or ???
      let answer = answers[p1]
        ? answers[p1]
        : thisQuestion.placeholder || "???";
      //   if (answer === "") answer = "???";
      if (thisQuestion.isKey) {
        return "<span style='background-color:#ccf;'>" + answer + "</span>";
      } else {
        return "<span style='background-color:#fc9;'>" + answer + "</span>";
      }
    }
    let newStr = rawText.replace(/#(\w+)\b/gi, replacer);
    return newStr;
  };

  prefaceForTemplate = () => {
    const currentTemplate = this.currentTemplate();
    if (!currentTemplate) return "";
    return prefaceForDoc(currentTemplate);
  };

  availableTemplates() {
    const templates = Object.entries(this.state.teamTemplates)
      .concat(Object.entries(this.props.pingGoTemplates))
      .filter(
        ([key, template]) =>
          template.sector === this.props.workspace.sector &&
          CONTENT_TYPES[template.contentType].category ===
            this.props.match.params.category
      )
      .sort(([keyA, templateA], [keyB, templateB]) =>
        templateA.title.localeCompare(templateB.title)
      );
    return templates;
  }

  render() {
    const { profile, auth, workspace, match } = this.props;

    if (
      !isLoaded(
        this.props.pingGoTemplates,
        this.props.pingGoQuestions,
        workspace,
        profile
      )
    ) {
      return <Loader />;
    }

    if (!canAccessPage(auth, undefined, workspace)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: {
              from: { pathname: this.props.history.location.pathname },
            },
          }}
        />
      );
    }

    return (
      <Fragment>
        <Helmet title="Select a content template" />
        <div className="flex-no-shrink">
          <DocumentHeader
            userId={profile.userId}
            workspace={workspace}
            workspaceId={match.params.workspaceId}
            handleBack={this.handleBack}
            activeStep={0}
          />
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            spacing={6}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4" gutterBottom display="inline">
                Choose a template
              </Typography>
            </Grid>
            <Grid item>
              <Tour tourId={match.path} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleStartCreate}
                disabled={!this.state.selectedTemplate}
              >
                Next step
              </Button>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Grid container className="flex-section">
          <Grid item xs={6} className="flex-col-scroll-left">
            <List>
              {this.availableTemplates().map(([key, template]) => (
                <ListItem
                  key={key}
                  selected={this.state.selectedTemplate === key}
                  onClick={() => this.setState({ selectedTemplate: key })}
                >
                  <ListItemIcon>
                    <GenericIcon fontSize="large" color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">{template.title}</Typography>
                    }
                    secondary={template.description}
                  />
                  {template.teamName && (
                    <Chip
                      label={template.teamName}
                      variant="outlined"
                      size="small"
                      color="primary"
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} className="flex-col-scroll-right">
            <Card m={3}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Preview
                </Typography>
                <PrefaceWrapper
                  dangerouslySetInnerHTML={{
                    __html: this.prefaceForTemplate(),
                  }}
                ></PrefaceWrapper>
                <Preview
                  dangerouslySetInnerHTML={{
                    __html: this.transformText(),
                  }}
                ></Preview>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const workspaceId = ownProps.match.params.workspaceId;
  const workspaces = state.firestore.data.workspaces;

  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    workspaces,
    workspace: workspaces
      ? state.firestore.data[`workspaces`][workspaceId]
      : {},
    pingGoTemplates:
      state.firestore.data["teams/R7Z1iCDHpvS73plmK1iP/templates"],
    pingGoQuestions:
      state.firestore.data["teams/R7Z1iCDHpvS73plmK1iP/questions"],
  };
};

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "workspaces",
      doc: props.match.params.workspaceId,
    },
    {
      collection: "teams/R7Z1iCDHpvS73plmK1iP/templates",
    },
    {
      collection: "teams/R7Z1iCDHpvS73plmK1iP/questions",
    },
  ]),
  connect(mapStateToProps)
)(StartDoc);
