import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import {
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MuiTextField
} from "@material-ui/core";

import DataTable from "react-data-table-component";
import ManageContactMenu from "./ManageContactMenu";
import { Autocomplete } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";

const MarginChip = styled(Chip)`
  margin: 1px;
`;
const TextField = styled(MuiTextField)(spacing);

function ContactTable({
  contacts,
  teamId,
  allTagsInUse,
  selectMode,
  handleSelectionChange,
  disabledRowCriteria
}) {
  const [tagFilter, setTagFilter] = useState([]);
  const [textFilter, setTextFilter] = useState("");

  const filteredContacts =
    tagFilter.length === 0 && textFilter === ""
      ? contacts
      : contacts
          .filter(
            (contact) =>
              tagFilter.length === 0 ||
              (contact.tags &&
                contact.tags !== "" &&
                tagFilter.every((tag) => contact.tags.split(",").includes(tag)))
          )
          .filter(
            (contact) =>
              contact.publication
                .toLowerCase()
                .includes(textFilter.toLowerCase()) ||
              contact.firstName
                .toLowerCase()
                .includes(textFilter.toLowerCase()) ||
              contact.lastName
                .toLowerCase()
                .includes(textFilter.toLowerCase()) ||
              contact.jobTitle
                .toLowerCase()
                .includes(textFilter.toLowerCase()) ||
              contact.email.toLowerCase().includes(textFilter.toLowerCase())
          );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <TextField
          mr={3}
          margin="dense"
          id="textFilter"
          name="textFilter"
          variant="standard"
          label="Search"
          style={{ width: "300px" }}
          value={textFilter}
          onChange={(event) => setTextFilter(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setTextFilter("")}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Autocomplete
          id="tagFilter"
          multiple
          filterSelectedOptions
          options={allTagsInUse}
          value={tagFilter}
          onChange={(event, newTags) => setTagFilter(newTags)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              margin="dense"
              label="Filter by Tag"
              style={{ width: "300px" }}
            />
          )}
        />
      </>
    );
  }, [tagFilter, allTagsInUse, textFilter]);

  const columns = [
    {
      id: "publication",
      name: "Publication",
      selector: (row) => row.publication,
      sortable: true,
      wrap: true
    },
    {
      id: "firstName",
      name: "First name",
      selector: (row) => row.firstName,
      sortable: true
    },
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true
    },
    {
      id: "jobTitle",
      name: "Job Title",
      selector: (row) => row.jobTitle,
      sortable: true,
      wrap: true
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true
    },
    {
      id: "phone",
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true
    },
    {
      id: "tags",
      name: "Tags",
      selector: (row) => row.tags,
      cell: (row, index, column, id) => (
        <div style={{ whiteSpace: "normal" }}>
          {row.tags &&
            row.tags
              .split(",")
              .map((tag) => (
                <MarginChip
                  key={tag}
                  component="span"
                  size="small"
                  label={tag}
                />
              ))}
        </div>
      ),
      sortable: true,
      style: { "white-space": "unset" }
    }
  ];
  if (!selectMode)
    columns.push({
      name: "Action",
      cell: (row, index, column, id) => (
        <ManageContactMenu
          contact={row}
          contactId={row.id}
          teamId={teamId}
          allTagsInUse={allTagsInUse}
        />
      ),
      button: true
    });

  const customStyles = {
    subHeader: {
      style: {
        minHeight: "40px"
      }
    },
    rows: {
      style: {
        fontSize: "15px"
      }
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold"
      }
    }
  };

  return (
    <Paper>
      <DataTable
        columns={columns}
        data={filteredContacts}
        defaultSortFieldId="publication"
        selectableRows={selectMode}
        onSelectedRowsChange={handleSelectionChange}
        selectableRowDisabled={disabledRowCriteria}
        selectableRowsHighlight
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={customStyles}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[50, 100, 250, 1000]}
        noDataComponent={
          <div style={{ padding: "24px" }}>
            There are no contacts to display
          </div>
        }
      />
    </Paper>
  );
}

export default ContactTable;
