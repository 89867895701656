import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import { spacing } from "@material-ui/system";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { startOfDay, endOfDay, format } from "date-fns";
import trackEvent from "../util/trackEvent";
import prefaceForDoc from "../util/prefaceForDoc";

const Button = styled(MuiButton)(spacing);
const PrefaceWrapper = styled.div`
  margin: 12px 0 0 12px;
`;

class DocumentAttributesDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      dueDate: null,
      embargoDateTime: null,
      errors: {},
      open: false,
      error: null
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      dueDate: this.props.document.dueDate
        ? new Date(this.props.document.dueDate)
        : null,
      embargoDateTime: this.props.document.embargoDateTime
        ? new Date(this.props.document.embargoDateTime)
        : null
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.parentClose) this.props.parentClose();
  };

  handleSubmit = () => {
    const dueDate = this.state.dueDate
      ? endOfDay(this.state.dueDate).getTime()
      : null;
    const embargoDateTime = this.state.embargoDateTime
      ? this.state.embargoDateTime.getTime()
      : null;
    this.props.firestore
      .update(
        `workspaces/${this.props.document.workspaceId}/documents/${this.props.documentId}`,
        { dueDate, embargoDateTime }
      )
      .then(() => {
        trackEvent(
          this.props.firebase,
          this.props.document.dueDate
            ? "Set document attributes"
            : "Changed document attributes"
        );
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (dueDate) => {
    this.setState({
      dueDate
    });
  };

  handleEmbargoChange = (embargoDateTime) => {
    this.setState({
      embargoDateTime
    });
  };
  render() {
    const { mode, document } = this.props;

    return (
      <>
        {mode === "button" && (
          <Button
            variant="outlined"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            endIcon={<Edit />}
            onClick={this.handleOpen}>
            {/* <Hidden lgDown>Due date </Hidden> */}
            {document.dueDate
              ? format(document.dueDate, "d MMM y")
              : "No date set"}
          </Button>
        )}
        {mode === "preface" && (
          <PrefaceWrapper
            onClick={this.handleOpen}
            dangerouslySetInnerHTML={{
              __html: prefaceForDoc(document)
            }}></PrefaceWrapper>
        )}
        {/* {mode === "menuItem" && (
          <MenuItem variant="text" onClick={this.handleOpen}>
            Release and embargo dates
          </MenuItem>
        )} */}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="xs">
          <DialogTitle>Release and Embargo Dates</DialogTitle>
          <DialogContent>
            <DatePicker
              value={this.state.dueDate}
              onAccept={this.handleDateChange}
              onChange={() => null}
              clearable
              autoOk
              label="Release date"
              format="dd/MM/yyyy"
              emptyLabel="No release date"
              okLabel="Save"
              clearLabel="Clear release date"
            />
            <DateTimePicker
              value={this.state.embargoDateTime}
              onAccept={this.handleEmbargoChange}
              onChange={() => null}
              initialFocusedDate={startOfDay(this.state.dueDate)}
              ampm={false}
              clearable
              autoOk
              label="Embargo date and time"
              format="dd/MM/yyyy hh:mm"
              emptyLabel="No embargo"
              okLabel="Save"
              clearLabel="Remove embargo"
            />
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(DocumentAttributesDialog);
