import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography as MuiTypography,
  TextField as MuiTextField,
  IconButton as MuiIconButton
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Edit } from "@material-ui/icons";

import trackEvent from "../util/trackEvent";

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const EditIcon = styled(Edit)`
  margin: 4px;
  padding: 0;
  height: 16px;
  width: 16px;
`;
const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

class DistributionNoteDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      noteText: "",
      error: null
    };
  }

  handleOpen = () => {
    this.setState({
      noteText: this.props.noteText || "",
      error: null,
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSubmit = () => {
    const { toEmail } = this.props;
    const authorEmail = this.props.profile.email;
    const distributionNotes = {
      ...this.props.document.distributionNotes
    };
    const newNote = {
      authorEmail,
      noteText: this.state.noteText,
      sentOn: new Date().getTime() // so it's sortable with emails
    };
    if (distributionNotes[toEmail]) {
      distributionNotes[toEmail] = distributionNotes[toEmail].concat(newNote);
    } else {
      distributionNotes[toEmail] = [newNote];
    }
    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.workspaceId}/documents`,
          doc: this.props.documentId
        },
        {
          distributionNotes
        }
      )
      .then((result) => {
        trackEvent(this.props.firebase, "Added a distribution note");
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <>
        {this.props.mode === "Add" ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ whiteSpace: "nowrap" }}
            onClick={this.handleOpen}>
            New Note
          </Button>
        ) : (
          <IconButton onClick={this.handleOpen} size="small">
            <EditIcon color="secondary" />
          </IconButton>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm">
          <DialogTitle>
            {this.props.mode === "Add"
              ? "Add a Distribution Note"
              : "Edit a Distribution Note"}
          </DialogTitle>
          <DialogContent>
            <TextField
              id="noteText"
              name="noteText"
              type="string"
              multiline
              margin="normal"
              required
              autoFocus
              fullWidth
              label="Note"
              placeholder="Enter a note"
              value={this.state.noteText}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              disabled={this.state.noteText.length === 0}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(DistributionNoteDialog);
