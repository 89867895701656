import React, { Component } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import Helmet from "react-helmet";
import WorkspaceDialog from "../../components/WorkspaceDialog";
import WorkspaceCard from "../../components/WorkspaceCard";
import BlankWorkspacesImage from "../../assets/blank-workspaces.png";

import {
  Container as MuiContainer,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  Switch,
  Typography as MuiTypography
} from "@material-ui/core";
import Loader from "../../components/Loader";
import { spacing } from "@material-ui/system";
import AppSumoFirstVisitDialog from "../../components/AppSumoFirstVisitDialog";
import DecidePersonaDialog from "../../components/DecidePersonaDialog";
import CreateTeamDialog from "../../components/CreateTeamDialog";

import TermsDialog from "../../components/TermsDialog";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Container = styled(MuiContainer)(spacing);

class Workspaces extends Component {
  constructor(props) {
    super();
    this.state = {
      showArchived: false
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  render() {
    const { myWorkspaces, sharedWorkspaces, profile, myTeams } = this.props;

    if (!isLoaded(profile, myTeams)) return <Loader />;
    if (!profile.termsConsentedOn) return <TermsDialog />;
    if (profile.grandfathered && !profile.modal_hidden_appsumo)
      return <AppSumoFirstVisitDialog />;
    if (profile.persona === "undecided") return <DecidePersonaDialog />;
    if (profile.persona === "agency" && !myTeams)
      return <CreateTeamDialog open={true} />;

    if (!isLoaded(myWorkspaces, sharedWorkspaces)) {
      return <Loader />;
    }

    const myWorkspacesKeys = myWorkspaces ? Object.keys(myWorkspaces) : [];
    const unarchivedMyWorkspacesKeys = myWorkspacesKeys.filter(
      (workspaceId) => !myWorkspaces[workspaceId].isArchived
    );
    const showArchivedSwitch =
      unarchivedMyWorkspacesKeys.length !== myWorkspacesKeys.length;
    const visibleMyWorkspaces = this.state.showArchived
      ? myWorkspacesKeys
      : unarchivedMyWorkspacesKeys;
    const visibleSharedWorkspaces = sharedWorkspaces
      ? Object.keys(sharedWorkspaces).filter(
          (workspaceId) => !sharedWorkspaces[workspaceId].isArchived
        )
      : [];
    return (
      <>
        <Helmet title="Workspaces" />
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            <Typography variant="h2" gutterBottom display="inline">
              My workspaces
            </Typography>
          </Grid>
          <Grid item>
            {showArchivedSwitch && (
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showArchived}
                    onChange={this.handleChange}
                    name="showArchived"
                    color="primary"
                  />
                }
                label="Show archived"
              />
            )}
            <WorkspaceDialog mode="Add" />
          </Grid>
        </Grid>
        <Divider my={3} />

        {myWorkspaces ? (
          <Grid container spacing={4}>
            {visibleMyWorkspaces.map((workspaceId) => (
              <WorkspaceCard
                key={workspaceId}
                workspaceId={workspaceId}
                workspace={myWorkspaces[workspaceId]}
              />
            ))}
          </Grid>
        ) : (
          <Container maxWidth="sm" mt={12}>
            <Typography variant="h2" align="center" gutterBottom>
              Set up your first workspace
            </Typography>
            <Typography variant="body1" align="center">
              Use a workspace for each client or project, where all the press
              releases have the same Key Messages in common. You can invite
              others to the workspace to collaborate with you.
            </Typography>
            <Container align="center" my={5}>
              <WorkspaceDialog mode="Add" />
            </Container>
            <img
              src={BlankWorkspacesImage}
              style={{ marginLeft: "100px", marginTop: "40px" }}
              height="160"
              alt="Blank Workspaces"
            />
          </Container>
        )}

        {visibleSharedWorkspaces.length > 0 && (
          <>
            <Divider my={6} />
            <Typography variant="h4" mb={4}>
              Shared with me
            </Typography>
            <Grid container spacing={4}>
              {visibleSharedWorkspaces.map((workspaceId) => (
                <WorkspaceCard
                  key={workspaceId}
                  workspaceId={workspaceId}
                  workspace={sharedWorkspaces[workspaceId]}
                />
              ))}
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myWorkspaces: state.firestore.data[`myWorkspaces${state.firebase.auth.uid}`],
  sharedWorkspaces:
    state.firestore.data[`sharedWorkspaces${state.firebase.auth.uid}`],
  myTeams: state.firestore.data.myTeams
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    // we append the uid to the storeAs reference to make each unique, avoiding
    // a brief flash of previous user details if someone else logs in
    {
      collection: "workspaces",
      where: ["ownerUserId", "==", props.auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "myWorkspaces" + props.auth.uid
    },
    {
      collection: "workspaces",
      where: ["sharingUserIds", "array-contains", props.auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "sharedWorkspaces" + props.auth.uid
    }
  ])
)(Workspaces);
