import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Checkbox,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  Typography as MuiTypography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import AppIcon from "../assets/firebase-pinggo-logo.png";

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const DialogContent = styled(MuiDialogContent)`
  padding: 0 40px 0;
`;
const DialogActions = styled(MuiDialogActions)`
  padding: 24px;
  justify-content: center;
`;

class TermsDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true,
      termsConsent: false,
      marketingConsent: false,
      processing: false,
      errors: {}
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOk = () => {
    const { termsConsent, marketingConsent } = this.state;
    if (!termsConsent) {
      this.setState({
        errors: { termsConsent: "Please tick to agree to the PingGo terms" }
      });
      return;
    }
    this.props.firebase
      .updateProfile({
        marketingConsent,
        termsConsentedOn: new Date().getTime()
      })
      .then((result) => {
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  buttonDisabled = () => {
    const { termsConsent, errors, processing } = this.state;
    return !(termsConsent && Object.keys(errors).length === 0 && !processing);
  };

  handleCheckboxChange = (event) => {
    // clear any errors for the changed field
    const errors = this.state.errors;
    delete errors[event.target.name];
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  render() {
    const { termsConsent, marketingConsent, errors } = this.state;

    return (
      <Dialog
        open={this.state.open}
        // onClose={this.handleClose}
        fullWidth={true}
        // disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm">
        <DialogContent>
          <Grid container justifyContent="center">
            <img src={AppIcon} alt="foxy" width="250px" />
          </Grid>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom
            my={5}>
            PingGo Terms of Service
          </Typography>

          <FormControlLabel
            // position checkbox at top
            style={{ display: "table" }}
            control={
              <div style={{ display: "table-cell" }}>
                <Checkbox
                  checked={Boolean(termsConsent)}
                  onChange={this.handleCheckboxChange}
                  name="termsConsent"
                  color="primary"
                />
              </div>
            }
            label={
              <Typography variant="body2" color="textSecondary">
                I agree to PingGo's{" "}
                <a href="https://ping-go.com/tos">Terms of Service</a> and{" "}
                <a href="https://ping-go.com/privacy">Privacy Policy</a>, and I
                consent to PingGo transferring, hosting and processing my data
                outside of the UK.*
              </Typography>
            }
            labelPlacement="end"
          />

          <FormControlLabel
            my={3}
            // position checkbox at top
            style={{ display: "table" }}
            control={
              <div style={{ display: "table-cell" }}>
                <Checkbox
                  checked={Boolean(marketingConsent)}
                  onChange={this.handleCheckboxChange}
                  name="marketingConsent"
                  color="primary"
                />
              </div>
            }
            label={
              <Typography variant="body2" color="textSecondary">
                I agree to receive marketing emails from PingGo.
              </Typography>
            }
          />
          {errors.termsConsent && (
            <Typography mb={2} variant="body2" color="primary">
              {errors.termsConsent}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.handleOk}
            color="primary"
            disabled={this.buttonDisabled()}>
            Yes, I agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(TermsDialog);
