import React from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from "@material-ui/core";

function NewContentCards({ handleSelect }) {
  return (
    <Grid container spacing={5} alignItems="stretch">
      <Grid item xs>
        <Card elevation={4} style={{ height: "100%" }}>
          <CardHeader
            title="Create your Key Messages"
            titleTypographyProps={{ align: "center" }}
          />
          <CardContent>
            <Typography variant="body1">
              Key messages are the foundation of any communications campaign.
              Get started with a scoping document or company backgrounder to
              capture the key messages that will resonate with your target
              audience. PingGo will use your key messages consistently across
              all templates to ensure your comms cuts through.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleSelect("background")}>
              Background Templates...
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card
          elevation={4}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
          }}>
          <CardHeader
            title="Explore our Template Library"
            titleTypographyProps={{ align: "center" }}
          />
          <CardContent>
            <Typography variant="body1">
              From press releases and blogs to pitches and thought leadership,
              our journalist approved and industry standard templates provide
              the guidance and structure needed for top quality media writing.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleSelect("content")}>
              Content Templates...
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleSelect("press-release")}>
              Press Release Templates...
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs>
        <Card
          elevation={4}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
          }}>
          <CardHeader
            title="Freestyle"
            titleTypographyProps={{ align: "center" }}
          />
          <CardContent>
            <Typography variant="body1">
              No rules. No structure. Just you and your keyboard.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleSelect("blank")}>
              Blank Content...
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NewContentCards;
