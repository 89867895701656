import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { formatDistanceToNow } from "date-fns";
import {
  Avatar as MuiAvatar,
  MenuItem as MuiMenuItem,
  Menu,
  Tooltip,
  ListItem,
  ListItemIcon,
  ClickAwayListener,
  ListItemText
  // ListItem,
  // Typography,
  // Icon
} from "@material-ui/core";
import {
  AccountCircle,
  CancelScheduleSend as EmailError,
  Drafts as EmailOpened,
  Email as EmailDelivered,
  Send as EmailSending,
  Settings as EmailProcessing
} from "@material-ui/icons";

import trackEvent from "../util/trackEvent";

const Avatar = styled(MuiAvatar)`
  height: 37px;
  width: 37px;
  border: 2px solid #fafafa;
  margin-left: -10px;
`;

const MenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

const EMAIL_STATUS = {
  PROCESSING: "Delivering.",
  SUCCESS: "Delivered.",
  ERROR: "An error occurred in delivery."
};

const statusIcon = (sentEmail) => {
  if (sentEmail.openedAt) {
    return <EmailOpened />;
  } else if (sentEmail.deliveryState) {
    if (sentEmail.deliveryState === "ERROR") return <EmailError />;
    if (sentEmail.deliveryState === "SUCCESS") return <EmailDelivered />;
    return <EmailSending />;
  }
  return <EmailProcessing />;
};

const statusText = (sentEmail) => {
  if (sentEmail.openedAt) {
    return "Opened " + formatDistanceToNow(sentEmail.openedAt) + " ago.";
  } else if (sentEmail.deliveryState) {
    return EMAIL_STATUS[sentEmail.deliveryState];
  }
  return null;
};
class ManageInvitationMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
  }

  handleClick = (event) => {
    if (!this.props.canEdit) return;
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickAway = (event) => {
    const thisMenu = document.getElementById(this.props.invitation.id);
    // sometimes the element is not found, so we ignore
    if (thisMenu && !thisMenu.contains(event.target)) this.handleClose();
  };

  handleWithdraw = () => {
    const { invitation } = this.props;
    this.props.firestore
      .delete(`email/${invitation.id}`)
      .then(() => {
        return;
      })
      .catch((error) => {
        console.log(error);
      });
    trackEvent(this.props.firebase, "Withdrew an invitation", {
      invitedUser: invitation.To
    });
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { invitation } = this.props;
    const id = open ? invitation.id : undefined;

    return (
      <div id={id}>
        <Tooltip title={invitation.To + " has been invited"} arrow>
          <Avatar onClick={this.handleClick}>
            <AccountCircle />
          </Avatar>
        </Tooltip>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Menu
            // id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            // elevation={8}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            disableEnforceFocus
            style={{ pointerEvents: "none" }}
            PaperProps={{ style: { pointerEvents: "auto" } }}>
            <ListItem divider>
              <ListItemIcon>{statusIcon(invitation)}</ListItemIcon>
              <ListItemText
                primary={invitation.To}
                secondary={statusText(invitation)}
              />
            </ListItem>
            <MenuItem
              variant="text"
              color="primary"
              onClick={this.handleWithdraw}>
              Withdraw invitation
            </MenuItem>
          </Menu>
        </ClickAwayListener>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  withRouter,
  connect(mapStateToProps)
)(ManageInvitationMenu);
