const homeSteps = [
  {
    target: "body",
    placement: "center",
    title: "Welcome to PingGo!",
    content: `PingGo helps you create simple, consistent and creative press releases and other content. Let's get started with
    a quick tour of the main areas of the app, to help you find your way around.`
  },
  {
    target: ".tour-workspacesLink",
    placement: "right-start",
    title: "Workspaces",
    content: `A workspace is where you group together content which all have a set of Key Messages
    in common - for example they might all belong to a single client, department or project.`
  },
  {
    target: ".tour-myPingGoLink",
    placement: "right-start",
    title: "My PingGo",
    content: `Your My PingGo page contains all the content you're currently working on, whether 
    as an author or an approver. If there's a number displayed, that's the number of content items that are
    currently awaiting your approval.`
  },
  {
    target: ".tour-teamsLink",
    placement: "right",
    title: "Teams",
    content: `If you're part of a team (like an Agency or similar organisational group), you can 
    access and manage team workspaces and the media contact database here.`
  }
  // {
  //   target: ".tour-profileMenu",
  //   placement: "bottom-end",
  //   title: "Profile",
  //   content: `You can manage your account and sign out of PingGo here.`
  // },
  // {
  //   target: ".tour-notifications",
  //   placement: "bottom-end",
  //   title: "Notifications",
  //   content: `You will be notified when you're added or removed from a workspace or team, or as a
  //   reviewer or a sign-off. You'll also see notifications when content you've authored is commented on,
  //   or approved/unapproved by someone.`
  // },
  // {
  //   target: ".tour-tour",
  //   placement: "bottom-end",
  //   title: "Get more help",
  //   content: `Wherever there's a mini-tour available for a particular page, you'll see this icon,
  //   and you can take the tour again anytime you like by clicking here.`
  // }
];

export default homeSteps;
