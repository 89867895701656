import CONTENT_TYPES from "./contentTypes";
import { format } from "date-fns";

const prefaceForDoc = (document) => {
  const contentType = CONTENT_TYPES[document.contentType];
  let preface = contentType.prefix ? `<h3>${contentType.prefix}</h3>` : "";
  if (contentType.includeDate && document.dueDate && !document.embargoDateTime)
    preface += `<h3>${format(document.dueDate, "d MMM y")}</h3>`;
  if (contentType.includeDate && document.embargoDateTime)
    preface += `<h4>Embargoed until ${format(
      document.embargoDateTime,
      "d MMMM y HH:mm"
    )}</h4>`;
  return preface;
};

export default prefaceForDoc;
