const CONTENT_TYPES = {
  pressRelease: {
    name: "Press Release",
    nameWithArticle: "a Press Release",
    prefix: "PRESS RELEASE",
    includeDate: true,
    includeCoveringText: true,
    category: "press-release"
  },
  blankPressRelease: {
    name: "Blank Press Release",
    nameWithArticle: "a Press Release",
    prefix: "PRESS RELEASE",
    includeDate: true,
    includeCoveringText: true,
    category: "blank"
  },
  pressPack: {
    name: "Press Pack",
    nameWithArticle: "a Press Pack",
    prefix: "PRESS PACK",
    includeDate: true,
    includeCoveringText: true,
    category: "background"
  },
  background: {
    name: "Background",
    nameWithArticle: "a Background ",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "background"
  },
  pitch: {
    name: "Feature Pitch",
    nameWithArticle: "a Feature Pitch",
    prefix: "PITCH",
    includeDate: false,
    includeCoveringText: false,
    category: "content"
  },
  opEd: {
    name: "Opinion Editorial",
    nameWithArticle: "an Opinion Editorial",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "content"
  },
  expertArticle: {
    name: "Expert Article",
    nameWithArticle: "an Expert Article",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "content"
  },
  caseStudy: {
    name: "Case study",
    nameWithArticle: "a Case study",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "content"
  },
  comment: {
    name: "Comment",
    nameWithArticle: "a Comment",
    prefix: "COMMENT",
    includeDate: true,
    includeCoveringText: true,
    category: "content"
  },
  statement: {
    name: "Statement",
    nameWithArticle: "a Statement",
    prefix: "STATEMENT",
    includeDate: true,
    includeCoveringText: true,
    category: "content"
  },
  blogPost: {
    name: "Blog Post",
    nameWithArticle: "a Blog Post",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "content"
  },
  plain: {
    name: "Plain Content",
    nameWithArticle: "a Piece of Content",
    prefix: null,
    includeDate: false,
    includeCoveringText: true,
    category: "blank"
  }
};

export default CONTENT_TYPES;
