import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import {
  CircularProgress,
  Typography as MuiTypography,
  Box
} from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

function Loader(props) {
  return (
    <Root>
      <Box display="flex" flexDirection="column" alignItems="center">
        {props.message && (
          <Typography mb={3} variant="h5" color="secondary">
            {props.message}
          </Typography>
        )}
        <CircularProgress m={2} color="primary" size={props.size || 50} />
      </Box>
    </Root>
  );
}

export default Loader;
