import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Helmet from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page403(props) {
  return (
    <Wrapper>
      <Helmet title="403 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Sorry, not allowed!
      </Typography>
      {/* <Typography component="h2" variant="h4" align="center" gutterBottom>
        Not authorised.
      </Typography> */}
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        You don't have permission to view this page. That might be because your
        permissions were removed, or a document changed to a state you can't
        access. You should contact the document owner.
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        mt={2}>
        Return to PingGo Home
      </Button>
    </Wrapper>
  );
}

export default Page403;
