const teamSteps = [
  {
    target: "body",
    placement: "center",
    title: "Team",
    content: `You're on the Team page right now. Your team might be a PR agency, a corporate PR department, or any group that
    needs to be able to manage workspaces for clients and access your Media Contact database.`
  },
  {
    target: ".tour-members",
    placement: "bottom",
    title: "Members",
    content: `Team members are all the PingGo users who have access to this team page, and who can
    create workspaces which are linked to the team. Use the 'add' button to invite others, whether or not
    they already have a PingGo account.`
  },
  {
    target: ".tour-team-workspaces",
    placement: "bottom",
    title: "Members",
    content: `Create a workspace for every client, where you can write new content, manage distributions and collaborate with your team.`
  },
  {
    target: ".tour-media-contacts",
    placement: "bottom",
    title: "Media Contacts",
    content: `This is where you manage your team's Media Contact Database, where your team can manage a central media list that can be 
    filtered quickly and easily using tags. This makes it really easy put together lists for specific content distributions. You 
    can upload contacts from a CSV file or add them individually.`
  }
  // {
  //   target: ".tour-myPingGoLink",
  //   placement: "right-start",
  //   title: "My PingGo",
  //   content: `Your My PingGo page contains all the content you're currently working on, whether
  //   as an author or an approver. If there's a number displayed, that's the number of content items that are
  //   currently awaiting your approval.`
  // },
  // {
  //   target: ".tour-profileMenu",
  //   placement: "bottom-end",
  //   title: "Profile",
  //   content: `You can manage your account and sign out of PingGo here.`
  // },
  // {
  //   target: ".tour-notifications",
  //   placement: "bottom-end",
  //   title: "Notifications",
  //   content: `You will be notified when you're added or removed from a workspace or team, or as a
  //   reviewer or a sign-off. You'll also see notifications when content you've authored is commented on,
  //   or approved/unapproved by someone.`
  // },
  // {
  //   target: ".tour-tour",
  //   placement: "bottom-end",
  //   title: "Get more help",
  //   content: `Wherever there's a mini-tour available for a particular page, you'll see this icon,
  //   and you can take the tour again anytime you like by clicking here.`
  // }
  // {
  //   target: ".tour-custom-templates",
  //   placement: "bottom",
  //   title: "Custom Templates",
  //   content: `Here you can manage templates that are available to the team's workspaces, in
  //   addition to the ones in the PingGo library. You can use one of the existing templates as
  //   a starting point, or build one from scratch.`
  // },
  // {
  //   target: ".tour-custom-questions",
  //   placement: "bottom",
  //   title: "Custom Questions",
  //   content: `You can add more questions here, if the PingGo library ones aren't sufficient to
  //   build the templates you need - perhaps
  //   you're catering for a particular vertical, or you just want to re-phrase the question or help
  //   text to better suit your needs. If you redefine a question tag that already exists in the
  //   PingGo library, it will be used in your team's workspaces instead of the PingGo one.`
  // },
  // {
  //   target: ".tour-master-key-messages",
  //   placement: "bottom",
  //   title: "Master Key Messages",
  //   content: `If you want to ensure certain Key Messages are used across all of your team's workspaces,
  //   you can define them here. They might be used for the name and contact details of your agency, or a corporate
  //   message that needs to be reflected consistently across all departments. Your Key Message here
  //   will override any Key Message of the same question tag that's defined in any of the team's workspaces.`
  // }
];

export default teamSteps;
