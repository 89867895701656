import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography as MuiTypography,
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Edit } from "@material-ui/icons";

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const EditIcon = styled(Edit)`
  margin: 4px;
  padding: 0;
  height: 16px;
  width: 16px;
`;
const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

class KeyMessageDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      error: null,
      tag: props.tag || "none",
      answer: null,
      keyQuestionMap: {}
    };
  }

  handleOpen = () => {
    this.setState({
      answer: this.props.answer || "",
      error: null,
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSubmit = () => {
    let newAnswer = {};
    newAnswer[this.state.tag] = this.state.answer;
    this.props.updateKeyAnswers(newAnswer);
    this.handleClose();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { keyQuestionMap } = this.props;
    const question = keyQuestionMap[this.state.tag];
    if (!question && this.props.mode !== "Add") {
      console.log("Invalid tag " + this.state.tag);
      return null;
    }

    return (
      <>
        {this.props.mode === "Add" ? (
          <Button
            variant="contained"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            onClick={this.handleOpen}>
            New Key Message
          </Button>
        ) : (
          <IconButton onClick={this.handleOpen}>
            <EditIcon color="secondary" />
          </IconButton>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="md">
          <DialogTitle>
            {this.props.mode === "Add"
              ? "Add a Key Message"
              : "Edit a Key Message"}
          </DialogTitle>
          <DialogContent>
            {this.props.mode === "Add" ? (
              <FormControl>
                <InputLabel id="tagSelectLabel">
                  Key Message Question
                </InputLabel>
                <Select
                  my={4}
                  id="tag"
                  name="tag"
                  labelId="tagSelectLabel"
                  autoWidth
                  value={this.state.tag}
                  onChange={this.handleChange}>
                  <MenuItem value="none">Choose a key question</MenuItem>
                  {keyQuestionMap &&
                    Object.keys(keyQuestionMap).map((tag) => (
                      <MenuItem key={tag} value={tag}>
                        {keyQuestionMap[tag].text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <Typography mt={2} variant="h6">
                {question.text}
              </Typography>
            )}
            {question && (
              <>
                <Typography mt={2} variant="body2">
                  {question.help}
                </Typography>
                <TextField
                  id="answer"
                  name="answer"
                  type="string"
                  multiline
                  margin="normal"
                  required
                  autoFocus
                  fullWidth
                  label="Key Message"
                  placeholder={question.placeholder}
                  value={this.state.answer}
                  onChange={this.handleChange}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              disabled={this.state.tag === "none"}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(KeyMessageDialog);
