const createDocSteps = [
  {
    target: "body",
    placement: "center",
    title: "Take a quick tour?",
    content: `First time writing content in PingGo?
      Click Next to learn the basics, or 'Skip the tour' to get started yourself`
  },
  {
    target: ".MuiAccordion-root.Mui-expanded:first-child",
    placement: "right",
    title: "The Questions",
    content: `These are the questions that will fill in the blanks in the template. 
      If there are missing Key Messages, they will be included in these questions.
      You can press 'enter' or 'tab' to move to the next question`
  },
  {
    target: ".tour-preview",
    placement: "left",
    title: "The Preview",
    content: `The preview shows you a live version of what your content looks like.  You can't 
    edit it directly but your current answers are incorporated as you type them. Key Messages are highlighted in purple, 
    the answers for this particular piece in orange.`
  },
  {
    target: ".tour-review",
    placement: "left",
    title: "The Next Step",
    content: `Once the questions have all been answered, this button will be enabled and you can move on to 
    tweaking your content and having it reviewed and approved. You can't come back to this stage but 
    you can still edit your answers directly within the content editor.`
  }
];

export default createDocSteps;
