import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";
import Helmet from "react-helmet";
import {
  withFirestore,
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";
import {
  Container as MuiContainer,
  Tooltip,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
  Chip as MuiChip,
  Box,
  Tabs,
  Tab,
  Paper
} from "@material-ui/core";
import { Archive } from "@material-ui/icons";
import Loader from "../../components/Loader";
import { AvatarGroup as MuiAvatarGroup } from "@material-ui/lab";
import { spacing } from "@material-ui/system";

import ManageMemberMenu from "../../components/ManageMemberMenu";
import InviteMemberDialog from "../../components/InviteMemberDialog";
import ManageInvitationMenu from "../../components/ManageInvitationMenu";
import autoId from "../../util/autoId";
// import PRICING_PLANS from "../../util/pricingPlans";
import DocumentTable from "../../components/DocumentTable";
// import BlankWorkspacesImage from "../../assets/blank-home.png";
import KeyMessageTable from "../../components/KeyMessageTable";
import KeyMessageDialog from "../../components/KeyMessageDialog";
import ManageWorkspaceMenu from "../../components/ManageWorkspaceMenu";
import canAccessPage from "../../util/canAccessPage";
import addNotification from "../../util/addNotification";
import trackEvent from "../../util/trackEvent";
import NewContentDialog from "../../components/NewContentDialog";
import NewContentCards from "../../components/NewContentCards";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Container = styled(MuiContainer)(spacing);
const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;
const Chip = styled(MuiChip)(spacing);
// const EllipsisWrapper = styled.div`
//   // overflow: hidden;
//   // text-overflow: ellipsis;
//   width: 40vw;
// `;
const ArchiveIcon = styled(Archive)`
  padding-top: 2px;
  padding-left: 3px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

class Workspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      keyQuestionMap: null,
      teamKeyAnswers: null
      // modifiedPlan: null
    };
  }

  componentDidMount() {
    // we don't have the teamId in the path parameters, so we can't rely on the
    // firebase connect to build the complete question map
    // we use the unwrapped firestore methods instead
    let keyQuestionMap = {};
    let teamKeyAnswers = {};
    this.props.firestore
      .collection(`teams/R7Z1iCDHpvS73plmK1iP/questions`)
      .where("isKey", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((question) => {
          keyQuestionMap[question.data().tag] = question.data();
        });
      })
      .then(() => {
        this.props.firestore
          .collection(`workspaces`)
          .doc(this.props.match.params.workspaceId)
          .get()
          .then((result) => {
            const teamId = result.data().teamId;
            // console.log(teamId);
            if (teamId) {
              this.props.firestore
                .collection(`teams/${teamId}/questions`)
                .where("isKey", "==", true)
                .get()
                .then((snapshot) => {
                  snapshot.forEach((question) => {
                    keyQuestionMap[question.data().tag] = question.data();
                  });
                })
                .then(() => {
                  this.props.firestore
                    .collection(`teams`)
                    .doc(teamId)
                    .get()
                    .then((result) => {
                      teamKeyAnswers = result.data().keyAnswers;
                    })
                    .then(() => {
                      this.setState({ teamKeyAnswers });
                    });
                });
            }
          })
          .then(() => {
            this.setState({ keyQuestionMap, teamKeyAnswers });
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleBack = () => {
    this.props.history.push("/workspaces");
  };

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  allUsers = () => {
    const workspace = this.props.workspace;
    // we store members in the sharingUsers field
    return [workspace.ownerUser, ...workspace.sharingUsers];
  };

  updateSharingUsers = (newUsers) => {
    const workspaceId = this.props.match.params.workspaceId;
    this.props.firestore
      .update(
        {
          collection: "workspaces",
          doc: workspaceId
        },
        {
          sharingUsers: newUsers,
          sharingUserIds: newUsers.map((user) => user.userId)
        }
      )
      // .then(() => {})
      .catch((error) => console.log(error));
  };

  handleRemoveUser = (removeUser) => {
    const newUsers = this.props.workspace.sharingUsers.filter(
      (user) => user.userId !== removeUser.userId
    );
    // console.log(newUsers);
    this.updateSharingUsers(newUsers);
    addNotification(
      this.props.firestore,
      removeUser,
      this.props.profile,
      `removed you as a member of ${this.props.workspace.name}.`,
      null
    );
    trackEvent(this.props.firebase, "Removed a user", {
      resource: this.props.workspace.name
    });
  };

  updateKeyAnswers = (newAnswer) => {
    const workspaceId = this.props.match.params.workspaceId;
    const newAnswers = Object.assign(
      {},
      this.props.workspace.keyAnswers,
      newAnswer
    );
    this.props.firestore
      .update(
        {
          collection: "workspaces",
          doc: workspaceId
        },
        {
          keyAnswers: newAnswers
        }
      )
      .then(() => {
        // console.log("updated with " + JSON.stringify(newAnswers));
      })
      .catch((error) => console.log(error));
  };

  // handlePlanUpdate = (workspaceId, newPlan) => {
  //   this.setState({ modifiedPlan: newPlan });
  // };

  handleSelect = (category) => {
    this.props.history.push(
      `/workspaces/${this.props.match.params.workspaceId}/documents/start/${category}`
    );
  };

  handleCreateQuickAccess = (template) => {
    const workspaceId = this.props.match.params.workspaceId;
    const { name, email, photo, userId } = this.props.profile;
    const hasNoTags = !template.text.includes("#");
    const nextStage = hasNoTags ? "review" : "create";
    const newContent = {
      workspaceId,
      title: template.title, // in case our redirect is interrupted
      stage: nextStage,
      text: template.text,
      templateText: template.text,
      contentType: template.contentType,
      modifiedOn: new Date().getTime(),
      ownerUser: {
        name,
        email,
        photo,
        userId
      },
      ownerUserId: userId,
      stageProgress: 0,
      stageTarget: 0,
      sharingUserIds: [],
      sharingUsers: [],
      approverUserIds: [],
      approvedUserIds: [],
      userRoles: {}
    };
    const newId = autoId();
    this.props.firestore
      .set(`workspaces/${workspaceId}/documents/${newId}`, newContent)
      .then(() => {
        this.props.history.push(
          `/workspaces/${workspaceId}/documents/${newId}/${nextStage}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { workspace, documents, profile, auth, sentInvitations } = this.props;
    const { keyQuestionMap, teamKeyAnswers } = this.state;

    if (!isLoaded(documents, workspace) || !keyQuestionMap) {
      return <Loader />;
    }

    if (!canAccessPage(auth, workspace)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: { from: { pathname: this.props.history.location.pathname } }
          }}
        />
      );
    }

    // const sharingUsers = workspace.sharingUsers;
    const workspaceId = this.props.match.params.workspaceId;
    const isBlankSlate = !documents.length;
    const canManageWorkspace = true;
    // profile.userId === workspace.ownerUserId ||
    // profile.userId === workspace.billingUserId;
    const pendingInvitations = (sentInvitations || []).filter(
      (invitation) => !invitation.invitationHandled
    );

    const userApproverObligations = [
      ...new Set(
        [].concat(
          ...this.props.documents.map((doc) => [...doc.approverUserIds])
        )
      )
    ];

    const userOwnerObligations = [
      ...new Set(this.props.documents.map((doc) => doc.ownerUserId))
    ];

    return (
      <Fragment>
        <Helmet title={workspace.name} />
        <Grid
          container
          justifyContent="space-between"
          spacing={6}
          wrap="nowrap"
          alignItems="center">
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid>
              <Typography
                variant="h2"
                display="inline"
                style={{ whiteSpace: "nowrap" }}>
                {workspace.name}
              </Typography>
            </Grid>
            {workspace.teamId && (
              <Grid>
                <Chip
                  label={workspace.teamName}
                  variant="outlined"
                  size="small"
                  color="primary"
                  ml={2}
                  mb={2}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            wrap="nowrap"
            alignItems="center">
            <Grid item>
              <AvatarGroup max={10} spacing={10}>
                {this.allUsers().map((user) => (
                  <ManageMemberMenu
                    key={user.userId}
                    user={user}
                    profile={profile}
                    // isAnApprover={this.userIsAnApprover(user.userId)}
                    // hasApproved={this.userHasApproved(user.userId)}
                    // role={this.userRoles()[user.userId]}
                    canEdit={
                      // team.ownerUserId === profile.userId ||
                      workspace.ownerUserId === profile.userId
                    }
                    isLiveApprover={userApproverObligations.includes(
                      user.userId
                    )}
                    isLiveOwner={userOwnerObligations.includes(user.userId)}
                    isOwner={workspace.ownerUserId === user.userId}
                    // handleToggleApprover={() =>
                    //   this.handleToggleApprover(user.userId)
                    // }
                    handleRemoveUser={() => this.handleRemoveUser(user)}
                    // handleMenuRoleUpdate={(role) =>
                    //   this.handleMenuRoleUpdate(user.userId, role)
                  />
                ))}
                {pendingInvitations.map((invitation) => (
                  <ManageInvitationMenu
                    key={invitation.id}
                    invitation={invitation}
                    canEdit={
                      // team.ownerUserId === profile.userId ||
                      workspace.ownerUserId === profile.userId
                    }
                  />
                ))}
              </AvatarGroup>
            </Grid>
            {canManageWorkspace && (
              <Grid item>
                <InviteMemberDialog
                  class="tour-manageUser"
                  resourceName={"the " + workspace.name + " workspace"}
                  parent={workspace}
                  workspaceId={workspaceId}
                  workspace={workspace} // assuming for plan changes?
                  teamId={workspace.teamId || ""}
                  parentUsers={this.allUsers()}
                  // parentAddedUserIds={workspace.sharingUserIds}
                  // parentUserRoles={this.userRoles()}
                  // parentApproverUserIds={
                  //   this.props.listenedDocument.approverUserIds
                  // }
                  resourcePath={`workspaces/${workspaceId}`}
                  redirectUrl={`/workspaces/${workspaceId}`}
                  inviteType={"workspace"}
                  inviteIntent={`join ${workspace.name}`}
                  handleUpdate={this.updateSharingUsers}
                  // maxSharingUsers={
                  //   PRICING_PLANS[workspace.plan || 0].max_additional_members
                  // }
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            {/* <Grid item>
              {this.state.modifiedPlan &&
              this.state.modifiedPlan !== workspace.plan ? (
                <CircularProgress size={15} />
              ) : (
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ textTransform: "uppercase" }}>
                  {PRICING_PLANS[workspace.plan || 0].name} Plan
                </Typography>
              )}
            </Grid> */}
            {workspace.isArchived && (
              <Grid item>
                <Tooltip title="Archived" arrow>
                  <ArchiveIcon color="secondary" />
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              {canManageWorkspace && (
                <ManageWorkspaceMenu
                  workspaceId={workspaceId}
                  workspace={workspace}
                  // handleUpdate={this.handlePlanUpdate}
                />
              )}
            </Grid>
            <Grid item>
              {this.state.tab === 0 && documents.length > 0 && (
                <NewContentDialog
                  workspaceId={workspaceId}
                  workspace={workspace}
                />
              )}
              {this.state.tab === 1 && (
                <KeyMessageDialog
                  keyQuestionMap={keyQuestionMap}
                  mode="Add"
                  updateKeyAnswers={this.updateKeyAnswers}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Divider my={3} />
        {!isBlankSlate && (
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label="Content" className="tour-press-releases" />
            <Tab label="Key Messages" className="tour-key-messages" />
            {/* <Tab label="Photo Library" className="tour-photo-library" /> */}
          </Tabs>
        )}
        <TabPanel value={this.state.tab} index={0}>
          {documents.length ? (
            <DocumentTable documents={documents} history={this.props.history} />
          ) : (
            <Container maxWidth="lg" mt={12}>
              <Typography variant="h2" align="center" gutterBottom>
                Let's create some content!
              </Typography>
              {/* <img
                src={BlankWorkspacesImage}
                style={{ marginLeft: "123px" }}
                height="200"
                alt="BlankWorkspaces"
              /> */}
              <NewContentCards handleSelect={this.handleSelect} />
            </Container>
          )}
        </TabPanel>
        <TabPanel value={this.state.tab} index={1}>
          <KeyMessageTable
            keyAnswers={workspace.keyAnswers}
            keyQuestionMap={keyQuestionMap}
            updateKeyAnswers={this.updateKeyAnswers}
            overrideAnswers={teamKeyAnswers}
            canEdit={canManageWorkspace}
          />

          {!isEmpty(teamKeyAnswers) && (
            <>
              <Typography variant="h4" mt={4} ml={1}>
                {workspace.teamName} Master Key Messages
              </Typography>
              <Typography variant="body2" my={1} ml={1}>
                These are managed by {workspace.teamName} and override Key
                Messages of the same type that you define above.
              </Typography>{" "}
              <KeyMessageTable
                keyAnswers={teamKeyAnswers}
                keyQuestionMap={keyQuestionMap}
                canEdit={false}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={this.state.tab} index={2}>
          <Paper></Paper>
        </TabPanel>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workspaceId = ownProps.match.params.workspaceId;
  const workspaces = state.firestore.data.workspaces;
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    // workspaces: state.firestore.data.workspaces,
    workspace: workspaces ? workspaces[workspaceId] : {},
    documents: state.firestore.ordered[`workspaces/${workspaceId}/documents`],
    sentInvitations: state.firestore.ordered["sentInvitations"]
  };
}

export default compose(
  withFirestore,
  firestoreConnect((props) => [
    {
      collection: "workspaces",
      doc: props.match.params.workspaceId
    },
    {
      collection: `workspaces/${props.match.params.workspaceId}/documents`,
      orderBy: ["modifiedOn", "desc"]
    },
    {
      collection: "email",
      where: [
        ["Tag", "==", "invitation"],
        ["resourcePath", "==", `workspaces/${props.match.params.workspaceId}`]
      ],
      orderBy: ["sentOn", "desc"],
      storeAs: "sentInvitations"
    }
  ]),
  connect(mapStateToProps)
)(Workspace);
