import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link as RouterLink } from "react-router-dom";
import stc from "string-to-color";
import initials from "../util/initials";

import {
  Avatar,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  Grid,
  Link,
  // Typography as MuiTypography,
  Tooltip,
  Chip as MuiChip,
  CardHeader as MuiCardHeader
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import ManageWorkspaceMenu from "./ManageWorkspaceMenu";
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  padding: 6px 3px 12px 12px;
`;
// const Typography = styled(MuiTypography)(spacing);
const CardHeader = styled(MuiCardHeader)`
  padding: 10px 6px 0px 12px;
`;
const Chip = styled(MuiChip)`
  margin-bottom: 7px;
`;

class WorkspaceCard extends Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { workspaceId, workspace } = this.props;

    if (!workspace) return null; // deleted elsewhere

    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardHeader
            title={workspace.name}
            titleTypographyProps={{
              variant: "h4"
            }}
            action={
              <ManageWorkspaceMenu
                workspaceId={workspaceId}
                workspace={workspace}
                verticalIcon
              />
            }
            style={{
              backgroundColor: workspace.isArchived ? "#eee" : "#fff"
            }}
          />
          <CardContent>
            <>
              {workspace.teamId && (
                <Chip
                  label={workspace.teamName}
                  variant="outlined"
                  size="small"
                  color="primary"
                />
              )}{" "}
              <AvatarGroup max={10} spacing="medium">
                <Tooltip title={`Owner: ${workspace.ownerUser.name}`} arrow>
                  <Avatar
                    alt={workspace.ownerUser.name}
                    src={workspace.ownerUser.photo}
                    style={{
                      backgroundColor: stc(workspace.ownerUser.name)
                    }}>
                    {initials(workspace.ownerUser.name)}
                  </Avatar>
                </Tooltip>
                {workspace.sharingUsers &&
                  workspace.sharingUsers.map((user) => (
                    <Tooltip key={user.email} title={user.name} arrow>
                      <Avatar
                        key={user.email}
                        alt={user.name}
                        src={user.photo}
                        style={{
                          backgroundColor: stc(user.name)
                        }}>
                        {initials(user.name)}
                      </Avatar>
                    </Tooltip>
                  ))}
              </AvatarGroup>
            </>
          </CardContent>
          <CardActions>
            <Link component={RouterLink} to={`/workspaces/${workspaceId}`}>
              Show
            </Link>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(connect(mapStateToProps))(WorkspaceCard);
