import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isLoaded, isEmpty } from "react-redux-firebase";

import {
  dashboardLayoutRoutes,
  multiColumnLayoutRoutes,
  authLayoutRoutes,
  errorRoutes
} from "./index";
import DashboardLayout from "../layouts/Dashboard";
import MultiColumnLayout from "../layouts/MultiColumn";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

const unAuthRoutes = (routes, auth) =>
  routes.map(({ path, component: Component }, index) => (
    <Route
      key={index}
      path={path}
      exact
      render={(props) =>
        isEmpty(auth) ? (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        ) : (
          <Redirect
            to={props.location.state ? props.location.state.from.pathname : "/"}
          />
        )
      }
    />
  ));

const authRoutes = (routes, Layout, auth) => {
  return routes.map(({ path, component: Component }, index) => (
    <Route
      key={index}
      path={path}
      exact
      render={(props) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  ));
};

const anyRoutes = (routes) => {
  return routes.map(({ path, component: Component }, index) => {
    return (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        )}
      />
    );
  });
};

const Routes = (props) => (
  <Router>
    <Switch>
      {authRoutes(dashboardLayoutRoutes, DashboardLayout, props.auth)}
      {authRoutes(multiColumnLayoutRoutes, MultiColumnLayout, props.auth)}
      {unAuthRoutes(authLayoutRoutes, props.auth)}
      {anyRoutes(errorRoutes)}

      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

const mapStateToProps = (state) => ({
  auth: state.firebase.auth
});

Routes.propTypes = {
  auth: PropTypes.object
};

export default connect(mapStateToProps)(Routes);
