import React from "react";
import { red, orange } from "@material-ui/core/colors";
import { format } from "date-fns";
import styled from "styled-components";

const DueDateWrapper = styled.span`
  color: ${(props) =>
    props.dueDate &&
    props.dueDate - new Date().getTime() < 3 * 24 * 60 * 60 * 1000 &&
    orange[900]};
  color: ${(props) =>
    props.dueDate && props.dueDate - new Date().getTime() < 0 && red[900]};
`;

function DueDate({ dueDate }) {
  return (
    <DueDateWrapper dueDate={dueDate}>
      {dueDate ? format(dueDate, "d MMM y") : "none"}
    </DueDateWrapper>
  );
}

export default DueDate;
