import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  ListItemAvatar,
  Avatar,
  ListItemText,
  MenuItem as MuiMenuItem,
  Menu
  // ListItem,
  // Typography,
  // Icon
} from "@material-ui/core";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import stc from "string-to-color";
import initials from "../util/initials";
import trackEvent from "../util/trackEvent";
import CreateTeamDialog from "./CreateTeamDialog";
import VerifyEmailDialog from "./VerifyEmailDialog";
import updateEmailVerification from "../util/updateEmailVerification";

const MenuItem = styled(MuiMenuItem)`
  .MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
`;
const SmallishAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  padding-top: 3px;
`;

class ManageAccountMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null,
      teamDialogOpen: false,
      verifyEmailDialogOpen: false,
      senderSignature: null
    };
  }

  componentDidMount() {}

  // componentWillUnmount() {
  //   this.props.firestore.unsetListener("myTeams");
  // }

  // profileWhenLoaded = () => {
  //   return new Promise((resolve, reject) => {
  //     console.log("in promise");
  //     if (isLoaded(this.props.profile)) {
  //       resolve(this.props.profile);
  //     } else {
  //       reject();
  //     }
  //   });
  // };

  componentDidUpdate() {
    updateEmailVerification(this.props);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    trackEvent(this.props.firebase, "Logged out");
    window.Intercom("shutdown");
    this.handleClose();
    this.props.history.push("/");
    this.props.firebase.logout();
  };

  handleTeamDialogOpen = () => {
    this.setState({ teamDialogOpen: true });
    this.handleClose();
  };

  handleTeamDialogClose = () => {
    this.setState({ teamDialogOpen: false });
  };

  handleVerifyEmailDialogOpen = () => {
    this.setState({ verifyEmailDialogOpen: true });
    this.handleClose();
  };

  handleVerifyEmailDialogClose = () => {
    this.setState({ verifyEmailDialogOpen: false });
  };

  handleToggleSuppressEmailNotifications = () => {
    this.handleClose();
    this.props.firebase
      .updateProfile({
        suppressEmailNotifications: !this.props.profile
          .suppressEmailNotifications
      })
      .then(() => {
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "manage-profile-popup" : undefined;
    const { profile } = this.props;

    // console.log(auth);
    return (
      <div>
        <CreateTeamDialog
          open={this.state.teamDialogOpen}
          handleClose={this.handleTeamDialogClose}
        />
        <VerifyEmailDialog
          open={this.state.verifyEmailDialogOpen}
          handleClose={this.handleVerifyEmailDialogClose}
        />
        <IconWrapper>
          <SmallishAvatar
            alt={profile.name}
            src={profile.photo}
            style={{
              backgroundColor: stc(profile.name)
            }}
            onClick={this.handleClick}
            className="tour-profileMenu">
            {initials(profile.name || profile.email)}
          </SmallishAvatar>
        </IconWrapper>
        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          // elevation={8}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}>
          <MenuItem onClick={this.handleClose} disabled style={{ opacity: 1 }}>
            <ListItemAvatar>
              <Avatar
                alt={profile.name}
                src={profile.photo}
                style={{
                  backgroundColor: stc(profile.name)
                }}>
                {initials(profile.name || profile.email)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={profile.name} secondary={profile.email} />
          </MenuItem>
          {/* <MenuItem divider disabled={true}>
            Edit Profile
          </MenuItem> */}
          <MenuItem
            variant="text"
            color="primary"
            onClick={this.handleToggleSuppressEmailNotifications}>
            {profile.suppressEmailNotifications
              ? "Send me notifications by email"
              : "Don't send notifications by email"}
          </MenuItem>
          {!profile.senderSignatureId && (
            <MenuItem
              divider
              variant="text"
              color="primary"
              onClick={this.handleVerifyEmailDialogOpen}>
              Verify email address
            </MenuItem>
          )}
          {profile.senderSignatureId && !profile.hasVerifiedEmail && (
            <MenuItem
              divider
              variant="text"
              color="primary"
              onClick={this.handleVerifyEmailDialogOpen}>
              Resend email verification
            </MenuItem>
          )}
          {profile.isAdmin && (
            <MenuItem
              divider
              variant="text"
              color="primary"
              onClick={this.handleTeamDialogOpen}>
              Create new team
            </MenuItem>
          )}
          {/* <MenuItem variant="text" component={Link} to={"/mySubscription"}>
            My subscription
          </MenuItem> */}
          <MenuItem variant="text" onClick={this.handleLogout}>
            Sign out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
});

export default compose(
  withFirestore,
  withRouter,
  connect(mapStateToProps)
)(ManageAccountMenu);
