import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField as MuiTextField,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import autoId from "../util/autoId";

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

class QuestionDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      error: null,
      tag: "",
      text: "",
      help: "",
      placeholder: "",
      isKey: false
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOpen = (duplicatedQuestion = null) => {
    if (duplicatedQuestion) {
      const { text, help, placeholder, isKey } = duplicatedQuestion;
      this.setState({
        tag: "CopyOf" + duplicatedQuestion.tag,
        text,
        help,
        placeholder,
        isKey,
        error: null,
        open: true,
        duplicated: true
      });
    } else if (this.props.question) {
      const { tag, text, help, placeholder, isKey } = this.props.question;
      this.setState({
        tag,
        text,
        help,
        placeholder,
        isKey,
        error: null,
        open: true,
        duplicated: false
      });
    } else {
      this.setState({
        error: null,
        tag: this.props.tag || "",
        text: "",
        help: "",
        placeholder: "",
        isKey: false,
        open: true,
        duplicated: false
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSubmit = () => {
    const { tag, text, help, placeholder, isKey } = this.state;
    const questionDetails = {
      modifiedOn: new Date().getTime(),
      tag,
      text,
      help,
      placeholder,
      isKey
    };
    if (this.props.mode === "Add" || this.state.duplicated) {
      const newId = autoId();
      this.props.firestore
        .set(`teams/${this.props.teamId}/questions/${newId}`, questionDetails)
        .then(() => {
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.firestore
        .update(
          `teams/${this.props.teamId}/questions/${this.props.questionId}`,
          questionDetails
        )
        .then(() => {
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  render() {
    return (
      <>
        {this.props.mode === "Add" && (
          <Button
            variant="contained"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => this.handleOpen()}>
            New question
          </Button>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullwidth="true"
          maxWidth="md">
          <DialogTitle>{this.props.mode} a question</DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={7}>
                <TextField
                  id="tag"
                  name="tag"
                  label="Question Tag"
                  type="string"
                  required
                  fullWidth
                  value={this.state.tag}
                  onChange={this.handleChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(this.state.isKey)}
                      onChange={this.handleCheckboxChange}
                      name="isKey"
                      color="primary"
                    />
                  }
                  label="a Key Message"
                />
              </Grid>
            </Grid>
            <TextField
              id="text"
              name="text"
              type="string"
              multiline
              required
              fullWidth
              label="Question Text"
              value={this.state.text}
              onChange={this.handleChange}
            />
            <TextField
              id="help"
              name="help"
              type="string"
              multiline
              required
              fullWidth
              label="Help Text"
              value={this.state.help}
              onChange={this.handleChange}
            />
            <TextField
              id="placeholder"
              name="placeholder"
              type="string"
              multiline
              margin="normal"
              required
              fullWidth
              label="Placeholder Text"
              value={this.state.placeholder}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            {this.state.error && <Typography variant="body2">Error</Typography>}
            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              color="primary">
              {this.props.mode === "Add" || this.state.duplicated
                ? "Create"
                : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(QuestionDialog);
