import React, { Component, Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "../../vendor/ckeditor.css";
import styled from "styled-components";
import {
  firestoreConnect,
  isLoaded,
  isEmpty,
  withFirestore,
  withFirebase,
} from "react-redux-firebase";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";
import Helmet from "react-helmet";
// import htmlToPlainText from "../../util/htmlToPlainText";

import {
  Typography as MuiTypography,
  Button,
  Grid as MuiGrid,
  Hidden,
  Tooltip,
  LinearProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Archive } from "@material-ui/icons";
import { AvatarGroup } from "@material-ui/lab";

import Loader from "../../components/Loader";
// import PRICING_PLANS from "../../util/pricingPlans";
import SaveStatusCloud from "../../components/SaveStatusCloud";
import canAccessPage from "../../util/canAccessPage";
import DocumentHeader from "../../components/DocumentHeader";
import InviteReviewerDialog from "../../components/InviteReviewerDialog";
import addNotification from "../../util/addNotification";
import trackEvent from "../../util/trackEvent";
import Tour from "../../components/Tour";
import ManageDocumentMenu from "../../components/ManageDocumentMenu";
import ManageReviewerMenu from "../../components/ManageReviewerMenu";
import ManageInvitationMenu from "../../components/ManageInvitationMenu";

import CONTENT_TYPES from "../../util/contentTypes";
import DocumentAttributesDialog from "../../components/DocumentAttributesDialog";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const ArchiveIcon = styled(Archive)`
  padding-left: 3px;
`;
const Progress = styled(LinearProgress)`
  width: 100%;
  height: 3px;
`;
const ToolbarWrapper = styled.div`
  padding-left: 12px;
`;

const EllipsisWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 35vw;
`;

const STATUS_DIRTY = "Edited";
const STATUS_SAVED = "All changes saved";
const STATUS_SAVING = "Saving changes...";
const STATUS_ERROR = "Error saving changes, retrying...";

const USER_ROLES = ["Editor", "Suggester", "Commenter", "Viewer"];

const STAGE_TEXT = {
  review: {
    title: "Edit and review ",
    activeStep: 2,
    actioned: "Approved",
    action: "approve",
    action_noun: "approval",
    action_button: "Approve",
    un_action_button: "Unapprove",
    un_actioned: "Removed their approval",
    manageUserRole: "Approver",
    nextStage: "signoff",
    nextStageDescription: "'Sign-off' (clean)",
    prevStage: "create",
    prevStageDescription: "'Answer questions' (with original answers)",
  },
  signoff: {
    title: "Get sign-off for ",
    activeStep: 3,
    actioned: "Signed off",
    action: "sign-off",
    action_noun: "sign-off",
    action_button: "Sign off",
    un_action_button: "Remove sign-off",
    un_actioned: "Removed their sign-off",
    manageUserRole: "Sign-off",
    nextStage: "share",
    nextStageDescription: "'Send' (clean)",
    prevStage: "review",
    prevStageDescription: "'Edit & review' (clean)",
  },
};

const TRACK_CHANGES_COMMANDS = [
  "trackChanges",
  "acceptSuggestion",
  "discardSuggestion",
  "acceptAllSuggestions",
  "discardAllSuggestions",
  "acceptSelectedSuggestions",
  "discardSelectedSuggestions",
];

const stripTag = (text, tag) => {
  const el = document.createElement("div");
  el.innerHTML = text;
  let elements = el.getElementsByTagName(tag);
  while (elements[0]) elements[0].parentNode.removeChild(elements[0]);
  return el.innerHTML;
};

class ReviewDoc extends Component {
  constructor(props) {
    super();
    this.state = {
      stage: props.match.path.includes("review") ? "review" : "signoff",
      initalText: null,
      title: "",
      isLayoutReady: false,
      editor: null,
      editorRole: null,
      error: null,
      saveStatus: STATUS_SAVED,
      saveIntervalId: null,
      messageText: "",
    };
    this.sidebarElementRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.toolbarExtensionRef = React.createRef();
    this.prefaceRef = React.createRef();
  }

  componentDidMount() {
    this.props.firestore
      .get({
        collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
        doc: this.props.match.params.documentId,
      })
      .then((result) => {
        this.setState({
          initialText: this.props.document.text,
          title: this.props.document.title,
        });
      })
      .catch((error) =>
        this.setState({
          error,
        })
      );
    this.setState({ isLayoutReady: true });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // this.state.editor.destroy().catch((error) => {
    //   console.log(error);
    // });
    window.removeEventListener("resize", this.boundRefreshDisplayMode);
    window.removeEventListener("beforeunload", this.boundCheckPendingActions);
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !(prevProps.listenedDocument && prevProps.listenedDocument.userRoles) ||
      !this.state.editor
    )
      return; // not using roles pre 2.0.9
    const userId = this.props.profile.userId;
    if (
      prevProps.listenedDocument.userRoles[userId] !==
      this.props.listenedDocument.userRoles[userId]
    )
      if (this.props.listenedDocument.approverUserIds) {
        // 2.0.11
        this.refreshEditMode(
          this.state.editor,
          this.props.listenedDocument.userRoles[userId]
        );
      } else {
        // 2.0.10
        this.refreshEditMode(
          this.state.editor,
          this.props.listenedDocument.userRoles[userId].role
        );
      }
  }

  refreshDisplayMode(editor) {
    const annotationsUIs = editor.plugins.get("AnnotationsUIs");
    const sidebarElement = this.sidebarElementRef.current;
    const sidebar = editor.plugins.get("Sidebar");
    if (!sidebar.container) sidebar.setContainer(sidebarElement);
    annotationsUIs.switchTo("wideSidebar");

    // if (window.innerWidth < 1070) {
    //   sidebarElement.classList.remove("narrow");
    //   sidebarElement.classList.add("hidden");
    //   // annotationsUIs.switchTo("inline");
    // } else if (window.innerWidth < 1300) {
    //   sidebarElement.classList.remove("hidden");
    //   sidebarElement.classList.add("narrow");
    //   // annotationsUIs.switchTo("narrowSidebar");
    // } else {
    //   sidebarElement.classList.remove("hidden", "narrow");
    //   // annotationsUIs.switchTo("wideSidebar");
    // }
  }

  refreshEditMode(editor, role) {
    if (!editor) return; // which shouldn't happen since the editor has been initialised, but does
    switch (role) {
      case "Suggester":
        editor.isReadOnly = false;
        editor.plugins.get("CommentsOnly").isEnabled = false;
        editor.execute("trackChanges");
        TRACK_CHANGES_COMMANDS.forEach((command) =>
          editor.commands.get(command).forceDisabled("role")
        );
        break;
      case "Commenter":
        editor.isReadOnly = false;
        editor.plugins.get("CommentsOnly").isEnabled = true;
        break;
      case "Viewer":
        editor.isReadOnly = true;
        break;
      default:
        // Editor
        editor.isReadOnly = false;
        editor.plugins.get("CommentsOnly").isEnabled = false;
        TRACK_CHANGES_COMMANDS.forEach((command) =>
          editor.commands.get(command).clearForceDisabled("role")
        );
        if (editor.commands.get("trackChanges").value)
          editor.execute("trackChanges");
        break;
    }
    this.setState({ editorRole: role });
  }

  checkPendingActions(editor, domEvt) {
    if (editor.plugins.get("PendingActions").hasAny) {
      domEvt.preventDefault();
      domEvt.returnValue = true;
    }
  }

  onChange = (event, editor) => {
    if (this.state.saveIntervalId) {
      clearInterval(this.state.saveIntervalId);
    }
    const newInterval = setInterval(this.saveChanges, 1000);
    this.setState({
      saveStatus: STATUS_DIRTY,
      saveIntervalId: newInterval,
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      return true;
    }
    if (event.key === "Enter") {
      this.setState({ messageText: "" });
      this.addMessage();
      event.preventDefault();
      return false;
    }
  };

  saveChanges = (andExit = false, backPath = "/") => {
    const editor = this.state.editor;
    if (!editor) {
      if (andExit) this.props.history.push(backPath);
      return;
    }
    // no need to save if
    // const liveCloudVersion = this.state.editor.plugins.get("RealTimeCollaborationClient")
    //       .cloudDocumentVersion
    // const listenedVersion = this.props.listenedDocument.cloudDocumentVersion
    // if (listenedVersion && (liveCloudVersion <= listenedVersion)) return
    clearInterval(this.state.saveIntervalId);
    if (this.state.saveStatus === STATUS_SAVED && andExit) {
      this.props.history.push(backPath);
    }
    this.setState({
      saveStatus: STATUS_SAVING,
    });
    const newParams = {
      modifiedOn: new Date().getTime(),
      text: editor.getData(),
    };
    // Our saved title needs to reject any pending tracked changes
    // TODO if we're separating these saving of changes based on cloud doc versio
    // we'll need to handle this first, saving our new title for the update
    // but storing it in state anyway.
    editor.plugins
      .get("TrackChangesData")
      .getDataWithDiscardedSuggestions()
      .then((data) => {
        const el = document.createElement("html");
        el.innerHTML = data;
        let firstChild = el.children[1].firstChild;
        if (!firstChild) return "Untitled";
        let title = firstChild.innerText;
        // empty nodes in CKEditor have whitespace in them, so
        if (title.replace(/\s/g, "").length > 0) return title;
        return "Untitled";
      })
      .then((title) => {
        this.setState({ title });
        newParams.title = title;
        newParams.cloudDocumentVersion = editor.plugins.get(
          "RealTimeCollaborationClient"
        ).cloudDocumentVersion;
        return;
      })
      .then(() => {
        this.props.firestore.update(
          {
            collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
            doc: this.props.match.params.documentId,
          },
          newParams
        );
      })
      .then(() => {
        this.setState({
          saveStatus: STATUS_SAVED,
        });
        if (andExit) this.props.history.push(backPath);
      })
      .catch((err) => {
        const retryInterval = setInterval(this.saveChanges, 3000);
        this.setState({
          saveStatus: STATUS_ERROR,
          saveIntervalId: retryInterval,
        });
        console.log(err);
      });
  };

  userRoles = () => {
    const roles = this.props.listenedDocument.userRoles || {};
    // in case a new workspace user has been added, we create the correct mapping dynamically here, setting any
    // non-existent roles to "Editor"
    return this.allUsers().reduce((acc, user) => {
      return {
        ...acc,
        [user.userId]: roles[user.userId] || USER_ROLES[0],
      };
    }, {});
  };

  allUsers = () => {
    const workspace = this.props.workspace;
    const document = this.props.listenedDocument;
    // we store guests in the sharingUsers field
    const guestUsers = document.sharingUsers;
    const workspaceUsers = [workspace.ownerUser, ...workspace.sharingUsers];
    const workspaceUserIds = [
      workspace.ownerUserId,
      ...workspace.sharingUserIds,
    ];
    return workspaceUsers.concat(
      guestUsers.filter((user) => !workspaceUserIds.includes(user.userId))
    );
  };

  isAddedUser = (userId) => {
    const allWorkspaceUsers = [this.props.workspace.ownerUserId].concat(
      this.props.workspace.sharingUserIds
    );
    return !allWorkspaceUsers.includes(userId);
  };

  getMentionItems = (queryText) => {
    const usersToDisplay = this.allUsers().filter(isUserMatching).slice(0, 10);
    const userList = usersToDisplay.map((user) => ({
      id: "@" + user.email,
      text: "@" + user.email,
    }));
    return userList;

    function isUserMatching(user) {
      const searchString = queryText.toLowerCase();
      return (
        (user.name && user.name.toLowerCase().includes(searchString)) || // guests have no name field
        user.email.toLowerCase().includes(searchString)
      );
    }
  };

  handleRemoveUser = (removeUser) => {
    const newUsers = this.props.listenedDocument.sharingUsers.filter(
      (user) => user.userId !== removeUser.userId
    );
    let newRoles = { ...this.userRoles() };
    delete newRoles[removeUser.userId];
    const newApproverUserIds = [
      ...this.props.listenedDocument.approverUserIds,
    ].filter((userId) => userId !== removeUser.userId);
    this.updateSharingUsers(newUsers, newRoles, newApproverUserIds);
    addNotification(
      this.props.firestore,
      removeUser,
      this.props.profile,
      `removed you from "${this.state.title}".`,
      null
    );
    trackEvent(this.props.firebase, "Removed a user", {
      resource: this.props.resourceName,
    });
  };

  handleToggleApprover = (changeUserId) => {
    let approverUserIds = [...this.props.listenedDocument.approverUserIds];
    if (!approverUserIds.includes(changeUserId)) {
      approverUserIds.push(changeUserId);
    } else {
      approverUserIds = approverUserIds.filter(
        (userId) => userId !== changeUserId
      );
    }
    this.updateSharingUsers(this.allUsers(), this.userRoles(), approverUserIds);
  };

  handleMenuRoleUpdate = (userId, role) => {
    let newRoles = { ...this.userRoles() };
    newRoles[userId] = role;
    this.updateSharingUsers(
      this.allUsers(),
      newRoles,
      this.props.listenedDocument.approverUserIds
    );
  };

  updateSharingUsers = (newUsers, newUserRoles, newApproverUserIds) => {
    // we don't remove the approval from a user who's removed here,
    // so they can be added back and the approval can be preserved
    let approvedUserIds = this.props.listenedDocument.approvedUserIds;
    const workspace = this.props.workspace;
    const workspaceUserIds = [
      workspace.ownerUserId,
      ...workspace.sharingUserIds,
    ];
    const guestUsers = newUsers.filter(
      (user) => !workspaceUserIds.includes(user.userId)
    );
    const stageProgress = newApproverUserIds.filter((userId) =>
      approvedUserIds.includes(userId)
    ).length;
    const stageTarget = newApproverUserIds.length;

    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          sharingUsers: guestUsers,
          sharingUserIds: guestUsers.map((user) => user.userId),
          approverUserIds: newApproverUserIds,
          userRoles: newUserRoles,
          stageTarget,
          stageProgress,
        }
      )
      .then(() => {})
      .catch((error) => console.log(error));
  };

  userIsAnApprover = (userId) => {
    return this.props.listenedDocument.approverUserIds.includes(userId);
  };

  userHasApproved = (userId) => {
    return this.props.listenedDocument.approvedUserIds.includes(userId);
  };

  addApproval = () => {
    let approvedUserIds = this.props.listenedDocument.approvedUserIds;
    approvedUserIds = [...approvedUserIds];
    approvedUserIds.push(this.props.profile.userId);
    approvedUserIds = [...new Set(approvedUserIds)]; // TODO really need to do this?
    const stageProgress = this.props.listenedDocument.approverUserIds.filter(
      (userId) => approvedUserIds.includes(userId)
    ).length;

    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          approvedUserIds,
          stageProgress,
        }
      )
      .then(() => {
        trackEvent(
          this.props.firebase,
          `${STAGE_TEXT[this.state.stage].actioned} some content`
        );
        addNotification(
          this.props.firestore,
          this.props.listenedDocument.ownerUser,
          this.props.profile,
          `${STAGE_TEXT[this.state.stage].actioned} "${this.state.title}".`,
          this.props.match.url
        );
        return;
      })
      .catch((error) => console.log(error));
  };

  removeApproval = () => {
    let approvedUserIds = this.props.listenedDocument.approvedUserIds;
    approvedUserIds = approvedUserIds.filter(
      (userId) => userId !== this.props.profile.userId
    );
    const stageProgress = this.props.listenedDocument.approverUserIds.filter(
      (userId) => approvedUserIds.includes(userId)
    ).length;

    this.props.firestore
      .update(
        {
          collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
          doc: this.props.match.params.documentId,
        },
        {
          approvedUserIds,
          stageProgress,
        }
      )
      .then(() => {
        trackEvent(
          this.props.firebase,
          STAGE_TEXT[this.state.stage].un_actioned + " from some content"
        );
        addNotification(
          this.props.firestore,
          this.props.listenedDocument.ownerUser,
          this.props.profile,
          STAGE_TEXT[this.state.stage].un_actioned +
            ` from "${this.state.title}".`,
          this.props.match.url
        );
        return;
      })
      .catch((error) => console.log(error));
  };

  allApproved = () => {
    const { stageProgress, stageTarget } = this.props.listenedDocument;
    return stageProgress === stageTarget;
  };

  handleBack = (backPath) => {
    this.saveChanges(true, backPath);
  };

  handleClean = () => {
    const editor = this.state.editor;
    if (!editor) return;
    // TODO wait for changes to be saved first, they could be in-progress but the button is still active
    let cleanText = "";
    editor.plugins
      .get("TrackChangesData")
      .getDataWithDiscardedSuggestions()
      .then((text) => {
        cleanText = stripTag(stripTag(text, "comment-start"), "comment-end");
        return editor.data.set(cleanText, {
          suppressErrorInCollaboration: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleStageChange = (newStage) => {
    const editor = this.state.editor;
    if (!editor) return;
    // TODO wait for changes to be saved first, they could be in-progress but the button is still active
    const docPath = `/workspaces/${this.props.match.params.workspaceId}/documents/${this.props.match.params.documentId}`;
    editor.plugins
      .get("TrackChangesData")
      .getDataWithDiscardedSuggestions()
      .then((text) => {
        return stripTag(stripTag(text, "comment-start"), "comment-end");
      })
      .then((cleanText) => {
        return this.props.firestore.update(
          {
            collection: `workspaces/${this.props.match.params.workspaceId}/documents`,
            doc: this.props.match.params.documentId,
          },
          {
            text: cleanText,
            modifiedOn: new Date().getTime(),
            stage: newStage,
            // we turn over a new leaf to prevent persistence of comments and tracked changes
            // this is appended to the document ID to create a new collaboration channel
            leaf: (this.props.document.leaf || 0) + 1,
            stageTarget: 0,
            stageProgress: 0,
            sharingUserIds: [], // clear all added users
            sharingUsers: [],
            approverUserIds: [],
            approvedUserIds: [],
          }
        );
      })
      .then(() => {
        this.setState({
          saveStatus: STATUS_SAVED,
        });
        trackEvent(this.props.firebase, "Moved content to " + newStage);
        this.props.history.push(docPath + "/" + newStage);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAddComment = (content) => {
    const el = document.createElement("div");
    el.innerHTML = content;
    let elements = el.getElementsByClassName("mention");
    elements.forEach((mention) => {
      const user = this.allUsers().find(
        // omit the leading '@', and don't send to guests or yourself
        (user) =>
          user.email === mention.innerText.substring(1) &&
          !user.isGuest &&
          user.userId !== this.props.profile.userId
      );
      if (user) {
        addNotification(
          this.props.firestore,
          user,
          this.props.profile,
          `mentioned you in a comment on "${this.state.title}": ${el.innerText}`,
          this.props.match.url
        );
      }
    });
  };

  render() {
    const {
      stage,
      saveStatus,
      error,
      isLayoutReady,
      // editor,
      initialText,
      title,
    } = this.state;
    const {
      documents,
      document,
      messages,
      workspace,
      listenedDocument,
      profile,
      auth,
      sentInvitations,
    } = this.props;
    const { workspaceId, documentId } = this.props.match.params;

    if (error) {
      return <p>{error.message}</p>;
    }

    if (
      !isLoaded(documents, workspace, profile, messages, listenedDocument) ||
      isEmpty(document, workspace)
    )
      return <Loader />;

    if (!canAccessPage(auth, listenedDocument, workspace)) {
      return (
        <Redirect
          to={{
            pathname: "/error/403",
            state: {
              from: { pathname: this.props.match.path },
            },
          }}
        />
      );
    }

    // const thisStage = this.props.match.path.split("/").reverse()[0];
    // console.log(thisStage, listenedDocument.stage);
    // if (listenedDocument.stage !== thisStage) {
    //   // console.log("different " + thisStage);
    //   // return (
    //   //   <Redirect
    //   //     to={{
    //   //       pathname: `/workspaces/${workspaceId}/documents/${documentId}/${listenedDocument.stage}`
    //   //     }}
    //   //   />
    //   // );
    // }

    // const plainText = htmlToPlainText(this.state.text); // for word count - should use getData!
    const { stageProgress, stageTarget } = listenedDocument;

    const canManageDocument = // anyone in the workspace
      workspace.ownerUserId === profile.userId ||
      workspace.sharingUserIds.includes(profile.userId);

    // when moving stages we want a clean copy - ideally we'd handle with the REST API but for now
    // we 'turn over a new leaf' to leave the persisted old document behind
    const channelId = documentId + (document.leaf || "");

    const userSessions = null; //editor?.plugins
    // ?.get("Sessions")
    // ?.channelConnectedUsers?.get(channelId)?._itemMap;

    const pendingInvitations = (sentInvitations || []).filter(
      (invitation) => !invitation.invitationHandled
    );
    // console.log(
    //   "rendering: cloud " +
    //     (editor &&
    //       editor.plugins.get("RealTimeCollaborationClient")
    //         .cloudDocumentVersion) +
    //     ", listened " +
    //     listenedDocument.cloudDocumentVersion

    const MentionPlugin = Editor.builtinPlugins.find(
      (plugin) => plugin.pluginName === "Mention"
    );

    return (
      <Fragment>
        <Helmet
          title={
            STAGE_TEXT[stage].title +
            CONTENT_TYPES[listenedDocument.contentType].nameWithArticle
          }
        />
        <div className="flex-no-shrink">
          <DocumentHeader
            isGuest={profile.isGuest}
            activeStep={STAGE_TEXT[stage].activeStep}
            userId={profile.userId}
            workspace={workspace}
            workspaceId={workspaceId}
            handleBack={this.handleBack}
          />
          <Grid
            container
            justifyContent="space-between"
            spacing={4}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item container alignItems="center" wrap="nowrap">
              <Grid mx={2}>
                <EllipsisWrapper>
                  <Typography variant="h4" gutterBottom display="inline" noWrap>
                    {title}
                  </Typography>
                </EllipsisWrapper>
              </Grid>
            </Grid>
            <Grid
              item
              container
              wrap="nowrap"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <AvatarGroup max={10} spacing={10}>
                  {this.allUsers().map((user) => (
                    <ManageReviewerMenu
                      key={user.userId}
                      user={user}
                      profile={profile}
                      stageText={STAGE_TEXT[stage]}
                      userSession={
                        userSessions && userSessions.get(user.userId)
                      }
                      isAnApprover={this.userIsAnApprover(user.userId)}
                      hasApproved={this.userHasApproved(user.userId)}
                      role={this.userRoles()[user.userId]}
                      canEdit={canManageDocument}
                      canBeRemoved={this.isAddedUser(user.userId)}
                      isOwner={listenedDocument.ownerUserId === user.userId}
                      handleToggleApprover={() =>
                        this.handleToggleApprover(user.userId)
                      }
                      handleRemoveUser={() => this.handleRemoveUser(user)}
                      handleMenuRoleUpdate={(role) =>
                        this.handleMenuRoleUpdate(user.userId, role)
                      }
                    />
                  ))}
                  {pendingInvitations.map((invitation) => (
                    <ManageInvitationMenu
                      key={invitation.id}
                      invitation={invitation}
                      canEdit={canManageDocument}
                    />
                  ))}
                </AvatarGroup>
              </Grid>
              {canManageDocument && (
                <Grid item>
                  <InviteReviewerDialog
                    class="tour-manageUser"
                    resourceName={`"${title}"`}
                    actionNoun={STAGE_TEXT[stage].action_noun}
                    parent={listenedDocument}
                    workspaceId={workspaceId}
                    workspace={workspace}
                    teamId={workspace.teamId || ""}
                    parentUsers={this.allUsers()}
                    parentUserRoles={this.userRoles()}
                    // parentAddedUserIds={listenedDocument.sharingUserIds}
                    parentApproverUserIds={
                      this.props.listenedDocument.approverUserIds
                    }
                    resourcePath={`workspaces/${workspaceId}/documents/${documentId}`}
                    redirectUrl={this.props.match.url}
                    inviteType={STAGE_TEXT[stage].action}
                    inviteIntent={`${STAGE_TEXT[stage].action} "${title}"`}
                    handleUpdate={this.updateSharingUsers}
                    // maxSharingUsers={
                    //   PRICING_PLANS[workspace.plan || 0].max_approvers
                    // }
                  />
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="primary"
                style={{ textTransform: "uppercase" }}
              >
                {this.state.editorRole}
              </Typography>
            </Grid>
            {listenedDocument.isArchived && (
              <Grid item>
                <Tooltip title="Archived" arrow>
                  <ArchiveIcon color="secondary" />
                </Tooltip>{" "}
              </Grid>
            )}
            <Hidden mdDown>
              <Grid item>
                <DocumentAttributesDialog
                  mode="button"
                  document={listenedDocument}
                  documentId={documentId}
                />
              </Grid>
            </Hidden>
            <Grid item>
              <SaveStatusCloud saveStatus={saveStatus} />
            </Grid>
            <Grid item>
              <Tour tourId={this.props.match.path} />
            </Grid>
            {canManageDocument && (
              <Grid item>
                <ManageDocumentMenu
                  documentId={documentId}
                  document={listenedDocument}
                  handleCleanVersion={this.handleClean}
                  handlePrevStage={() =>
                    this.handleStageChange(STAGE_TEXT[stage].prevStage)
                  }
                  prevStageDescription={STAGE_TEXT[stage].prevStageDescription}
                />
              </Grid>
            )}
            <Grid item>
              {this.userIsAnApprover(profile.userId) &&
                !this.userHasApproved(profile.userId) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                    className="tour-approve"
                    onClick={this.addApproval}
                  >
                    {STAGE_TEXT[stage].action_button}
                  </Button>
                )}
              {this.userIsAnApprover(profile.userId) &&
                this.userHasApproved(profile.userId) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                    className="tour-approve"
                    onClick={this.removeApproval}
                  >
                    {STAGE_TEXT[stage].un_action_button}
                  </Button>
                )}
            </Grid>
            {canManageDocument && (
              <Grid item>
                <Tooltip
                  title={`Move to ${STAGE_TEXT[stage].nextStageDescription}`}
                  arrow
                >
                  {/* can't wrap a disabled button in a tooltip */}
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ whiteSpace: "nowrap" }}
                      className="tour-next"
                      onClick={() =>
                        this.handleStageChange(STAGE_TEXT[stage].nextStage)
                      }
                      disabled={!this.allApproved()}
                    >
                      Next step
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </div>

        <Progress
          variant="determinate"
          value={stageTarget === 0 ? 100 : (stageProgress / stageTarget) * 100}
        />
        <ToolbarWrapper
          ref={this.toolbarRef}
          className="toolbar"
        ></ToolbarWrapper>

        <div className="flex-section">
          <Grid container spacing={6} className="flex-col-scroll-left">
            <Grid item xs={8} className="editor">
              {!this.state.editor && (
                <Loader message="Connecting to document" />
              )}
              {isLayoutReady && typeof initialText !== "undefined" && (
                // text can be empty for blank templates, and have seen issues with loading with no toolbarRef
                <>
                  <DocumentAttributesDialog
                    mode="preface"
                    document={listenedDocument}
                    documentId={documentId}
                  />
                  <CKEditor
                    style={{
                      paddingTop: "-12px",
                    }}
                    editor={Editor}
                    onReady={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                      this.setState({
                        editor,
                      });
                      editor.focus();
                      this.refreshEditMode(
                        editor,
                        this.userRoles()[profile.userId]
                      );
                      // Switch between inline and sidebar annotations according to the window size.
                      this.boundRefreshDisplayMode =
                        this.refreshDisplayMode.bind(this, editor);
                      // Prevent closing the tab when any action is pending.
                      this.boundCheckPendingActions =
                        this.checkPendingActions.bind(this, editor);
                      window.addEventListener(
                        "resize",
                        this.boundRefreshDisplayMode
                      );
                      window.addEventListener(
                        "beforeunload",
                        this.boundCheckPendingActions
                      );
                      this.refreshDisplayMode(editor);

                      const CommentsRepository =
                        editor.plugins.get("CommentsRepository");
                      CommentsRepository.on("addComment", (eventInfo, data) => {
                        this.handleAddComment(data.content);
                      });
                      this.toolbarRef.current.appendChild(
                        editor.ui.view.toolbar.element
                      );
                    }}
                    config={{
                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "underline",
                          "fontColor",
                          // "strikethrough",
                          // "removeFormat",
                          // "highlight",
                          "|",
                          "alignment",
                          "|",
                          "numberedList",
                          "bulletedList",
                          "|",
                          "blockquote",
                          "horizontalLine",
                          "link",
                          "imageUpload",
                          // "insertTable",
                          // "mediaEmbed",
                          // "|",
                          "undo",
                          "redo",
                          "|",
                          "comment",
                          // "|",
                          "trackChanges",
                        ],
                      },
                      cloudServices: {
                        tokenUrl: () => {
                          return new Promise((resolve, reject) => {
                            const getToken = this.props.firebase
                              .functions()
                              .httpsCallable("ckEditorToken");
                            getToken()
                              .then((result) => {
                                return resolve(result.data);
                              })
                              .catch((error) => {
                                reject(new Error("Error getting token"));
                              });
                          });
                        },
                        uploadUrl: "https://78401.cke-cs.com/easyimage/upload/",
                        webSocketUrl: "wss://78401.cke-cs.com/ws",
                      },
                      collaboration: {
                        channelId,
                      },
                      image: {
                        toolbar: [
                          "imageStyle:inline",
                          "imageStyle:wrapText",
                          "imageStyle:breakText",
                          // "imageStyle:block",
                          // "imageStyle:side",
                          "|",
                          "toggleImageCaption",
                          "imageTextAlternative",
                          // "|",
                          // "linkImage"
                          // "comment"
                        ],
                      },
                      sidebar: {
                        container: this.sidebarElementRef.current,
                        preventScrollOutOfView: true,
                      },
                      comments: {
                        editorConfig: {
                          placeholder:
                            "Add a comment and click the green tick to save",
                          extraPlugins: [MentionPlugin],
                          mention: {
                            feeds: [
                              {
                                marker: "@",
                                feed: this.getMentionItems,
                                minimumCharacters: 0,
                              },
                            ],
                          },
                        },
                      },
                    }}
                    data={this.state.initialText}
                    onChange={this.onChange}
                  />
                </>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              ref={this.sidebarElementRef}
              className="sidebar"
            ></Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workspaceId = ownProps.match.params.workspaceId;
  const documentId = ownProps.match.params.documentId;
  const workspaces = state.firestore.data.workspaces;
  const documents = state.firestore.data[`workspaces/${workspaceId}/documents`];

  return {
    // have to check if collections are loaded before accessing documents
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    workspaces: state.firestore.data.workspaces,
    workspace: workspaces ? workspaces[workspaceId] : {},
    documents,
    document: documents ? documents[documentId] : {},
    listenedDocument: state.firestore.data.listenedDocument,
    messages:
      state.firestore.ordered[
        `workspaces/${workspaceId}/documents/${documentId}/messages`
      ],
    sentInvitations: state.firestore.ordered["sentInvitations"],
  };
}

export default compose(
  withFirestore,
  withFirebase,
  firestoreConnect((props) => [
    {
      collection: "workspaces",
      doc: props.match.params.workspaceId,
    },
    {
      collection: `workspaces/${props.match.params.workspaceId}/documents/${props.match.params.documentId}/messages`,
      orderBy: ["date", "asc"],
    },
    {
      collection: `workspaces/${props.match.params.workspaceId}/documents`,
      doc: props.match.params.documentId,
      storeAs: "listenedDocument",
    },
    {
      collection: "email",
      where: [
        ["Tag", "==", "invitation"],
        [
          "resourcePath",
          "==",
          `workspaces/${props.match.params.workspaceId}/documents/${props.match.params.documentId}`,
        ],
      ],
      orderBy: ["sentOn", "desc"],
      storeAs: "sentInvitations",
    },
  ]),
  connect(mapStateToProps)
)(ReviewDoc);
