import React from "react";
import styled from "styled-components";
import {
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem
} from "@material-ui/core";
import packageJson from "../../package.json";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer({ version }) {
  return (
    <Wrapper>
      <Hidden smDown>
        <List>
          <ListItem
            component="a"
            rel="noopener noreferrer"
            href="https://ping-go.com/privacy"
            target="_blank">
            <ListItemText primary="Privacy" />
          </ListItem>
          <ListItem
            component="a"
            rel="noopener noreferrer"
            href="https://ping-go.com/tos"
            target="_blank">
            <ListItemText primary="Terms of service" />
          </ListItem>
          <ListItem>
            <ListItemText primary={`© ${new Date().getFullYear()} - PingGo`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={"v" + packageJson.version} />
          </ListItem>
        </List>
      </Hidden>
    </Wrapper>
  );
}

export default Footer;
