import React, { Component } from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordianSummary,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon, Lock } from "@material-ui/icons";

const AccordionSummary = styled(MuiAccordianSummary)`
  white-space: normal; // commonly set to nowrap in <Grid>
`;
const LockIcon = styled(Lock)`
  height: 15px;
  color: #ddd;

  // padding: 0;
`;

class AccordionQuestionItem extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.answer === nextProps.answer &&
      this.props.expanded === nextProps.expanded &&
      this.props.locked === nextProps.locked
    ) {
      return false;
    } else {
      return true;
    }
  }
  componentDidMount() {
    if (this.props.expanded) this.focusTextInput();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.expanded && this.props.expanded) {
      this.focusTextInput();
    }
  }

  handleKeyDown = (event) => {
    if ((event.key === "Tab" || event.key === "Enter") && !event.shiftKey) {
      event.preventDefault();
      this.props.nextQuestion();
    } else if (event.key === "Tab" && event.shiftKey) {
      event.preventDefault();
      this.props.prevQuestion();
    }
  };

  focusTextInput() {
    this.textInput.current.focus();
    const strLength = this.textInput.current.value.length * 2;
    this.textInput.current.setSelectionRange(strLength, strLength);
  }

  render() {
    const {
      question,
      answer,
      expanded,
      handleChangeExpanded,
      handleChangeText,
      locked
    } = this.props;
    const hasAnswer = answer && answer.length > 1;
    return (
      <Accordion
        key={question.index}
        expanded={expanded}
        onChange={handleChangeExpanded(question.index.toString())}>
        <AccordionSummary expandIcon={locked ? null : <ExpandMoreIcon />}>
          <div>
            <svg height="22" width="30">
              <circle
                cx="11"
                cy="11"
                r="10"
                fill={question.isKey ? "#ccf" : "#fc9"}
                stroke="#555"
                strokeWidth={hasAnswer ? 0 : 2}
              />
              <text
                textAnchor="middle"
                fontSize="small"
                x="11"
                y="16"
                fill={hasAnswer ? "#fff" : "#555"}>
                {question.index + 1}
              </text>
            </svg>
          </div>
          <Typography variant="body2" style={{ paddingTop: "2px" }}>
            {hasAnswer ? question.text : <b>{question.text}</b>}{" "}
            {locked && (
              <Tooltip
                title="Locked because this is a Key Message, and only the workspace owner can change those once they're created"
                arrow>
                <LockIcon />
              </Tooltip>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            name={question.tag}
            placeholder={question.placeholder}
            variant="outlined"
            fullWidth={true}
            multiline
            minRows={3}
            maxRows={99}
            type="string"
            // disabled={locked}
            value={answer}
            helperText={question.help}
            onChange={handleChangeText}
            onKeyDown={this.handleKeyDown}
            inputRef={this.textInput}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default AccordionQuestionItem;
