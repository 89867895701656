import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from "styled-components";
import { rgba } from "polished";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
  Badge as MuiBadge,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText
} from "@material-ui/core";

import {
  RecentActors as MyPingGoIcon,
  Apps as WorkspaceIcon,
  Business as SharedTeamIcon,
  Apartment as MyTeamIcon
} from "@material-ui/icons";

import PingoLogo from "../assets/logo-pinggo165x54.png";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));
const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;
const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    color: #fff;
  }
`;
const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;
const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;
const Items = styled.div`
  // padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;
const Brand = styled(ListItem)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  min-height: 55px;
  padding: 0;
  // margin-bottom: 10px;
  // padding-left: 0;
  // padding-right: 0;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 55px;
  }
`;
const Link = styled(ListItem)`
  padding: 12px 20px 12px 24px;
  font-weight: 600;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.87)};
  }
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.06, props.theme.sidebar.background)};
  }
`;
const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  padding: 0px 12px 0px;
  margin-top: 0;
  margin-bottom: 0;
`;

function Sidebar({
  auth,
  myTeams,
  sharedTeams,
  approverDocuments,
  classes,
  staticContext,
  location,
  ...rest
}) {
  useFirestoreConnect([
    {
      collection: "teams",
      where: ["ownerUserId", "==", auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "myTeams"
    },
    {
      collection: "teams",
      where: ["sharingUserIds", "array-contains", auth.uid],
      orderBy: ["name", "asc"],
      storeAs: "sharedTeams"
    },
    {
      collectionGroup: "documents",
      where: ["approverUserIds", "array-contains", auth.uid],
      storeAs: "approverDocuments" + auth.uid
    }
  ]);

  const { dispatch, ...remaining } = rest; // otherwise an error is raised
  const awaitingApproval = approverDocuments
    ? approverDocuments.filter((doc) => !doc.approvedUserIds.includes(auth.uid))
        .length
    : 0;

  return (
    <Drawer variant="permanent" {...remaining}>
      <Brand>
        <img src={PingoLogo} alt="PingoLogo" width="165" height="54" />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            <Link
              button
              dense
              component={NavLink}
              exact
              to="/"
              activeClassName="active"
              className="tour-workspacesLink">
              <WorkspaceIcon />
              <LinkText>Workspaces</LinkText>
            </Link>
            <Link
              button
              dense
              component={NavLink}
              // exact
              to="/mypinggo"
              activeClassName="active"
              className="tour-myPingGoLink">
              <Badge color="primary" badgeContent={awaitingApproval}>
                <MyPingGoIcon />
                <LinkText>My PingGo</LinkText>
              </Badge>
            </Link>
            {myTeams &&
              Object.keys(myTeams).map((teamId) => (
                <Link
                  key={teamId}
                  button
                  dense
                  component={NavLink}
                  // exact
                  to={`/teams/${teamId}`}
                  activeClassName="active"
                  className="tour-teamsLink">
                  <MyTeamIcon />
                  <LinkText>{myTeams[teamId].name}</LinkText>
                </Link>
              ))}
            {sharedTeams &&
              Object.keys(sharedTeams).map((teamId) => (
                <Link
                  key={teamId}
                  button
                  dense
                  component={NavLink}
                  // exact
                  to={`/teams/${teamId}`}
                  activeClassName="active"
                  className="tour-teamsLink">
                  <SharedTeamIcon />
                  <LinkText>{sharedTeams[teamId].name}</LinkText>
                </Link>
              ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
}
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myTeams: state.firestore.data.myTeams,
  sharedTeams: state.firestore.data.sharedTeams,
  approverDocuments:
    state.firestore.ordered[`approverDocuments${state.firebase.auth.uid}`]
});

export default compose(withRouter, connect(mapStateToProps))(Sidebar);
