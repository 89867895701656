import React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withFirebase, firestoreConnect } from "react-redux-firebase";
import { compose } from "recompose";
import { useRouteMatch } from "react-router-dom";
import semver from "semver";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
  Button,
  Tooltip
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import ManageAccountMenu from "./ManageAccountMenu";
import NotificationsPopup from "./NotificationsPopup";
import Tour from "./Tour";
import packageJson from "../../package.json";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.05);
`;
const Toolbar = styled(MuiToolbar)`
  min-height: 55px;
  margin-right: 12px;
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Header = ({ onDrawerToggle, profile, version, firebase }) => {
  const handleUpgrade = () => {
    if (caches) {
      caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={5}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            {version && semver.lt(packageJson.version, version.version) && (
              <Grid item>
                <Tooltip
                  title={"Update to PingGo version " + version.version}
                  arrow>
                  <Button color="primary" onClick={handleUpgrade}>
                    New version! Click to update
                  </Button>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tour tourId={useRouteMatch().path} />
            </Grid>
            <Grid item>
              <NotificationsPopup
                userId={profile.userId}
                ignoreNotificationsBefore={
                  profile.ignoreNotificationsBefore || 0
                }
              />
            </Grid>
            <Grid item>
              <ManageAccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const versions = state.firestore.data.versions;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    version: versions
      ? state.firestore.data.versions[window.location.hostname]
      : null
  };
};

export default compose(
  withFirebase,
  firestoreConnect((props) => [
    {
      collection: "versions",
      doc: window.location.hostname
    }
  ]),
  withTheme,
  connect(mapStateToProps)
)(Header);
