import React, { useState } from "react";
import Joyride from "react-joyride";
import styled from "styled-components";
import { grey, amber } from "@material-ui/core/colors";
import { withFirebase, isLoaded } from "react-redux-firebase";
import { compose } from "recompose";
import { connect } from "react-redux";
import { HelpOutline as Help } from "@material-ui/icons";
import { IconButton as MuiIconButton, Tooltip } from "@material-ui/core";
import trackEvent from "../util/trackEvent";

import createDocSteps from "../tours/createDocSteps";
import startDocSteps from "../tours/startDocSteps";
import reviewDocSteps from "../tours/reviewDocSteps";
import homeSteps from "../tours/homeSteps";
import workspaceSteps from "../tours/workspaceSteps";
import teamSteps from "../tours/teamSteps";

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

function Tour({ tourId, firebase, profile, myTeams }) {
  const steps = {
    "/": homeSteps,
    "/workspaces/:workspaceId": workspaceSteps,
    "/workspaces/:workspaceId/documents/start/:category": startDocSteps,
    "/workspaces/:workspaceId/documents/:documentId/create": createDocSteps,
    "/workspaces/:workspaceId/documents/:documentId/review": reviewDocSteps,
    "/teams/:teamId/:tab?": teamSteps
  };

  const tourNames = {
    "/": "Home",
    "/workspaces/:workspaceId": "Workspace",
    "/workspaces/:workspaceId/documents/start/:category": "Choose Template",
    "/workspaces/:workspaceId/documents/:documentId/create": "Answer Questions",
    "/workspaces/:workspaceId/documents/:documentId/review": "Edit & Proof",
    "/teams/:teamId/:tab?": "Team"
  };

  const [helpers, setHelpers] = useState();

  const toggleTourInProfile = (index = 0) => {
    const toursComplete = profile.toursComplete || {};
    toursComplete[tourId] = !toursComplete[tourId];
    firebase
      .updateProfile({ toursComplete })
      .then((result) => {
        if (toursComplete[tourId])
          trackEvent(firebase, `Ended a ${tourNames[tourId]} tour`, {
            at: `step ${index} of ${steps[tourId].length}`
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleJoyrideCallback = (data) => {
    if (data.action === "close") helpers.skip();
    if (data.action === "start")
      trackEvent(firebase, `Started a ${tourNames[tourId]} tour`);
    if (data.type === "tour:end") toggleTourInProfile(data.index);
  };

  if (!steps[tourId]) return null;

  const otherDialogsShowing =
    !profile.termsConsentedOn ||
    (profile.grandfathered && !profile.modal_hidden_appsumo);

  const showTour =
    isLoaded(profile) &&
    !otherDialogsShowing &&
    !(profile.persona === "undecided") &&
    !(profile.persona === "agency" && !myTeams) &&
    (!profile.toursComplete || !profile.toursComplete[tourId]);

  return (
    <>
      <Tooltip title="Show the tour" arrow>
        {/* We wrap the button as Tooltip complains if it's disabled */}
        <span>
          <IconButton
            onClick={toggleTourInProfile}
            disabled={showTour}
            className="tour-tour">
            <Help />
          </IconButton>
        </span>
      </Tooltip>
      {showTour && (
        <Joyride
          run={showTour}
          steps={steps[tourId]}
          getHelpers={setHelpers}
          continuous
          showSkipButton
          locale={{ last: "Finish", skip: "Skip the tour" }}
          styles={{
            options: {
              arrowColor: "#ffffff",
              backgroundColor: "#ffffff",
              overlayColor: "rgba(0, 0, 0, 0.4)",
              primaryColor: amber[800],
              textColor: grey[800],
              width: 400,
              zIndex: 10000
            },
            tooltip: {
              fontSize: 15,
              padding: 12
            },
            tooltipTitle: {
              fontSize: 16
            }
          }}
          callback={handleJoyrideCallback}
        />
      )}
    </>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    profile: state.firebase.profile,
    myTeams: state.firestore.data.myTeams,
    sharedTeams: state.firestore.data.sharedTeams
  };
}
export default compose(withFirebase, connect(mapStateToProps))(Tour);
