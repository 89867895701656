import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirestore } from "react-redux-firebase";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Publish } from "@material-ui/icons";

import Dropzone from "react-dropzone";
import { spacing } from "@material-ui/system";
import isValidEmail from "../util/isValidEmail";
import Papa from "papaparse";

const Button = styled(MuiButton)(spacing);
const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};
const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

class UploadContactsDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      error: null,
      contactsPreview: null,
      validPreview: false,
      mergeUpdatedContacts: true
    };
  }

  componentDidMount() {
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(this);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // fix Warning 'Function component cannot be given refs workround
    const { childRef } = this.props;
    if (childRef) childRef(undefined);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOpen = () => {
    this.setState({
      error: null,
      open: true,
      contactsPreview: null,
      validPreview: false,
      mergeUpdatedContacts: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSubmit = () => {
    if (!this.state.validPreview) return;
    const existingContactMap = this.props.existingContacts.reduce(
      (acc, cur) => {
        acc[cur.email] = cur;
        return acc;
      },
      {}
    );
    const existingEmails = this.existingEmails();
    this.state.contactsPreview.forEach((uploadedContact) => {
      const uploadedContactTagsArray = uploadedContact.tags
        ? uploadedContact.tags.split(":")
        : [];
      let newContact = uploadedContact;
      newContact.tags = uploadedContactTagsArray.sort().join(",");
      if (existingEmails.includes(uploadedContact.email)) {
        const existingContact = existingContactMap[uploadedContact.email];
        const existingContactTagsArray = existingContact.tags
          .split(",")
          .filter((item) => item !== "");
        if (this.state.mergeUpdatedContacts) {
          // we don't want to overwrite with new contact empty strings
          Object.keys(uploadedContact)
            .filter((key) => uploadedContact[key] === "")
            .forEach((key) => delete uploadedContact[key]);
          newContact = { ...existingContact, ...uploadedContact };
          newContact.tags = [
            ...new Set([
              ...existingContactTagsArray,
              ...uploadedContactTagsArray
            ])
          ]
            .sort()
            .join(",");
        }
        this.props.firestore.update(
          `teams/${this.props.teamId}/contacts/${existingContact.id}`,
          newContact
        );
      } else {
        this.props.firestore.add(
          `teams/${this.props.teamId}/contacts`,
          newContact
        );
      }
    });
    this.handleClose();
  };

  handleUpload = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      let csv = reader.result;
      csv =
        "publication,firstName,lastName,jobTitle,email,phone,tags\r\n" + csv;
      const result = Papa.parse(csv, { header: true });
      // console.log(result.meta);
      this.setState({
        contactsPreview: result.data.filter((contact) =>
          isValidEmail(contact.email)
        ),
        validPreview: result.data.length > 0,
        error:
          result.length === 0
            ? "Sorry, there were no valid contacts in that file."
            : null
      });
    };
    try {
      reader.readAsText(acceptedFiles[0]);
      // this.setState({ error: null });
    } catch (err) {
      console.log(err);
      this.setState({
        error:
          "There was a problem processing your file - is it definitely a CSV list of contacts?"
      });
    }
  };

  mergeUpdatedContactsCheckboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  existingEmails = () => this.props.existingContacts.map(({ email }) => email);

  newContacts = () =>
    this.state.contactsPreview.filter(
      (contact) => !this.existingEmails().includes(contact.email)
    ) || [];

  render() {
    const { contactsPreview, validPreview } = this.state;

    // console.log(this.state.mergeUpdatedContacts);
    return (
      <>
        <Button
          variant="text"
          color="primary"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => this.handleOpen()}>
          Upload contacts
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullwidth="true"
          maxWidth="lg">
          <DialogTitle>
            {validPreview ? "Upload Preview" : "Upload Contacts CSV"}
          </DialogTitle>
          <DialogContent>
            {!validPreview ? (
              <>
                <Dropzone
                  onDrop={(acceptedFiles) => this.handleUpload(acceptedFiles)}
                  accept="text/csv, text/plain, application/vnd.ms-excel">
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject
                  }) => (
                    <DropContainer
                      {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject
                      })}>
                      <input {...getInputProps()} />
                      <Typography variant="h5">
                        Drag and drop a .csv file of your contacts here, or
                        click to select a file
                      </Typography>
                    </DropContainer>
                  )}
                </Dropzone>
                <Typography align="center" variant="body1">
                  Use the CSV format: Publication/outlet name,First name, Last
                  name, Job title, Email address, Phone number, Tags
                </Typography>
                <Typography align="center" variant="body2">
                  Lines without a valid email address (like a heading line, if
                  you have one) will be ignored.
                </Typography>
                <Typography align="center" variant="body2">
                  Don't forget to put quotes (") around fields with commas in.
                </Typography>
                <Typography align="center" variant="body2">
                  Lines with an email address which matches an existing contact
                  will update those contact details.
                </Typography>
                <Typography align="center" variant="body2">
                  Use colons (:) to separate multiple tags.
                </Typography>
              </>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Publication</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Job title</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactsPreview.map((contact, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body2">
                          {contact.publication}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {contact.firstName} {contact.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {contact.jobTitle}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{contact.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{contact.phone}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{contact.tags}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.error && (
              <Typography variant="body2" color="error">
                {this.state.error}
              </Typography>
            )}
            {validPreview && (
              <Typography variant="body2">
                {this.newContacts().length} contacts will be added and{" "}
                {contactsPreview.length - this.newContacts().length} updated
              </Typography>
            )}
            {validPreview &&
              contactsPreview.length > this.newContacts().length && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(this.state.mergeUpdatedContacts)}
                      onChange={this.mergeUpdatedContactsCheckboxChange}
                      name="mergeUpdatedContacts"
                      color="primary"
                    />
                  }
                  label="Merge, not replace, details and tags for those updated contacts"
                />
              )}

            <Button
              variant="outlined"
              onClick={this.handleClose}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              disabled={!validPreview}
              color="primary"
              endIcon={<Publish />}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile
});

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps)
)(UploadContactsDialog);
